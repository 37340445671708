import { createMuiTheme } from '@material-ui/core/styles';

export const createTheme = () => {
    const theme = createMuiTheme({
        '*': {
            fontFamily: '"lato", "sans-serif"',
        },
        typography: {
            fontSize: 16,
            fontFamily: '"lato", "sans-serif"',
            'button': {
                fontSize: 13,
                fontWeight: 900,
            },
            'smallBody': {
                fontSize: 15,
                fontWeight: 500,
            },
            'body1': {
                fontSize: 18,
                fontWeight: 500,
            },
            'body2': {
                fontSize: 20,
                fontWeight: 500,
            },
            'h6': {
                
            },
            'h5': {},
            'h4': {
                fontSize: 30,
                fontWeight: 900,
                '@media (max-width: 450px)': {
                    fontSize: 22,
                },
            },
            'h3': {
                fontSize: 35,
                fontWeight: 'bold',
                '@media (max-width: 450px)': {
                    fontSize: 26,
                },
            },
            'h2': {
                fontSize: 26,
                marginBottom: 16,
                fontWeight: 'bold',
                '@media (max-width: 450px)': {
                    fontSize: 20,
                },
            },
            'h1': {
                fontSize: 35,
                fontWeight: 'bold',
                marginBottom: 24,
                '@media (max-width: 450px)': {
                    fontSize: 26,
                },
            },
        },
        palette: {
            line: {
                red: '#E52A1B',
                blue: '#171C8F'
            },
            primary: {
                main: '#8FB1EE',
            },
            secondary: {
                // dark: '#FFFFFF',
                main: '#EFF3F0',
                // light: '#FFFFFF'
            },
            background: {
                white: '#ffffff',
                default: '#ffffff',
                gray: '#f9f9f9',
            },
            text: {
                primary: '#444544',
                secondary: '#7B7B7B',
                white: '#FFFFFF',
            },
        },
        overrides: {
        },
        props: {
            MuiContainer: {
                // maxWidth: "md",
            },
        },
    })
    return {
        ...theme,
    }
}

const MainTheme = createTheme();
export default MainTheme