import React, { useEffect } from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import FamilySvg from '../../svgs/FamilySvg';
import SelectedNewsCardComponent from '../../components/SelectedNewsCardComponent';
import { useDispatch, useSelector } from 'react-redux';
import SectionsComponent from '../../components/SectionsComponent';
// import NewsCardComponent from '../../components/NewsCardComponent';
import { NEWS_ROUTE } from '../../constants/routes';
import NewsCardSkeletonComponent from '../../components/skeletons/NewsCardSkeletonComponent';
import SelectedNewsCardSkeletonComponent from '../../components/skeletons/SelectedNewsCardSkeletonComponent';

import loadable from '@loadable/component'
import { FETCH_MAIN_DATA_REDUCER } from '../../reducers/MainPageReducer';
const NewsCardComponent = loadable(() => import('../../components/NewsCardComponent'))
const useStyles = makeStyles((theme) => ({

    bannerCont: {
        position: 'relative',
        zIndex: 3,
        [theme.breakpoints.down("md")]: {
           marginTop: 40,
        },
    },
    family: {
        maxWidth: '100%',
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    },
    bannerBox: {
        overflow: 'hidden',
    },
    newsCont: {
        display: 'flex',
    },
    newsBox: {
        display: 'flex',
        overflow: 'auto',
        flex: 1,
        paddingTop: 40,
        paddingBottom: 80,
        marginBottom: 80,
        [theme.breakpoints.down("xs")]: {
            marginBottom: 40,
        },
    },

}))

const MainPage = (props) => {
    const classes = useStyles();
    const { data, loading } = useSelector(state => state.MainPageReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FETCH_MAIN_DATA_REDUCER())
     }, [dispatch])

    return (
        <MainContainerComponent showLargeBgr hiddenBreadcrumbs showAnim title={'Centrum Inicjatyw Rodzinnych'}>
            <Box className={classes.bannerBox}>
                <Container className={classes.bannerCont}>
                    <Grid container justify='space-between'>
                        <Grid item lg={4} md={8} sm={10} xs={12}>
                            {
                                loading
                                    ? <SelectedNewsCardSkeletonComponent />
                                    : data?.selectedNews
                                        ? <SelectedNewsCardComponent
                                            title={data.selectedNews.title}
                                            subtitle={data.selectedNews.subtitle}
                                            date={data.selectedNews.date}
                                            slug={data.selectedNews.slug}
                                            src={data.selectedNews.src}
                                        />
                                        : null

                            }
                        </Grid>
                        <Grid item lg={7}>
                            <FamilySvg className={classes.family} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <SectionsComponent
            />
            <Box className={classes.newsBox}>
                <Container className={classes.newsCont}>
                    {loading
                        ? <>
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                        </>
                        : data?.news?.length
                            ? data.news.map(({ date, title, intro, src, slug }, key) =>
                                <NewsCardComponent
                                    key={key}
                                    date={date}
                                    first={key === 0}
                                    title={title}
                                    intro={intro}
                                    src={src}
                                    to={`${NEWS_ROUTE}/${slug}`}
                                />
                            )
                            : ''
                    }
                </Container>
            </Box>
        </MainContainerComponent>
    )
}


MainPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(MainPage);