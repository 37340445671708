import React from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonComponent from './ButtonComponent';
import { classNames } from '../../functions';
import { green, grey, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    cont: {
        position: 'relative',
    },
    success: {
        backgroundColor: green[400],
        borderColor: green[400],
        '&:hover': {
            backgroundColor: green[700],
            borderColor: green[700],
        },
    },
    error: {
        backgroundColor: red[400],
        borderColor: red[400],
        '&:hover': {
            backgroundColor: red[700],
            borderColor: red[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    loading: {
        borderColor: grey[500],
    }
}))

const SubmitButtonComponent = ({ contClassName = '', title, loading, success, error, className = '' }) => {
    const classes = useStyles();

    return (
        <Box className={classNames([classes.cont, contClassName])}>
            <ButtonComponent
                className={classNames([
                    className, 
                    loading ? classes.loading : '', 
                    success ? classes.success  : '',
                    error ? classes.error  : '',
                ])}
                variant='contained'
                color='primary'

                disabled={loading}
                type='submit'
            >{title}</ButtonComponent>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Box>
    )
}


SubmitButtonComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(SubmitButtonComponent);