import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NEWS_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_NEWS_POST_DATA_REDUCER = createAsyncThunk(
    'NewsPostReducer/fetchNewsPostData',
    async (slug) => {
        const response = await GET(`${NEWS_API}/${slug}`);
        return response.body
    }
)

const initialState = {
    data: {},
    loading: false,
    error: {},
};

export const NewsPostReducer = createSlice({
    name: 'NewsPostReducer',
    initialState,
    reducers: {
        RESET_NEWS_POST_REDUCER: (state) => {
            state.error = {}
        }
    },
    extraReducers: {
        [FETCH_NEWS_POST_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_NEWS_POST_DATA_REDUCER.fulfilled]: (state, { payload }) => {
            state.data = {
                ...state.data,
                ...payload.data
            }
            state.loading = false;
        },
        [FETCH_NEWS_POST_DATA_REDUCER.rejected]: (state, { error }) => {
            state.data = {};
            state.loading = false;
            state.error = error;
        }
    }
})

export const { RESET_NEWS_POST_REDUCER } = NewsPostReducer.actions

export default NewsPostReducer.reducer