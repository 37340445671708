import superagent from 'superagent';
import { API_URL } from '../constants';

// const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const POST = async (url, data = {}) => {
    // const lang = getLang();
    return superagent
        .post(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data);
};

export const PUT = async (url, data = {}) => {
    return superagent
        .put(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('enctype', 'multipart/form-data')
        .send(data)
}


export const GET = async (url, query = {}) => {
    // const lang = getLang();
    return superagent
        .get(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .query(query);
};
