import * as React from "react"

function BtnSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={86.565}
            height={78.623}
            viewBox="0 0 86.565 78.623"
            {...props}
        >
            <path
                d="M40.75 78.623a30.769 30.769 0 01-7.76-.966 45.869 45.869 0 01-9.128-3.592 60.475 60.475 0 01-9.016-5.7 47.872 47.872 0 01-7.844-7.445 27.767 27.767 0 01-5.248-9.251A38.424 38.424 0 01.087 37.588 39.554 39.554 0 011.164 30.7a40.233 40.233 0 019.99-18.122 39.358 39.358 0 0111.548-8.339 39.047 39.047 0 018.548-2.73 71.39 71.39 0 019.9-1.341A75.132 75.132 0 0146.118 0a47.007 47.007 0 0114.607 1.987 35.149 35.149 0 017.805 3.7 32.964 32.964 0 015.972 4.913 44.964 44.964 0 014.732 5.868c1.576 2.293 3 4.716 4.069 6.569a23.712 23.712 0 012.649 7.3 35.906 35.906 0 01.6 7.354 71.281 71.281 0 01-1.7 13.334 22.194 22.194 0 01-2.9 7.2 26.9 26.9 0 01-4.636 5.514A49.081 49.081 0 0166.802 70.8c-6.36 3.357-15.981 7.823-26.052 7.823zM46.107 12.1c-1.353 0-2.745.047-4.139.14-5.651.379-11.011 1.493-13.989 2.91a27.7 27.7 0 00-13.39 13.84 27.145 27.145 0 00-1.18 19.095c1.018 3.255 4.261 7.157 8.895 10.7a48.057 48.057 0 007.163 4.535 33.554 33.554 0 006.623 2.636 18.582 18.582 0 004.682.572 26.3 26.3 0 004.964-.5 39.36 39.36 0 005.112-1.379 70.369 70.369 0 0010.217-4.524 37.918 37.918 0 007.958-5.25 11.843 11.843 0 003.891-6.308 58.274 58.274 0 001.443-11.039c.052-3.643-.5-6.5-1.648-8.489-1.367-2.377-2.483-4.184-3.512-5.687a33.24 33.24 0 00-3.494-4.37 20.91 20.91 0 00-3.971-3.241 23.2 23.2 0 00-4.944-2.3A35.483 35.483 0 0046.11 12.1z"
                fill="#8fb1ee"
            />
        </svg>
    )
}

export default BtnSvg
