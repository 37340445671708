import React, { useEffect, useMemo } from 'react'
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import HeaderComponent from './HeaderComponent';
import loadable from '@loadable/component'
import { useDispatch, useSelector } from 'react-redux';
// import NewsletterComponent from './NewsletterComponent';
import HeaderBgrSvg from '../svgs/HeaderBgrSvg';
import FamilyBgrSvg from '../svgs/FamilyBgrSvg';
import { useTranslation } from 'react-i18next';
import { MAIN_ROUTE, EMPTY_ROUTE } from '../constants/routes';
import LinkComponent from './LinkComponent';
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet";
import { FETCH_MENU_REDUCER } from '../reducers/MainReducer';
const FooterComponent = loadable(() => import('./FooterComponent'))
const NewsletterComponent = loadable(() => import('./NewsletterComponent'))

const useStyles = (showLargeBgr, showAnim, space) => makeStyles((theme) => ({
    cont: {
        marginTop: 150,
        // position: showAnim ? 'relative' : 'relative',
        zIndex: showAnim ? 0 : 2,
        marginBottom: space ? 240 : 0,
        [theme.breakpoints.down("md")]: {
            marginTop: 80,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 0,
            marginBottom: space ? 80 : 0,
        },
    },
    main: {
        position: 'relative',
        overflow: 'hidden',
    },
    familyBgr: {
        position: 'absolute',
        zIndex: 1,
        height: '120%',
        maxWidth: '80%',
        right: showLargeBgr ? '-15%' : '-32%',
        top: showLargeBgr ? '-50%' : '-60%',
        '& path:nth-of-type(5)': {
            animation: `$familyBgrAnim 5.5s ease-in-out infinite`,
        },
        // animation: showLargeBgr ? `$familyBgrAnim 2s ease-in forwards` : 'none',
        [theme.breakpoints.down("sm")]: {
            maxWidth: '100%',
            top: showLargeBgr ? '-55%' : '-60%',
        },
        [theme.breakpoints.down("xs")]: {
           display: 'none',
        },
    },
    headerBgr: {
        position: 'absolute',
        zIndex: 1,
        top: 400,
        left: '-15%',
        '& path:first-of-type': {
            animation: `$headerBgrAnim 4.5s ease-in-out infinite`,
            animationDelay: '2s',
        },
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    },
    breadcrumbs: {
        position: 'relative',
        zIndex: 2,
        marginBottom: 80,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down("sm")]: {
            marginTop: 40,
            marginBottom: 40,
        },
    },
    hr: {
        width: 70,
        margin: '0 20px',
        height: 2,
        background: theme.palette.primary.main,
        border: 'none',
    },
    // title: {
    //     fontWeight: 900,
    //     textTransform: 'uppercase',
    // },
    "@keyframes headerBgrAnim": {
        "0%": {
            transform: "matrix(1, 0, 0, 1, 0, 0)"
        },
        "50%": {
            transform: 'matrix(1, 0.2, 0, 1, 0, 0)',
        },
        "100%": {
            transform: "matrix(1, 0, 0, 1, 0, 0)"
        }
    },
    // "@-webkit-keyframes headerBgrAnim": {
    //     "0%": {
    //         transform: "matrix(1, 0, 0, 1, 0, 0)"
    //     },
    //     "50%": {
    //         transform: 'matrix(1, 0.2, 0, 1, 0, 0)',
    //     },
    //     "100%": {
    //         transform: "matrix(1, 0, 0, 1, 0, 0)"
    //     }
    // },
    "@keyframes familyBgrAnim": {
        "0%": {
            transform: "matrix(1, 0, 0, 1, 0, 0)"
        },
        "50%": {
            transform: 'matrix(1, 0.3, 0, 1, 0, 0)',
        },
        "100%": {
            transform: "matrix(1, 0, 0, 1, 0, 0)"
        }
    },
    // "@-webkit-keyframes familyBgrAnim": {
    //     "0%": {
    //         right: '-80%',
    //         top: '-80%',
    //     },
    //     "100%": {
    //         right: '-10%',
    //         top: '-20%',
    //         // transform: "translateY(-5px)"
    //     },
    // },
}))

const MainContainerComponent = ({ showLargeBgr, hiddenBreadcrumbs, children, title, showAnim, space }) => {
    const classes = useStyles(showLargeBgr, showAnim, space)();
    const dispatch = useDispatch();
    const history = useHistory();
    const { data } = useSelector(state => state.MainReducer);
    const { t } = useTranslation();
    const state = useSelector(state => state);
    const errorsArray = useMemo(() => Object.keys(state).filter((key) => state[key].error).map((key) => state[key].error), [state]);
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [history, window.location.href])

    useEffect(() => {
        if (!data?.menu) {
            dispatch(FETCH_MENU_REDUCER());
        }
    }, [dispatch, data])
    useEffect(() => {
        if (errorsArray?.length && errorsArray.find((error) => error.status === 404))
            history.push(EMPTY_ROUTE)
        // eslint-disable-next-line
    }, [errorsArray])

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'Pageview',
                page: {
                    title
                }
            });
        }
    }, [title])
    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <HeaderComponent />
            <section className={classes.main}>
                <HeaderBgrSvg
                    className={classes.headerBgr}
                />
                <FamilyBgrSvg
                    className={classes.familyBgr}
                />
                {children
                    ? <Box className={classes.cont}>
                        {!hiddenBreadcrumbs
                            ? <Container className={classes.breadcrumbs}>
                                <LinkComponent to={MAIN_ROUTE}>
                                    <Typography variant='button' className={classes.title}>{t('title')}</Typography>
                                </LinkComponent>
                                <hr className={classes.hr} />
                                <Typography variant='button' className={classes.title} color='primary'>{title}</Typography>
                            </Container>
                            : ''
                        }
                        {children}
                    </Box>
                    : ''
                }
                <NewsletterComponent />
                <FooterComponent />
            </section>
        </>
    )
}


MainContainerComponent.propTypes = {
    showLargeBgr: PropTypes.bool,
    hiddenBreadcrumbs: PropTypes.bool,
    showAnim: PropTypes.bool,
    title: PropTypes.string,
}
export default React.memo(MainContainerComponent);