import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MENU_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_MENU_REDUCER = createAsyncThunk(
    'MainReducer/fetchMenu',
    async () => {
        const response = await GET(MENU_API);
        return response.body.data
    }
)


export const MainReducer = createSlice({
    name: 'MainReducer',
    initialState: {
        data: {},
        loading: false,
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [FETCH_MENU_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_MENU_REDUCER.fulfilled]: (state, { payload}) => {
            state.data.menu = payload?.menu || []
            state.data.bottom_menu = payload?.bottom_menu || []
            state.loading = false;
        },
        [FETCH_MENU_REDUCER.rejected]: (state, { payload}) => {
            state.data = {};
            state.loading = false;
            state.error = payload;
            state.error = {};
        }
    }
})

// export const { } = MainReducer.actions

export default MainReducer.reducer