import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    text: {
        width: '100%',
        height: 20,
        marginBottom: 10,
    }
}))

const TextSkeletonComponent = (props) => {
 const classes = useStyles();

    return (
        <Skeleton
            variant="rect"
            className={classes.text}
        />
    )
}

export default React.memo(TextSkeletonComponent);