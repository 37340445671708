import * as React from "react"

function FriendlyBgrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={455.952}
            height={375.453}
            viewBox="0 0 455.952 375.453"
            {...props}
        >
            <path
                d="M140.03 101.77c2.777-16.727 3.467-31.66 12.366-49.47 2.972-5.943 4.86-11.977 8.688-14.63 11.082-7.67 23.308-13.27 35.035-19.964 6.746-3.853 13.723-7.159 20.5-10.84 4.026-2.188 8.033-4.926 12.584-5.829 16.245-3.243 30.261 1.291 43.514 11.175 15.093 11.254 25.135 27.269 34.336 43.421 1.374 2.416 2.522 5.764 5.208 6.934 5.023 2.178 10.348 2.337 15.744 2.5l.185.007c17.616.532 35-3.71 52.68-2.272 14.982 1.219 29.017 6.895 42.265 13.93 6.425 3.41 13.608 8.374 17.6 14.471 4.065 6.2 6.291 13.832 9.885 20.336 2.213 4 4.833 7.557 5.217 12.224a58.144 58.144 0 01-.8 13.91c-2.051 13.036-6.412 25.03-7.425 38.388-.96 12.652-4.657 23.974-9.159 35.682-6.64 17.258-13.665 33.762-22.5 50.06-3.887 7.166-7.5 15.945-12.392 22.428-2.262 3-4.948 6.481-8.114 8.57-8.537 5.637-18.052 10.334-28.181 11.827-23.163 3.42-46.291 6.673-69.32 11.2-26.762 5.255-58.351 9.8-83.883-2.876-14.96-7.426-30.346-17.408-41.468-29.978-10.478-11.836-19.985-27.171-27.436-41.1-11.839-22.121-18.17-49.725-11.435-74.385 1.8-6.6.6-13.173 1.022-19.914.54-8.59 4.261-16.42 5.2-24.827.787-6.943-1.112-13.799.084-20.978z"
                fill="#bcd0c7"
            />
            <path
                data-name="Shape"
                d="M109.62 375.453h-.006a82.767 82.767 0 01-20.874-2.6 123.386 123.386 0 01-24.555-9.662 162.677 162.677 0 01-24.254-15.343 128.775 128.775 0 01-21.086-20.017c-6.766-8.2-11.515-16.573-14.116-24.885a103.361 103.361 0 01-4.493-37.878 106.4 106.4 0 012.9-18.53 108.226 108.226 0 0126.867-48.748 105.872 105.872 0 0131.073-22.432 105.035 105.035 0 0122.993-7.342 192.039 192.039 0 0126.626-3.607c4.484-.299 8.98-.451 13.364-.451 15.357 0 28.944 1.848 39.291 5.346a94.55 94.55 0 0121 9.962 88.673 88.673 0 0116.074 13.216 120.954 120.954 0 0112.729 15.784c4.24 6.169 8.076 12.685 10.944 17.671a63.784 63.784 0 017.127 19.639 96.586 96.586 0 011.616 19.783c-.194 13.618-2.7 26.993-4.566 35.868a59.7 59.7 0 01-7.809 19.361 72.351 72.351 0 01-12.47 14.833c-9.071 8.351-19.39 14.283-28.287 18.982-17.118 9.035-42.998 21.05-70.088 21.05zm14.41-178.954c-3.639 0-7.385.126-11.134.376-15.2 1.019-29.62 4.017-37.631 7.829a74.508 74.508 0 00-36.02 37.229 73.02 73.02 0 00-3.173 51.365c2.74 8.755 11.461 19.252 23.926 28.8a129.272 129.272 0 0019.269 12.2 90.26 90.26 0 0017.817 7.091 49.984 49.984 0 0012.6 1.538 70.742 70.742 0 0013.354-1.358 105.878 105.878 0 0013.75-3.709 189.291 189.291 0 0027.485-12.17c9.664-5.1 16.466-9.591 21.406-14.124 5.753-5.278 9.177-10.828 10.467-16.967 2.527-12.037 3.759-21.473 3.881-29.695.141-9.8-1.351-17.481-4.434-22.836-3.678-6.394-6.681-11.254-9.447-15.3a89.414 89.414 0 00-9.4-11.754 56.248 56.248 0 00-10.682-8.718 62.4 62.4 0 00-13.3-6.186c-6.873-2.328-17.075-3.6-28.725-3.6z"
                fill="#fff"
            />
        </svg>
    )
}

export default FriendlyBgrSvg
