import * as React from "react"

function FacebookSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={44}
            height={44}
            viewBox="0 0 44 44"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 133">
                <path
                className='facebook-letter'
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1184"
                    d="M19.7 32V21.7l-.85-.175a1.755 1.755 0 01-.706-.312.749.749 0 01-.269-.625v-1.525H19.7v-.738a5.427 5.427 0 01.356-2.012 4.259 4.259 0 011.025-1.538 4.614 4.614 0 011.631-.987 6.375 6.375 0 012.188-.35 6.3 6.3 0 01.875.056 6.2 6.2 0 01.838.181l-.075 1.887a.662.662 0 01-.1.331.668.668 0 01-.231.212 1.14 1.14 0 01-.306.119 1.441 1.441 0 01-.325.038 3.776 3.776 0 00-.913.1 1.462 1.462 0 00-.669.356 1.6 1.6 0 00-.406.675 3.438 3.438 0 00-.138 1.057v.613h3.038v2.65h-2.913V32z"
                    fill="#fff"
                />
                <g
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 28"
                    fill="none"
                    stroke="#fff"
                    strokeWidth={2}
                >
                    <path stroke="none" d="M0 0h44v44H0z" />
                    <path d="M1 1h42v42H1z" />
                </g>
            </g>
        </svg>
    )
}

export default FacebookSvg
