import * as React from "react"

function FamilyBgrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1285.121}
            height={1394.707}
            viewBox="0 0 1285.121 1394.707"
            {...props}
        >
            <path
                d="M237.099 967.986c123.904 113.435 300.382 88.508 426.46-44.279s100.762-340.502-45.49-426.33-307.507-82.753-426.462 44.275-78.414 312.899 45.492 426.334z"
                fill="#f9f9f9"
            />
            <path
                data-name="Shape"
                d="M910.454 408.123c66.853 36.628 149.59 39.873 198.588-20.654s39.531-149.417-21.147-198.537-145.051-25.819-198.587 20.655-45.704 161.904 21.146 198.536z"
                fill="#f9f9f9"
            />
            <path
                data-name="Shape"
                d="M1080.428 920.789c57.158 46.27 57.213 122.22 19.732 187.256s-101.11 124.902-145.497 179.732-126.44 68.928-187.262 19.692-70.312-137.26-19.73-187.255 91.387-125.38 145.497-179.732 130.098-65.967 187.26-19.693z"
                fill="#eff3f0"
            />
            <path
                data-name="Shape"
                d="M282.422 1027.551c21.035 17.029 49.603 13.163 65.888-6.953s13.657-53.347-6.921-65.888-50.889-17.307-65.886 6.95-14.118 48.862 6.919 65.891zM376.455 964.917c-7.982 2.988-10.92 11.54-7.82 20.296s13.073 12.397 20.316 7.767 10.824-11.994 7.825-20.292-12.339-10.757-20.321-7.77z"
                fill="#e0436b"
            />
            <path
                data-name="Shape"
                d="M385.174 547.844a13.76 13.76 0 0019.667-2.075c4.86-6.004 4.077-15.924-2.065-19.668s-15.19-5.166-19.667 2.076-4.216 14.583 2.065 19.667zM614.558 1150.11c22.833 18.484 64.472-4.648 90.077-29.718s21.997-69.809-9.736-90.811-68.448-18.284-90.846 9.385-12.327 92.66 10.505 111.145z"
                fill="#7252a4"
            />
            <path
                data-name="Shape"
                d="M961.614 815.446c19.128 17.512 46.372 13.664 65.837-6.836s15.554-52.566-7.024-65.816-47.472-12.775-65.836 6.834-12.106 48.306 7.023 65.818zM1010.213 856.94c6.28 5.749 15.23 4.483 21.618-2.244s5.108-17.262-2.305-21.612-15.591-4.192-21.62 2.246-3.973 15.86 2.307 21.61z"
                fill="#f0c933"
            />
            <path
                data-name="Shape"
                d="M578.467 658.959c19.128 17.512 46.372 13.664 65.837-6.836s15.554-52.566-7.024-65.816-47.472-12.775-65.836 6.834-12.106 48.306 7.023 65.818z"
                fill="#453264"
            />
            <path
                data-name="Shape"
                d="M104.75 594.14c6.278 5.749 15.23 4.483 21.617-2.244s5.108-17.262-2.305-21.612-15.591-4.192-21.62 2.246-3.973 15.86 2.307 21.61z"
                fill="#e0436b"
            />
            <path
                data-name="Shape"
                d="M935.127 967.112c6.28 5.749 15.23 4.483 21.618-2.244s5.108-17.262-2.305-21.612-15.591-4.192-21.62 2.246-3.973 15.86 2.307 21.61zM567.596 681.489c-4.32 7.338-1.222 15.834 6.688 20.686s17.951 1.41 20.667-6.747.862-16.127-6.69-20.688-16.345-.592-20.665 6.749z"
                fill="#453264"
            />
            <path
                data-name="Shape"
                d="M1046.523 534.752c19.128 17.512 46.372 13.664 65.837-6.836s15.554-52.566-7.024-65.816-47.472-12.775-65.836 6.834-12.106 48.306 7.023 65.818z"
                fill="#0f90d1"
            />
            <path
                data-name="Shape"
                d="M1010.723 525.985c60.676 49.12 60.732 129.763 20.926 198.808S924.291 857.412 877.162 915.63s-134.24 73.197-198.809 20.927-74.632-145.723-20.927-198.808 97.033-133.122 154.487-190.838 138.131-70.047 198.81-20.926z"
                fill="#171c8f"
            />
            <path
                data-name="Shape"
                d="M706 510.69c25.837 23.655 62.637 18.457 88.928-9.231s21.013-71.007-9.485-88.903-64.123-17.258-88.928 9.231-16.351 65.248 9.485 88.904zM1044.402 1191.538c48.138 44.071 116.703 34.387 165.685-17.2s39.148-132.292-17.673-165.638-119.47-32.15-165.687 17.202-30.463 121.566 17.675 165.636z"
                fill="#c4c8d4"
            />
            <path
                data-name="Shape"
                d="M937.954 478.692c10.9 5.969 24.4 6.485 32.403-3.402a23.039 23.039 0 00-3.42-32.4c-9.892-8.008-23.661-4.193-32.403 3.401s-7.482 26.434 3.42 32.401zM821.81 379.483c6.608 3.614 14.79 3.927 19.639-2.063a13.96 13.96 0 00-2.072-19.638c-5.994-4.852-14.341-2.542-19.64 2.062s-4.534 16.018 2.072 19.639z"
                fill="#eff3f0"
            />
            <path
                data-name="Shape"
                d="M718.79 88.94a141.355 141.355 0 0120.928 198.809c-49.12 60.678-113.97 126.293-154.487 190.837s-144.098 77.38-198.809 20.928-75.703-131.144-20.927-198.809l154.487-190.837c53.826-66.49 138.13-70.048 198.809-20.927z"
                fill="#dcd7f5"
            />
        </svg>
    )
}

export default FamilyBgrSvg
