import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ButtonComponent from './form/ButtonComponent';
import { useTranslation } from 'react-i18next';
import LinkComponent from './LinkComponent';
import { NEWS_ROUTE } from '../constants/routes';
import moment from 'moment';
import { DATE_FORMAT } from '../constants';

const useStyles = makeStyles((theme) => ({
   title: {
      marginTop: 10,
   },
   img: {
      width: '100%',
      height: 200,
      objectFit: 'cover',
      marginBottom: 20,
      marginTop: 40,
      [theme.breakpoints.down("md")]: {
         height: 350,
      },
   },
   btnCont: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down("sm")]: {
         display: 'block'
      },
   },
   btn: {
      [theme.breakpoints.down("sm")]: {
         width: '100%',
      },
   },
   dateCont: {
      padding: '15px 40px',
      background: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
         textAlign: 'center',
      },
   }
}))

const SelectedNewsCardComponent = ({ title, date, slug, subtitle, src }) => {
   const classes = useStyles();
   const { t } = useTranslation();
   return (
      <Box>
         <Typography color='primary' variant='button'>{subtitle}</Typography>
         <Typography variant='h3' className={classes.title}>{title}</Typography>
         <LazyLoadImage
            alt={title}
            className={classes.img}
            src={src}
         />
         <Box className={classes.btnCont}>
            <Box className={classes.dateCont}>
               <Typography
                  variant='button'
                  color='textSecondary'
                  component='p'
               >
                  {moment(date).format(DATE_FORMAT)}
               </Typography>
            </Box>
            <LinkComponent to={`${NEWS_ROUTE}/${slug}`}>
               <ButtonComponent
               className={classes.btn}
                  color='primary'
                  variant='contained'
               >
                  {t('btns.more')}
               </ButtonComponent>
            </LinkComponent>
         </Box>
      </Box>
   )
}


SelectedNewsCardComponent.propTypes = {
   subtitle: PropTypes.string,
   title: PropTypes.string,
   src: PropTypes.string,
   slug: PropTypes.string,
   date: PropTypes.string,
}
export default React.memo(SelectedNewsCardComponent);