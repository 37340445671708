// import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
import MainReducer from './MainReducer'
import MainPageReducer from './MainPageReducer'
import EventsReducer from './EventsReducer'
import NewsReducer from './NewsReducer'
import NewsPostReducer from './NewsPostReducer'
import EventsPostReducer from './EventsPostReducer'
import PartnersReducer from './PartnersReducer'
import { configureStore } from '@reduxjs/toolkit'

export default configureStore({
    reducer: {
        MainReducer,
        MainPageReducer,
        EventsReducer,
        NewsReducer,
        NewsPostReducer,
        EventsPostReducer,
        PartnersReducer,
    },
    // devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    
})