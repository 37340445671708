import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { classNames } from '../functions';
import SquareBoxComponent from './SquareBoxComponent';
import LinkComponent from './LinkComponent';
import NewsHeaderComponent from './NewsHeaderComponent';

const hoverEffect = (theme) => ({
    background: theme.palette.primary.main,
    '& .post-text': {
        color: theme.palette.text.white
    },
    '& .btn-more-box': {
        background: theme.palette.background.white
    },
    '& .post-btn': {
        color: theme.palette.primary.main
    },
})
const useStyles = (first, gray) => makeStyles((theme) => ({
    link: {
        padding: 5,
        flexBasis: '50%',
        minWidth: '50%',
        [theme.breakpoints.down("sm")]: {
            minWidth: '100%',
            flexBasis: '100%',
        },
    },
    postCont: {
        width: '100%',
        padding: 40,
        transition: 'all 0.2s ease-in',
        '& *': {
            transition: 'all 0.2s ease-in'
        },
        '& .btn-more-box': {
            background: theme.palette.primary.main,
        },
        '& .post-btn': {
            color: theme.palette.text.white
        },
        ...(first ? hoverEffect(theme)
            : {
                background: theme.palette.background.white,
            }
        ),

        '&:hover': {
            ...hoverEffect(theme),
        },
        ...(gray
            ? {
                background: theme.palette.background.gray,
            }
            : {}
        ),

    },
    imgCont: {
        [theme.breakpoints.down("xs")]: {
            marginTop: 20,
        },
    },
    img: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    moreTextBox: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        marginTop: 8,
    },
    intro: {
        marginTop: 20,
    },
    square: {
        [theme.breakpoints.down("xs")]: {
            '&::after': {
                paddingBottom: '40%',
            },
        },
    }
}))

const NewsCardComponent = ({ date, intro, title, src, first, to, gray, localization }) => {
    const classes = useStyles(first, gray)();
    const { t } = useTranslation();
    return (
        <LinkComponent to={to} className={classes.link}>
            <Box className={classes.postCont}>
                <NewsHeaderComponent
                    date={date}
                    localization={localization}
                />
                <Grid container justify='space-between' className={classes.content}>
                    <Grid item sm={7} xs={12}>
                        <Typography variant='h3' className='post-text'>{title}</Typography>
                        <Typography className={classNames([classes.intro, 'post-text'])}>{intro}</Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.imgCont}>
                        <SquareBoxComponent className={classes.square}>
                            <LazyLoadImage
                                src={src}
                                className={classes.img}
                                alt={title}
                            />
                        </SquareBoxComponent>
                        <SquareBoxComponent className={classes.square}>
                            <Box className={classNames([classes.moreTextBox, 'btn-more-box'])}>
                                <Typography align='center' variant='button' className={classNames([classes.btnText, 'post-btn'])}>{t('btns.more')}</Typography>
                            </Box>
                            {/* {t('btns.more')} */}
                        </SquareBoxComponent>
                    </Grid>
                </Grid>
            </Box >
        </LinkComponent>
    )
}


NewsCardComponent.propTypes = {
    date: PropTypes.string,
    gray: PropTypes.bool,
    first: PropTypes.bool,
    title: PropTypes.string,
    intro: PropTypes.string,
    src: PropTypes.string,
    localization: PropTypes.string,
}
export default React.memo(NewsCardComponent);