import * as React from "react"

function EducationSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={208.535}
            height={174.79}
            viewBox="0 0 208.535 174.79"
            {...props}
        >
            <g data-name="Group 504">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 482">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 975"
                        d="M132.895 79.295a49.981 49.981 0 01-43.621 55.64 46.09 46.09 0 01-3.159.278 50 50 0 01-48.138-71.1c.284-.617.584-1.222.9-1.823.472-.907.966-1.8 1.495-2.673l.054-.091a49.994 49.994 0 0192.472 19.77"
                        fill="#6595ef"
                        opacity={0.302}
                    />
                </g>
                <g data-name="Awards">
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 70">
                        <path
                            d="M129.655 90.822c-.119 3-3.151 5.207-4.347 5.963a.435.435 0 00-.073.674 6.006 6.006 0 011.834 4.178.435.435 0 00.635.386c1.983-1.025 7.527-4.473 7.228-10.862a.437.437 0 00-.645-.361 3.163 3.163 0 01-2.337.362 7.125 7.125 0 01-1.64-.712.436.436 0 00-.655.372z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 683"
                            d="M130.114 89.923c.784.033 1.487.847 2.465.871.691.017 1.283-.527 1.936-.512 1.381.032 1.024 2.876.267 4.936q-.181.492-.409.963a14.438 14.438 0 01-6.458 6.259c-.293.146-.1.222-.652.067-.7-.194-.612-1.17-.789-1.94a6.942 6.942 0 00-1.626-2.84.991.991 0 01.384-1.448c.3-.2.59-.4.872-.624 1.571-1.225 2.972-2.794 3.087-4.881a.739.739 0 01.093-.344.859.859 0 01.83-.507zm0 1c-.159 2.717-2.415 4.882-4.5 6.224a8.377 8.377 0 011.916 4.346v.1c3.9-2.036 7.1-5.683 6.935-10.33a5.183 5.183 0 01-3.063.221c-.583-.216-1.276-.73-1.284-.565z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 684"
                            d="M6 63.925c-.47-2.967 2.073-5.722 3.1-6.7a.435.435 0 00-.06-.675 6.007 6.007 0 01-2.615-3.739.435.435 0 00-.7-.255C3.98 53.949-.783 58.414.758 64.621a.437.437 0 00.7.227 3.166 3.166 0 012.222-.812 7.156 7.156 0 011.747.378.436.436 0 00.569-.492"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 685"
                            d="M6.036 52.002c.73.043.852 1 1.158 1.7a6.956 6.956 0 002.173 2.52.993.993 0 01-.093 1.494q-.382.377-.733.782c-1.3 1.51-2.36 3.364-2.073 5.391a.673.673 0 010 .181.963.963 0 01-1.35.741 6.682 6.682 0 00-1.1-.254c-1.552-.227-3.273 2.051-3.751.035a10.578 10.578 0 01.275-5.725 14.823 14.823 0 014.892-6.668c.332-.26.295-.21.602-.197zm-.1.992a16.525 16.525 0 00-1.782 1.688 10.938 10.938 0 00-2.959 9.765 5.131 5.131 0 012.961-.816 11.643 11.643 0 011.377.332l-.02-.152c-.3-2.575 1.372-5.089 3.214-6.868a8.439 8.439 0 01-2.728-3.888s.029-.139-.063-.065z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 686"
                            d="M98.644 9.961c1.089-2.8 4.673-3.9 6.051-4.224a.435.435 0 00.288-.613 6.007 6.007 0 01-.375-4.547.435.435 0 00-.475-.573c-2.209.324-8.573 1.781-10.369 7.919a.438.438 0 00.493.551 3.163 3.163 0 012.328.418 7.156 7.156 0 011.319 1.207.436.436 0 00.74-.138"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 687"
                            d="M92.479 27.538c.637.023.971.688.76 1.433a6.51 6.51 0 00.459 4.03.994.994 0 01-.833 1.244c-.346.089-.689.19-1.028.306-1.886.647-3.718 1.674-4.507 3.611a.67.67 0 01-.087.159.958.958 0 01-1.539-.04 6.779 6.779 0 00-.823-.774c-1.107-.882-3.146-.063-3.33-1.33a2.934 2.934 0 01.377-1.39c1.643-4.079 6.144-6.6 10.4-7.241a.689.689 0 01.151-.008zm-.119.949a16.525 16.525 0 00-2.39.56 10.943 10.943 0 00-7.476 6.944 5.176 5.176 0 012.968.787 11.583 11.583 0 011.023.981q.028-.071.059-.141c1.039-2.374 3.749-3.7 6.237-4.314a8.378 8.378 0 01-.4-4.732s.096-.102-.021-.085z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 688"
                            d="M203.246 129.228c-.119 3-3.151 5.206-4.347 5.963a.435.435 0 00-.073.673 6.008 6.008 0 011.834 4.177.434.434 0 00.635.387c1.984-1.025 7.528-4.474 7.229-10.862a.437.437 0 00-.645-.36 3.164 3.164 0 01-2.337.362 7.129 7.129 0 01-1.64-.712.436.436 0 00-.656.372z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 689"
                            d="M161.135 119.688c1.4.1.984 2.9.239 4.935a10.41 10.41 0 01-.409.963 14.422 14.422 0 01-6.458 6.259.655.655 0 01-.137.057.892.892 0 01-.262.044 1 1 0 01-.919-1.064 6.5 6.5 0 00-1.749-3.749.994.994 0 01.383-1.447c.3-.2.59-.4.872-.624 1.574-1.226 2.972-2.836 3.087-4.882a.658.658 0 01.031-.179.959.959 0 011.468-.462 6.694 6.694 0 001.03.464 3.266 3.266 0 002.43-.246.779.779 0 01.394-.069zm-4.427.636c-.158 2.717-2.415 4.881-4.5 6.224a8.427 8.427 0 011.917 4.4v.047c3.86-2.013 7.105-5.6 6.934-10.33a5.184 5.184 0 01-3.063.221c-.585-.214-1.278-.727-1.287-.563z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 690"
                            d="M65.463 168.68c-2.939.622-5.822-1.774-6.849-2.749a.434.434 0 00-.671.095 6.008 6.008 0 01-3.6 2.8.435.435 0 00-.219.711c1.481 1.67 6.186 6.2 12.305 4.337a.438.438 0 00.191-.714 3.164 3.164 0 01-.926-2.177 7.139 7.139 0 01.287-1.764.436.436 0 00-.521-.543"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 691"
                            d="M58.337 165.346c.577.019 1.014.647 1.568 1.078 1.574 1.223 3.44 2.195 5.49 1.792a.658.658 0 01.181-.014.958.958 0 01.809 1.309 6.653 6.653 0 00-.2 1.112c-.093.99.755 1.724.986 2.607.35 1.34-2.54 1.725-4.712 1.5q-.521-.054-1.034-.16a14.427 14.427 0 01-7.654-4.721c-.213-.247-.222-.066-.226-.588-.006-.723.979-.906 1.687-1.27a6.956 6.956 0 002.353-2.273.936.936 0 01.752-.372zm-.025.97a6.686 6.686 0 01-3.757 2.94l-.046.012c2.892 3.237 7.179 5.511 11.718 4.182a5.131 5.131 0 01-.967-2.915 11.577 11.577 0 01.26-1.393l-.15.028c-2.556.431-5.153-1.108-7.026-2.854 0 .002-.007-.037-.032 0z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 692"
                            d="M202.342 49.098c-2.938.622-5.821-1.774-6.848-2.748a.435.435 0 00-.671.095 6.009 6.009 0 01-3.6 2.8.435.435 0 00-.218.711c1.481 1.671 6.186 6.2 12.305 4.336a.437.437 0 00.191-.714 3.164 3.164 0 01-.926-2.176 7.124 7.124 0 01.287-1.765.436.436 0 00-.522-.543"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 693"
                            d="M154.624 85.675c.576.019 1.014.648 1.569 1.078 1.574 1.223 3.438 2.193 5.49 1.792a.68.68 0 01.181-.015.962.962 0 01.81 1.309 6.674 6.674 0 00-.2 1.112 3.956 3.956 0 00.885 2.368.954.954 0 01-.657 1.376 10.583 10.583 0 01-5.732.021 14.847 14.847 0 01-6.911-4.542c-.212-.247-.222-.063-.226-.588-.006-.733.978-.906 1.687-1.27a6.947 6.947 0 002.353-2.273.933.933 0 01.751-.368zm-.025.97a6.7 6.7 0 01-3.709 2.927l-.094.026c2.93 3.28 7.254 5.488 11.718 4.182a5.22 5.22 0 01-.967-2.914 11.474 11.474 0 01.26-1.393l-.151.028c-2.556.431-5.153-1.108-7.026-2.854.002-.001-.007-.039-.03-.002z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 694"
                            d="M169.742 167.096c-.47-2.967 2.072-5.722 3.1-6.7a.435.435 0 00-.06-.675 6.009 6.009 0 01-2.615-3.739.435.435 0 00-.7-.255c-1.744 1.393-6.508 5.858-4.967 12.065a.438.438 0 00.7.227 3.162 3.162 0 012.221-.812 7.14 7.14 0 011.748.378.436.436 0 00.569-.493"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 695"
                            d="M121.479 154.695c.706.042.845.959 1.135 1.644a6.866 6.866 0 002.2 2.573.993.993 0 01-.093 1.5c-.254.251-.5.511-.733.782-1.3 1.511-2.361 3.364-2.074 5.391a.7.7 0 01.005.182.96.96 0 01-1.349.741 6.7 6.7 0 00-1.1-.254c-1.559-.227-3.275 2.045-3.751.035a10.581 10.581 0 01.275-5.725 14.854 14.854 0 014.892-6.667c.324-.261.289-.21.593-.202zm-.1.992a16.529 16.529 0 00-1.781 1.688 10.938 10.938 0 00-2.959 9.764 5.223 5.223 0 012.961-.815 11.6 11.6 0 011.377.332c-.008-.05-.014-.1-.021-.152-.3-2.575 1.373-5.088 3.214-6.868a8.374 8.374 0 01-2.728-3.887s.03-.136-.062-.062z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 696"
                            d="M42.925 22.129c-.119 3-3.151 5.207-4.348 5.963a.435.435 0 00-.073.674 6.008 6.008 0 011.834 4.178.435.435 0 00.635.387c1.983-1.025 7.527-4.473 7.228-10.862a.437.437 0 00-.645-.36 3.164 3.164 0 01-2.337.362 7.138 7.138 0 01-1.64-.712.436.436 0 00-.654.37z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 697"
                            d="M58.997 11.367c.785.033 1.488.846 2.466.871.672.017 1.217-.507 1.908-.512 1.805-.014.749 4.449-.471 6.575a14.852 14.852 0 01-6.1 5.583c-.292.145-.115.2-.625.075-.686-.168-.638-1.134-.8-1.891a6.957 6.957 0 00-1.638-2.9.993.993 0 01.383-1.448q.448-.3.872-.624c1.576-1.228 2.973-2.839 3.087-4.882a.745.745 0 01.093-.345.858.858 0 01.825-.502zm0 1c-.159 2.717-2.415 4.882-4.5 6.223a8.376 8.376 0 011.916 4.346v.1c3.866-2.017 7.106-5.588 6.934-10.33a5.183 5.183 0 01-3.063.221c-.581-.212-1.275-.729-1.283-.564z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 698"
                            d="M13.78 130.657c-.119 3-3.15 5.207-4.348 5.963a.435.435 0 00-.073.673 6.009 6.009 0 011.834 4.178.435.435 0 00.636.387c1.983-1.026 7.527-4.474 7.228-10.862a.437.437 0 00-.645-.36 3.164 3.164 0 01-2.337.362 7.141 7.141 0 01-1.64-.712.436.436 0 00-.655.371z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 699"
                            d="M14.239 129.758c.783.033 1.495.847 2.465.872.685.017 1.253-.528 1.936-.512 1.374.032 1.023 2.876.267 4.936q-.18.492-.409.963a14.435 14.435 0 01-6.458 6.259c-.293.145-.1.222-.652.068-.674-.187-.614-1.138-.777-1.884a6.961 6.961 0 00-1.638-2.9.991.991 0 01.383-1.448q.448-.295.872-.624c1.574-1.226 2.973-2.839 3.086-4.882a.74.74 0 01.094-.345.858.858 0 01.831-.503zm0 1c-.16 2.717-2.415 4.882-4.5 6.224a8.425 8.425 0 011.917 4.4v.047c3.849-2.008 7.106-5.6 6.934-10.33a5.221 5.221 0 01-3.063.221c-.582-.217-1.276-.734-1.282-.566z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 700"
                            d="M168.921 20.612c-2.381 1.831-6.017.914-7.364.48a.435.435 0 00-.564.375 6.009 6.009 0 01-2.034 4.084.435.435 0 00.11.735c2.057.867 8.256 2.916 12.971-1.4a.437.437 0 00-.136-.726 3.164 3.164 0 01-1.775-1.563 7.156 7.156 0 01-.5-1.715.437.437 0 00-.705-.265"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 701"
                            d="M150.241 42.261c1.113.068.942 1.99 1.575 2.789.428.54 1.218.637 1.633 1.159.857 1.078-1.582 2.637-3.62 3.366-.328.117-.663.218-1 .3a14.432 14.432 0 01-8.943-.952c-.3-.132-.232.069-.469-.457-.287-.637.48-1.2.949-1.8a6.958 6.958 0 001.175-3.114.994.994 0 011.358-.632c.342.1.688.194 1.037.27 1.95.423 4.085.466 5.726-.755.337-.235.33-.182.579-.174zm-.1.978c-2.189 1.618-5.3 1.271-7.663.529a8.381 8.381 0 01-2.11 4.254l-.073.064c4.035 1.683 8.913 1.816 12.375-1.29a5.177 5.177 0 01-2.132-2.21c-.212-.585-.261-1.446-.394-1.347z"
                            fill="#dcd6e3"
                        />
                    </g>
                </g>
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 500"
                    fill="#7c628d"
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1231"
                        d="M66.008 78.729a3.594 3.594 0 004.678 2.034l34.372-13.626a3.594 3.594 0 002.034-4.678L93.465 27.883a3.776 3.776 0 00-4.677-2.034L54.212 39.476a3.594 3.594 0 00-2.034 4.678zm24.2-48.812l13.22 33.559-33.559 13.22-13.216-33.559z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1232"
                        d="M73.94 84.221H36.924a3.646 3.646 0 00-3.661 3.661v37.016a3.646 3.646 0 003.661 3.661H73.94a3.646 3.646 0 003.661-3.661V87.882a3.646 3.646 0 00-3.661-3.661zm-.407 40.27H37.331v-36.2h36.2v36.2z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1233"
                        d="M128.651 84.018l-35.8-9.559a3.716 3.716 0 00-4.474 2.644l-9.559 35.8a3.647 3.647 0 002.644 4.474l35.8 9.559a3.647 3.647 0 004.474-2.644l9.356-35.8a3.6 3.6 0 00-2.441-4.474zm-10.779 38.643l-34.982-9.152 9.356-34.982 34.982 9.356z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1234"
                        d="M73.737 63.679c0 .407 0 .61.2.814a2.451 2.451 0 001.017 1.017 2.228 2.228 0 001.627 0 2.451 2.451 0 001.017-1.017 9.9 9.9 0 00.2-2.441v-2.848l8.135-3.051 1.83 2.034.814.814c.2.2.61.407.814.61s.407.2.814.2a1.223 1.223 0 00.814-.2 2.451 2.451 0 001.017-1.017 1.737 1.737 0 000-1.424 6.333 6.333 0 00-1.424-1.83L80.448 44.561l-1.22-1.22a2.031 2.031 0 00-1.017-.814 2.891 2.891 0 00-1.22-.407 4.859 4.859 0 00-2.843 1.012l-.61 1.22a4.478 4.478 0 00-.2 1.627v16.48c.195.406.195.813.399 1.22zm9.559-10.169l-5.9 2.237-.2-9.152z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1235"
                        d="M49.737 115.949h6.508c1.017 0 1.83-.2 2.847-.2a4.636 4.636 0 002.237-.61 5.979 5.979 0 002.442-2.037 6.146 6.146 0 001.017-3.254 5.034 5.034 0 00-4.271-5.085 5.016 5.016 0 003.254-4.678 5.631 5.631 0 00-.2-1.83 6.09 6.09 0 00-.814-1.627l-1.22-1.22a6.091 6.091 0 00-2.034-.814 11.6 11.6 0 00-2.644-.2h-7.122a3.436 3.436 0 00-2.034.61 3.436 3.436 0 00-.61 2.034v15.864a3.436 3.436 0 00.61 2.034c.608 1.013 1.22 1.216 2.034 1.013zm1.627-17.9h3.458a9.5 9.5 0 013.458.61 2.237 2.237 0 011.22 2.034 3.771 3.771 0 01-.407 1.424 2.15 2.15 0 01-1.424 1.22 10.209 10.209 0 01-2.441.407h-3.864zm0 8.542h4.474a6.685 6.685 0 013.458.814 2.174 2.174 0 011.017 2.237q0 3.051-4.271 3.051h-4.678z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1236"
                        d="M96.52 105.577a5.769 5.769 0 001.627 2.237 9.031 9.031 0 002.237 1.627 11.76 11.76 0 002.847 1.017 12.515 12.515 0 003.864.407 8.025 8.025 0 003.051-1.22 5.769 5.769 0 002.034-1.83 6.362 6.362 0 001.017-2.034 1.786 1.786 0 00-.2-1.424 1.351 1.351 0 00-1.017-.814 1.786 1.786 0 00-1.424.2c-.407.407-.814.61-1.017 1.22a5.116 5.116 0 01-5.9 2.034 6.3 6.3 0 01-2.644-1.22 5.11 5.11 0 01-1.22-2.847 8.991 8.991 0 01.407-4.068 9.377 9.377 0 013.051-5.288 4.8 4.8 0 014.678-.814 4.881 4.881 0 012.441 1.424 7.131 7.131 0 011.22 2.847 5.317 5.317 0 00.61 1.627 1.118 1.118 0 001.017.61 1.786 1.786 0 001.424-.2 1.351 1.351 0 00.814-1.017A4.064 4.064 0 00115.03 95a6.645 6.645 0 00-2.237-3.661 10.945 10.945 0 00-4.068-2.034 13.838 13.838 0 00-4.068-.407 11.108 11.108 0 00-3.661 1.22 10.2 10.2 0 00-3.051 2.847 13.56 13.56 0 00-1.83 4.271 15.7 15.7 0 00-.407 3.051 13.427 13.427 0 00.2 2.847 10.727 10.727 0 00.612 2.443z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default EducationSvg
