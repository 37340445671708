import React from 'react'
import NewsPostComponent from '../../components/NewsPostComponent';
import { useSelector } from 'react-redux';
import { FETCH_EVENTS_POST_DATA_REDUCER } from '../../reducers/EventsPostReducer';

const EventsPostPage = (props) => {
    const { data, loading } = useSelector(state => state.EventsPostReducer)
    return (
        <NewsPostComponent
            desc={data?.desc}
            title={data?.title}
            loading={loading}
            localization={data?.localization}
            src={data?.src}
            mobileSrc={data?.src_mobile}
            fetchFun={FETCH_EVENTS_POST_DATA_REDUCER}
            breadcrumbsTitle={data?.subtitle}
            date={data?.date}
            gallery={data?.gallery}
        />
    )
}

export default React.memo(EventsPostPage);