import * as React from "react"

function FriendlyIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={355.577}
            height={244.774}
            viewBox="0 0 355.577 244.774"
            {...props}
        >
            <g data-name="Product-Launch">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 51">
                    <g fill="#fff">
                        <path d="M169.063 0a13.9 13.9 0 0113.432 12.363 6.031 6.031 0 013.526 1.153 4.749 4.749 0 011.054 5.566c-.319.624-1.5.406-1-.63a3.562 3.562 0 00-3.984-4.82s-.681-.012-.754-1.012a12.4 12.4 0 00-22.924-5.54 12.594 12.594 0 00-1.345 9.949s.008.859-1.089.648a6.662 6.662 0 00-7.613 6.321c0 .56.091.426-.039.7-.361.771-1.66-.133-3-.03a5.358 5.358 0 00-4.668 6.367.83.83 0 01-.091.526.6.6 0 01-1.075-.349 6.3 6.3 0 01-.115-.738 6.567 6.567 0 017.81-6.828 9.569 9.569 0 011.859-4.63 7.752 7.752 0 016.577-2.584s.136.258.039-.226a13.9 13.9 0 014.252-12.739 13.714 13.714 0 018.979-3.47z" />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 434"
                            d="M154.892 16.423a7.772 7.772 0 015.215 2.163c.048.047.418.6.03.928-.473.4-1.1-.382-1.75-.817a6.514 6.514 0 00-3.6-1.1s-.575-.134-.584-.575c-.005-.347.184-.602.689-.599z"
                        />
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 42">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 435"
                                d="M285.087 46.926a1.048 1.048 0 01.517.283 13.787 13.787 0 015.2 9.5 6.056 6.056 0 011.994.265 4.773 4.773 0 012.925 5.606 7.451 7.451 0 011.272.008c5.818.442 10.337 7.457 7.263 13.247 0 0-.255.42-.64.36-.757-.118-.144-1.275.162-2.238 1.539-4.846-2.771-10.74-8.271-10.178-2.232.228-1.233-.552-.967-1.359a3.571 3.571 0 00-4.142-4.425.761.761 0 01-.741-.821 12.581 12.581 0 00-4.98-9.191s-.55-1.085.408-1.057z"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 436"
                                d="M277.385 44.504a7.631 7.631 0 013.3.447.588.588 0 01.171.138c.361.425.022 1.089-.859.889a12.8 12.8 0 00-14.167 7.342 12.537 12.537 0 00-.457 8.212s.026.862-1.089.648a6.671 6.671 0 00-7.613 6.321c0 .593.033.5-.013.639-.18.549-1.122.5-1.158-.39a7.79 7.79 0 018.426-7.811s.136.257.039-.226c-1.5-7.806 4.792-16.129 13.231-16.209z"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 437"
                                d="M263.198 60.752a7.718 7.718 0 015.215 2.164c.044.042.362.465.141.8-.354.541-1.115-.177-1.8-.648a6.528 6.528 0 00-3.662-1.142.752.752 0 01-.484-.26c-.251-.376-.106-.918.59-.914z"
                            />
                        </g>
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 43">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 438"
                                d="M77.555 46.308a13.885 13.885 0 0113.433 12.363 5.867 5.867 0 011.992.249 4.75 4.75 0 012.925 5.606 7.413 7.413 0 011.272.008c5.8.442 10.34 7.458 7.263 13.247 0 0-.235.377-.569.366-.793-.027-.24-1.231.073-2.188 1.584-4.856-2.708-10.8-8.253-10.235-.311.032-.709.1-.709.1-1.166-.119-.467-.818-.258-1.454a3.571 3.571 0 00-4.141-4.426s-.681-.012-.754-1.012A12.4 12.4 0 0066.9 53.388a12.59 12.59 0 00-1.345 9.949s.026.862-1.089.648a6.662 6.662 0 00-7.613 6.32c0 .563.107.428-.051.728-.264.5-1.055.121-1.691.009a5.372 5.372 0 00-6.022 4.2 5.274 5.274 0 00.053 2.1.814.814 0 01-.049.46.6.6 0 01-1.117-.283 6.316 6.316 0 01-.115-.739 6.567 6.567 0 017.81-6.828 9.57 9.57 0 011.859-4.63 7.759 7.759 0 016.576-2.584s.137.258.039-.226c-1.5-7.809 4.792-16.129 13.231-16.21z"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 439"
                                d="M63.384 62.649a7.717 7.717 0 015.216 2.164c.043.042.362.465.141.8-.363.555-1.146-.209-1.86-.692a6.514 6.514 0 00-3.6-1.1.694.694 0 01-.464-.232c-.283-.369-.133-.944.567-.94z"
                            />
                        </g>
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 44">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 440"
                            d="M39.096 129.928c-.213 7.858 1.144 16.5 5.766 23.018a7.3 7.3 0 006.061 3.089c2.83-.042 6.7-.266 8.844-1.09a33.715 33.715 0 01-6.983 4.319 1.682 1.682 0 00-.577 2.733c3.16 3.248 9.531 8.991 14.1 7.861 6.2-1.536 9.782-2.3 9.9-2.326-.135.1-4.961 3.6-6.015 5.463-.4.708 1.222 1.116 3.32 1.71 6.483 1.843 9.581.676 14.169-.738a24.445 24.445 0 014.949-1.209s-7.632 5.575-5.418 8.7c4.932 6.963 30.625 1.555 30.625 1.555s-9.336-6.619-18.678-12.094-25.751-17.82-38.91-31.637a63.157 63.157 0 01-15.87-29.781c-1.423.468-3.631 6.059-4.695 13.476"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 441"
                            d="M39.096 129.928c-.213 7.858 1.144 16.5 5.766 23.018a7.3 7.3 0 006.061 3.089c2.83-.042 6.7-.266 8.844-1.09a33.715 33.715 0 01-6.983 4.319 1.682 1.682 0 00-.577 2.733c3.16 3.248 9.531 8.991 14.1 7.861 6.2-1.536 9.782-2.3 9.9-2.326-.135.1-4.961 3.6-6.015 5.463-.4.708 1.222 1.116 3.32 1.71 6.483 1.843 9.581.676 14.169-.738a24.445 24.445 0 014.949-1.209s-7.632 5.575-5.418 8.7c4.932 6.963 30.625 1.555 30.625 1.555s-9.336-6.619-18.678-12.094-25.751-17.82-38.91-31.637a63.157 63.157 0 01-15.87-29.781c-1.423.468-3.631 6.059-4.695 13.476"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 442"
                            d="M39.096 129.928c-.213 7.858 1.144 16.5 5.766 23.018a7.3 7.3 0 006.061 3.089c2.83-.042 6.7-.266 8.844-1.09a33.715 33.715 0 01-6.983 4.319 1.682 1.682 0 00-.577 2.733c3.16 3.248 9.531 8.991 14.1 7.861 6.2-1.536 9.782-2.3 9.9-2.326-.135.1-4.961 3.6-6.015 5.463-.4.708 1.222 1.116 3.32 1.71 6.483 1.843 9.581.676 14.169-.738a24.445 24.445 0 014.949-1.209s-7.632 5.575-5.418 8.7c4.932 6.963 30.625 1.555 30.625 1.555s-9.336-6.619-18.678-12.094-25.751-17.82-38.91-31.637a63.157 63.157 0 01-15.87-29.781c-1.423.468-3.631 6.059-4.695 13.476"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 443"
                            d="M44.546 108.637c.812.228.679.618.806 1.235 2.362 11.121 8.463 21.48 16.308 29.607a186.785 186.785 0 0038.826 31.2c6.1 3.627 12 7.491 17.753 11.539 0 0 .61.5.443 1.053-.145.479-.713.621-1.315.738-7.493 1.456-15.3 2.524-22.66 1.608-3.3-.411-7.011-1.265-8.438-4.013-.919-1.77.327-4.007 1.394-5.354a19.746 19.746 0 011.666-1.825l.046-.044c-4.031 1.169-8.038 2.579-12.412 1.974a26.851 26.851 0 01-6.257-1.592c-.984-.4-1.967-1.242-1.069-2.541a16.1 16.1 0 012.946-2.966c-2.111.492-4.05 1.026-6.259 1.5-3.559.727-7.1-1.572-10.021-3.854a47.9 47.9 0 01-4.736-4.294 2.642 2.642 0 01.8-4.132 25.181 25.181 0 003.471-1.834c-3.268.359-6.746.673-9.376-.984a11.347 11.347 0 01-3.487-3.992c-3.773-6.359-4.919-14.04-4.778-21.392 0 0 .026-1.177.832-1.231 1-.067.906 1.593.924 3.1.088 7.27 1.468 14.895 5.767 20.489a6.427 6.427 0 00.448.523c2.328 2.445 6.4 2.128 10.053 1.667a13.992 13.992 0 003.225-.7 1.14 1.14 0 01.65.009c.711.293.668 1.174-.431 2.007a37.084 37.084 0 01-6.608 3.969c-.542.267-.546.952.075 1.583 3.132 3.156 6.591 6.453 10.737 7.3a9 9 0 003.9-.4 341.326 341.326 0 018.045-1.875 1.2 1.2 0 01.957.14c1.082.9-1.715 2.556-3.664 4.28a15.194 15.194 0 00-1.835 1.846s-.225.015.092.144a20.488 20.488 0 0011.911 1.2c2.888-.666 5.644-1.889 8.574-2.377a5.8 5.8 0 01.974-.048c.8.254.84 1.163-.138 1.932-1.977 1.568-4.1 3.29-4.857 5.588-.411 1.247.9 2.584 2.373 3.256 4.477 2.04 9.785 1.627 14.764 1.247 3.522-.268 7.025-.742 10.5-1.354l.149-.027c-5.58-3.851-11.238-7.525-17.124-10.988a177.262 177.262 0 01-26.18-19.536c-5.733-5.02-11.427-10.174-16.181-16.106a63.944 63.944 0 01-12.239-24.5l-.014-.059c-1.993 3.494-2.721 7.643-3.318 11.759a1.136 1.136 0 01-.6.711c-.675.222-1.239-.383-1.072-1.49.625-4.065 1.446-8.234 3.375-11.715a3.776 3.776 0 011.83-1.944 1.7 1.7 0 01.455-.037z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 444"
                            d="M99.16 170.917c9.342 5.475 18.678 12.094 18.678 12.094s-4.084-15.681-7.36-24.978-10.012-8.53-10.944-7.942-.216 10.4-.216 10.4a85.467 85.467 0 01-4.126-12.5c-1.423-6.194-10.283-12.438-15.235-15.507-8.391-5.2-12.89-10.173-14.655-10.541-1.744-.363-4.155 7.006-4.226 7.221.035-.193 1.2-6.4-.432-9.975-1.657-3.631-12.83-10.814-16.264-9.687a63.156 63.156 0 0015.87 29.78c13.159 13.816 29.57 26.161 38.91 31.636"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 445"
                            d="M45.273 108.502c2.745.042 5.355 1.511 7.873 3.009 2.977 1.771 6.014 3.757 7.878 6.565a10.625 10.625 0 011.359 5.56s1.392-3.215 3.442-2.457c1.907.706 3.4 2.344 5.1 3.719 7.36 5.937 16.191 9.888 22.071 17.163a15.98 15.98 0 013.189 6.3c.536 2.2 1.2 4.372 1.94 6.514l.055.159c-.041-2.44-.285-5.328 1.3-5.886 2.735-.962 6.008.453 8.067 2.408 3.329 3.159 4.42 7.94 5.828 12.485 1.906 6.152 3.633 12.354 5.269 18.566 0 0-.014 1.882-1.316 1.123q-9.253-6.528-18.838-12.179a175.428 175.428 0 01-24.845-18.377c-6.383-5.5-12.73-11.162-17.913-17.767A63.361 63.361 0 0143.8 111.028c-.149-.638-.3-1.528-.3-1.528.112-1.203 1.025-.998 1.773-.998zm.172 1.8a62.607 62.607 0 0016.214 29.173 186.8 186.8 0 0038.826 31.2c5.417 3.219 10.671 6.654 15.845 10.211l.037.026c-2-7.445-4.026-14.834-6.569-22.148-1.2-3.447-3.122-6.933-6.947-7.9a5.391 5.391 0 00-2.708-.075 32.2 32.2 0 00-.207 3.306c.007 2.032.109 4.065.245 6.094a1.161 1.161 0 01-.151.824c-.421.575-1.283.412-1.711-.623a87.059 87.059 0 01-4.018-12.336c-1.044-4.125-4.572-7.3-8.126-10.146-5.158-4.128-10.9-7.246-16.124-11.454a36.086 36.086 0 00-4.451-3.437c-.226-.129-.189-.624-.9.29a20.126 20.126 0 00-2.712 5.9s-.34.942-1.059.824a.926.926 0 01-.706-1.129c.066-.374.067-.4.09-.558.5-3.414.946-7.256-1.177-9.854a25.693 25.693 0 00-12.3-7.959c-.629-.173-1.424-.373-1.391-.229z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 446"
                            d="M79.237 141.307c.843.1.943 1.351 1.131 2.209.9 4.2.9 8.65-1 12.543a1.057 1.057 0 01-.528.509c-.717.256-1.41-.542-.962-1.483 1.836-3.916 1.224-8.631.428-12.7-.188-.989.442-1.099.931-1.078z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 447"
                            d="M48.707 136.774c.491.05.792.456 1.2.723 2.811 1.854 6.631 2.043 9.911 1.6 0 0 .746.017.948.573s-.147 1.116-.984 1.209c-4.113.442-8.549.1-11.655-2.51a1.046 1.046 0 01-.355-.643.866.866 0 01.874-.952z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 448"
                            d="M46.093 146.543c.691.041.817 1.217 1.318 2.028a9.825 9.825 0 001.64 2 .755.755 0 01.141.657c-.159.379-.587.536-1.013.138a10.081 10.081 0 01-2.664-4.048s-.089-.781.578-.775z"
                            fill="#3b1b6a"
                        />
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 45">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 449"
                            d="M42.151 185.562c-8.421-12.1-20.264-10.233-20.264-10.233s4.383 10.6 20.264 10.233z"
                            fill="#ffce93"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 450"
                            d="M23.761 174.343c7.477.063 14.615 4.327 19.014 10.569a1.151 1.151 0 01.213.934c-.34 1-2.106.645-3.736.542-7.1-.451-14.253-3.653-17.747-9.867-.19-.338-.427-.845-.427-.845-.24-.639.226-1.2 1.2-1.279.493-.043.988-.055 1.483-.054zm-.22 1.765h-.229c3.447 5.548 10.375 8.34 17.058 8.562h.025a22.034 22.034 0 00-16.535-8.566z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 451"
                            d="M46.184 190.964c-8.609 4.836-20.164.737-20.164.737 7.232-5.243 20.164-.737 20.164-.737z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 452"
                            d="M35.477 188.281a35.521 35.521 0 0110.9 1.818s1.59.917-.085 1.81c-6.117 3.264-13.925 2.89-20.529.638 0 0-1.409-.807.107-1.819a17.264 17.264 0 019.607-2.447zm-.208 1.766a16.965 16.965 0 00-6.992 1.354l-.05.023c5.113 1.286 10.631 1.576 15.462-.263a34.481 34.481 0 00-8.218-1.115z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 453"
                            d="M49.527 189.638c.838.035 1.284 1.529.131 1.752a20.741 20.741 0 01-3.494.457c-.809-.018-1.293-1.527-.131-1.752a22.636 22.636 0 013.494-.457z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 454"
                            d="M48.382 186.685c3.09-10.764-3.688-16.851-3.688-16.851s-4.147 7.666 3.688 16.851z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 455"
                            d="M48.382 186.685c3.09-10.764-3.688-16.851-3.688-16.851s-4.147 7.666 3.688 16.851z"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 456"
                            d="M48.382 186.685c3.09-10.764-3.688-16.851-3.688-16.851s-4.147 7.666 3.688 16.851z"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 457"
                            d="M44.739 168.952c.776.069 1.277.945 1.841 1.683a18.513 18.513 0 012.728 16.033c-.22.8-1.009 1.288-1.781.368-3.939-4.743-6.194-11.254-3.89-16.993.1-.259.279-.619.279-.619a.756.756 0 01.823-.472zm.254 2.559c-1.316 4.438.164 9.357 2.926 13.161l.037.05a16.4 16.4 0 00-2.937-13.22s-.01-.055-.03.009z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 458"
                            d="M53.985 196.032c-5.742 8.174-16.383 6.463-16.383 6.463 4.495-8.275 16.383-6.463 16.383-6.463z"
                            fill="#ffce93"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 459"
                            d="M51.351 194.982a21.2 21.2 0 012.723.172 1.038 1.038 0 01.559.28.919.919 0 01.072 1.109l-.141.2c-3.759 5.142-10.748 7.539-17.06 6.633 0 0-1.11-.427-.675-1.3a9.655 9.655 0 01.6-.992c2.927-4.362 8.727-6.115 13.922-6.102zm-.256 1.765c-4.508.036-9.194 1.209-11.977 5l.362.012c4.716.105 9.5-1.293 12.789-4.972a15.987 15.987 0 00-1.174-.04z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 460"
                            d="M56.611 192.016c4.594-10.213-1.245-17.205-1.245-17.205s-5.198 6.996 1.245 17.205z"
                            fill="#ff87b0"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 461"
                            d="M55.455 173.933c.741.108 1.118 1 1.561 1.768 2.818 4.9 2.789 11.2.509 16.429-.31.711-1.1 1.261-1.815.11-3.221-5.257-4.525-12.023-1.425-17.374.14-.241.365-.572.365-.572a.748.748 0 01.805-.361zm-.037 2.58c-1.928 4.186-1.174 9.285 1.017 13.444l.029.055a16.41 16.41 0 00-1.02-13.5s.003-.06-.028.001z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 462"
                            d="M62.974 202.134a20.811 20.811 0 00-13.306 10.588s7.334-.258 13.306-10.588z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 463"
                            d="M63.018 201.248a.752.752 0 01.383.109c.9.5-.1 2.01-.9 3.2q-.429.634-.891 1.247c-2.937 3.893-7.116 7.448-11.833 7.8 0 0-1.355-.324-.86-1.352a21.349 21.349 0 0113.6-10.906 1.373 1.373 0 01.501-.098zm-2.091 2.463a20.344 20.344 0 00-9.5 7.709l-.032.049c3.952-1.231 7.158-4.31 9.505-7.7-.001-.001.071-.075.027-.057z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 464"
                            d="M66.856 197.199c4.055-11.756-1.6-19.3-1.6-19.3s-5.496 6.688 1.6 19.3z"
                            fill="#ffce93"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 465"
                            d="M65.357 177.021c.821.131 1.187 1.193 1.644 2.106 2.763 5.514 2.727 12.261.785 18.078-.251.752-1.119 1.478-1.867.127-2.611-4.762-4.361-10.152-3.242-15.5a11 11 0 011.871-4.462 1.268 1.268 0 01.809-.349zm-.125 2.654c-2.243 4.829-.8 10.66 1.4 15.209a21.488 21.488 0 00-1.34-15.214s.011-.14-.059.005z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 466"
                            d="M83.033 201.776c1.555-12.338-5.527-18.563-5.527-18.563s-4.006 7.678 5.527 18.563z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 467"
                            d="M77.612 182.337c.778.128 1.274.992 1.853 1.722 3.842 4.837 5.206 11.539 4.463 17.68-.1.868-.989 1.28-1.784.36-3.534-4.125-6.352-9.041-6.355-14.5a10.99 10.99 0 01.915-4.752 1.161 1.161 0 01.908-.51zm.234 2.621c-1.221 5.263 1.421 10.623 4.5 14.6a21.489 21.489 0 00-4.441-14.618s-.02-.141-.058.018z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 468"
                            d="M89.377 185.258a.209.209 0 00-.27.286c.752 1.481 2.849 6.1 2.4 10.754a10.509 10.509 0 003.923 8.947s1.234-6.975.944-9.848a11.891 11.891 0 00-7-10.138"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 469"
                            d="M89.343 184.362a4.363 4.363 0 011.922.9 12.567 12.567 0 011.56 1.148 13.828 13.828 0 014.452 11.006 64.594 64.594 0 01-.963 7.9 1.076 1.076 0 01-.413.675c-.882.556-2.047-.849-2.993-2.088a11.521 11.521 0 01-2.262-7.893 10.588 10.588 0 00.039-1.223 20.437 20.437 0 00-2.369-8.852s-.198-1.609 1.027-1.573zm1.45 2.781a21.079 21.079 0 011.576 9.423 9.475 9.475 0 002.429 6.861l.027.031c.644-4.166 1.254-8.467-.414-12.074a10.891 10.891 0 00-3.664-4.354z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 470"
                            d="M93.912 209.394s-5.242.092-15.85 14.4a.468.468 0 00.511.727c2.524-.764 8.12-2.774 11.571-6.425 4.239-4.48 3.768-8.702 3.768-8.702z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 471"
                            d="M94.02 208.518c1.1.183.865 2.1.473 3.684-1.635 6.6-8.468 10.946-15.541 13.129a1.691 1.691 0 01-1.088-.031c-.812-.383-.962-1.426-.262-2.365 3.642-4.865 7.552-9.764 12.6-12.92a8.439 8.439 0 013.642-1.5s.088-.004.176.003zm-1.031 2.033c-3.558 1.426-6.387 4.431-9.072 7.417-1.508 1.677-2.928 3.43-4.3 5.222l-.069.091c5.406-1.86 10.91-4.884 12.912-10.464a10.089 10.089 0 00.533-2.243s.022-.033-.004-.023z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 472"
                            d="M101.15 188.482a.312.312 0 00-.431.415 18.76 18.76 0 011.861 9.2c-.225 6.43 5.959 8.473 5.959 8.473a46.979 46.979 0 00-.313-8.476c-.722-5.429-5.1-8.49-7.076-9.609"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 473"
                            d="M101.056 187.558a2.907 2.907 0 011.294.617 14.484 14.484 0 016.83 10.44 44.992 44.992 0 01.242 7.962s-.324 1.145-1.3.777a10.027 10.027 0 01-6.414-9.6 17.938 17.938 0 00-1.777-8.466 1.387 1.387 0 01-.1-.816 1.142 1.142 0 011.225-.914zm1.248 2.782a24.153 24.153 0 011.153 8.121 7.974 7.974 0 004.2 6.731l.05.027c.1-4.546-.048-9.265-2.959-12.675a14.808 14.808 0 00-2.361-2.2s-.155-.2-.083-.003z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 474"
                            d="M106.848 209.998s-4.221-1-7.117 3.9a11.793 11.793 0 01-5.854 5.142.316.316 0 00.078.61c2.1.228 7-.679 8.9-2.649a20.207 20.207 0 003.993-7.003z"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 475"
                            d="M105.977 209.032a8.623 8.623 0 011.126.121.713.713 0 01.294.154c.815.647-.172 2.365-.874 3.8-1.147 2.351-2.654 4.7-4.958 5.857a13.618 13.618 0 01-7.758 1.556 1.23 1.23 0 01-.049-2.383 12.71 12.71 0 005.473-5.108 8.29 8.29 0 016.746-3.997zm-.394 1.776c-2.312.164-4.108 2.013-5.376 4a13.429 13.429 0 01-3.705 3.842c2.617-.384 5.1-1.253 6.748-3.491a19.642 19.642 0 002.355-4.331s.046-.025-.023-.02z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 476"
                            d="M42.236 184.683a.955.955 0 01.428.159c7.525 5.351 15.324 10.689 23.932 13.748a161.193 161.193 0 0027.341 6.98c6.718 1.124 13.6 1.455 20.472 1.974l.411.031c.912.149.983 1.746-.132 1.76a680.539 680.539 0 01-16.975-1.459 125.813 125.813 0 01-33.958-8.467c-7.882-3.175-15.034-8.121-21.957-13.016.001 0-1.271-1.723.438-1.71z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 477"
                            d="M48.423 185.802a.914.914 0 01.8.6c.4 1.282.757 2.58 1.136 3.87.23.847-1.252 1.719-1.682.532-.4-1.282-.758-2.58-1.136-3.87a.879.879 0 01.882-1.132z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 478"
                            d="M56.668 191.135a.915.915 0 01.794.639c.288 1.1.53 2.209.795 3.313a.915.915 0 01-.635 1.059 24.582 24.582 0 01-3.559.765c-.824.071-1.442-1.46-.263-1.742l2.53-.548-.583-2.436a.864.864 0 01.921-1.05z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 479"
                            d="M66.855 197.198l.657 2.642"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 480"
                            d="M66.899 196.319c1.021.068 1.337 1.982 1.491 3.429.085.8-1.386 1.452-1.726.335-.443-1.655-1.367-3.816.235-3.764z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 481"
                            d="M62.975 202.129l4.538-2.287"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 482"
                            d="M67.537 198.96a.9.9 0 01.408 1.652c-1.506.8-3.048 1.537-4.573 2.306-.77.367-1.934-.9-.829-1.558 1.507-.8 3.048-1.537 4.573-2.306a.73.73 0 01.421-.094z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 483"
                            d="M66.753 220.436s10.951-3.327 13.447-13.081c0 0-12.201 3.8-13.447 13.081z"
                            fill="#ff87b0"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 484"
                            d="M80.264 206.475a.721.721 0 01.323.087.837.837 0 01.468 1.016l-.063.237c-1.786 6.468-7.789 11.457-13.9 13.439a.889.889 0 01-1.217-.939 9.342 9.342 0 01.184-1.03c1.445-6.468 8.171-10.91 13.831-12.758.005.004.019-.064.374-.052zm-1.535 2.385c-4.586 1.965-9 4.968-10.625 9.689-.07.2-.156.493-.156.493 4.681-1.978 8.9-5.343 10.83-10.153-.001 0 .111-.097-.049-.029z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 485"
                            d="M83.095 200.897a.931.931 0 01.665.379l2 2.9a.754.754 0 01.137.316.854.854 0 01-.435.957l-4.833 2.68c-.78.408-2.011-.864-.856-1.543l3.981-2.208-1.47-2.133a.876.876 0 01.811-1.348z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 486"
                            d="M95.459 204.362c.767.042 1.132 1.159 1.614 2.031a.916.916 0 01-.1 1 24.848 24.848 0 01-2.5 2.68c-.634.527-2.028-.36-1.211-1.279l1.955-2.105-.573-1.048a.879.879 0 01.815-1.279z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 487"
                            d="M108.585 205.686c.75.054 1.71 1.018 2.479 1.824a.874.874 0 01.144.2.91.91 0 01-.341 1.172c-1.19.656-2.406 1.265-3.608 1.9-.823.412-1.816-1.014-.821-1.562l2.4-1.261-.886-.73a.886.886 0 01.633-1.543z"
                            fill="#3b1b6a"
                        />
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 46">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 488"
                            d="M297.766 213.624c-5.95-2.319-9.406-3.536-9.521-3.577.121.117 4.458 4.205 5.264 6.19.3.754-1.356.949-3.513 1.269-6.666 1-9.589-.559-13.957-2.55a24.44 24.44 0 00-4.753-1.834s6.853 6.508 4.256 9.323c-5.785 6.273-30.571-2.387-30.571-2.387s10.107-5.366 20.075-9.6 27.825-14.368 42.648-26.382a63.158 63.158 0 0019.561-27.5c3.045 1.456 6.7 29.207-6.048 43.022a7.3 7.3 0 01-6.407 2.286c-2.8-.4-6.611-1.124-8.631-2.215a33.7 33.7 0 006.371 5.179 1.681 1.681 0 01.221 2.784c-3.55 2.816-10.606 7.695-14.994 5.987"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 489"
                            d="M327.487 155.73c1.552.59 1.627 1.57 2.251 3.828 2.458 8.9 1.7 18.874-.741 27.3-1.457 5.021-3.719 9.97-7.4 13.613a8.492 8.492 0 01-7.188 2.25q-.563-.084-1.125-.177a50.517 50.517 0 01-3.44-.684s2.381 1.52 3.54 2.505a2.634 2.634 0 01-.039 3.935c-3.428 2.756-7.1 5.332-11.372 6.3-3.284.741-6.718-1.017-10.109-2.289l-.243-.091.224.248c1.007 1.125 2.253 2.251 2.557 3.67.255 1.191-1.525 1.8-2.583 1.982-3.975.687-8.071.873-11.97-.557-1.877-.687-3.675-1.6-5.488-2.394 0 0 .878 1.155 1.468 2.155 1.165 1.974 1.838 4.438-.017 6.1-2.242 2-5.548 2.229-8.542 2.184-7.676-.116-15.343-2.234-22.51-4.688a1.144 1.144 0 01-.634-.624c-.169-.609.558-1.08 1.143-1.386 6.435-3.352 12.936-6.485 19.669-9.358a174.455 174.455 0 0026.723-14.888c9.942-6.55 19.766-13.61 26.638-23.089a54.148 54.148 0 008.118-15.255 1.071 1.071 0 011.07-.59zm.008 2.8a65.284 65.284 0 01-20.121 26.944 188.465 188.465 0 01-42.912 26.2c-5.833 2.519-11.57 5.29-17.223 8.2 7.021 2.209 14.318 4.2 21.349 3.956 2.358-.081 5.018-.407 6.359-2.043a2.377 2.377 0 00-.038-2.368 17.484 17.484 0 00-4.163-5.59.751.751 0 01-.318-.5.922.922 0 01.932-1.088 47.951 47.951 0 014.851 1.822c3.177 1.447 6.448 2.856 9.9 2.869a22.633 22.633 0 006.376-.763 37.326 37.326 0 00-3.156-3.809q-.8-.821-1.622-1.611a1.149 1.149 0 01-.33-.848.925.925 0 011.166-.688c3.212 1.141 6.391 2.381 9.587 3.6 2.484.916 5.41-.357 8.055-1.8a35.246 35.246 0 006.063-4.1c.438-.388.248-1.069-.37-1.43a30.692 30.692 0 01-6.3-5.163s-.454-.631-.154-1.116c.474-.767 1.651-.026 2.714.335 3.111 1.058 6.684 2.054 9.4 1.307a8.287 8.287 0 004.071-3.053c7.166-8.863 8.712-21.724 7.314-33.106a24.529 24.529 0 00-1.373-6.166s-.007-.119-.058.008z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 490"
                            d="M265.046 210.46c-9.967 4.231-20.075 9.6-20.075 9.6s6.062-15.027 10.5-23.827 11.023-7.174 11.873-6.471-1.121 10.343-1.121 10.343a85.447 85.447 0 005.7-11.872c2.206-5.96 11.794-11.016 17.1-13.423 8.989-4.084 14.088-8.435 15.886-8.574 1.776-.135 3.222 7.481 3.265 7.7-.011-.2-.367-6.5 1.708-9.837 2.108-3.389 14.112-9.078 17.372-7.52a63.155 63.155 0 01-19.56 27.5c-14.823 12.013-32.683 22.151-42.648 26.382"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 491"
                            d="M265.046 210.46c-9.967 4.231-20.075 9.6-20.075 9.6s6.062-15.027 10.5-23.827 11.023-7.174 11.873-6.471-1.121 10.343-1.121 10.343a85.447 85.447 0 005.7-11.872c2.206-5.96 11.794-11.016 17.1-13.423 8.989-4.084 14.088-8.435 15.886-8.574 1.776-.135 3.222 7.481 3.265 7.7-.011-.2-.367-6.5 1.708-9.837 2.108-3.389 14.112-9.078 17.372-7.52a63.155 63.155 0 01-19.56 27.5c-14.823 12.013-32.683 22.151-42.648 26.382"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 492"
                            d="M265.046 210.46c-9.967 4.231-20.075 9.6-20.075 9.6s6.062-15.027 10.5-23.827 11.023-7.174 11.873-6.471-1.121 10.343-1.121 10.343a85.447 85.447 0 005.7-11.872c2.206-5.96 11.794-11.016 17.1-13.423 8.989-4.084 14.088-8.435 15.886-8.574 1.776-.135 3.222 7.481 3.265 7.7-.011-.2-.367-6.5 1.708-9.837 2.108-3.389 14.112-9.078 17.372-7.52a63.155 63.155 0 01-19.56 27.5c-14.823 12.013-32.683 22.151-42.648 26.382"
                            fill="rgba(255,255,255,0.3)"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 493"
                            d="M325.843 155.434a6.928 6.928 0 011.919.426c.71.6.333 1.011.148 1.542-3.821 10.964-11.324 20.7-20.537 28.067a190.516 190.516 0 01-42.912 26.2c-6.466 2.793-12.751 5.835-18.962 9.106a1.2 1.2 0 01-.6.161.922.922 0 01-.75-1.211c3.3-8.153 6.692-16.58 10.7-24.211 1.632-3.109 4.135-5.9 7.443-6.87 1.907-.558 4.327-.769 5.665.476.8.74.513 2.19.374 3.544a51.1 51.1 0 01-.279 2.171c1.811-3.632 2.809-7.7 5.36-10.684 4.817-5.628 12.412-8.582 18.768-11.855 3.9-2.01 7.426-4.776 11.408-6.571a2.316 2.316 0 012.712.29 7.016 7.016 0 011.265 2.307 19.09 19.09 0 01.9-3.365c1.389-3.414 5.122-5.326 8.78-7.035 2.755-1.286 5.674-2.495 8.598-2.488zm-.115 1.765c-3.473.055-6.818 1.716-10.017 3.484-2.6 1.435-5.114 2.939-5.994 6.078a22.284 22.284 0 00-.663 7.1s-.08.751-.61.921c-1.162.372-1.378-2.084-1.941-4.045-.37-1.287-.621-2.833-1.589-3.607 0 0-3.406 1.828-5.819 3.3-7.833 4.745-16.944 7.4-23.382 13.843-2.783 2.787-3.844 6.743-5.531 10.239-.964 2-2 3.978-3.1 5.889-.428.739-1.84.7-1.717-.466a50.453 50.453 0 001.315-8.89 9.095 9.095 0 00-.024-.665c-3.7-1.658-8.529 2.526-10.6 6.669-3.409 6.824-6.35 13.843-9.252 20.867l-.105.255q9.113-4.69 18.47-8.717a178.233 178.233 0 0028.314-16c9.08-6.113 17.979-12.654 24.394-21.3a59.675 59.675 0 008.218-14.9 1.38 1.38 0 00-.368-.055z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 494"
                            d="M288.608 183.649c.6.052.584.2.72.439.349.6-.187 1.419-.467 2.2-1.313 3.717-1.853 7.791-.581 11.475a1.193 1.193 0 01-.064.719c-.357.638-1.344.661-1.706-.449-1.413-4.412-.411-9.312 1.155-13.6a1.4 1.4 0 01.943-.784z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 495"
                            d="M319.464 183.069a.684.684 0 01.607.3c1.078 1.226-2.876 2.755-5.529 2.944q-.437.031-.875.04c-.4.008-.8 0-1.206-.012a22.239 22.239 0 01-4.748-.684s-.585-.233-.664-.714c-.107-.645.515-1.134 1.392-.923 3.562.837 7.6 1.075 10.485-.808a1.123 1.123 0 01.538-.143z"
                            fill="#3b1b6a"
                        />
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 47">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 496"
                            d="M296.061 161.216c-10.427 8.265-28.347 16.982-28.348 16.982s-5.3-34.357 4.051-37.4a4.441 4.441 0 01.548-.1 3.925 3.925 0 013.462 1.337 2.366 2.366 0 002.651.964 6.291 6.291 0 002.359-2.126 45.768 45.768 0 013.835-4.643c4.7-4.934 10.941-10.539 17.177-9.934 0 0 6.531 3.035-6.489 16.343 0 0-3.392 4.644-.144 4.169a14.488 14.488 0 016.117.084 3.748 3.748 0 012.543 3.969 9.794 9.794 0 01-1.983 4.731 17.952 17.952 0 01-1.256 1.522"
                            fill="#00d3ae"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 497"
                            d="M296.061 161.216c-10.427 8.265-28.347 16.982-28.348 16.982s-5.3-34.357 4.051-37.4a4.441 4.441 0 01.548-.1 3.925 3.925 0 013.462 1.337 2.366 2.366 0 002.651.964 6.291 6.291 0 002.359-2.126 45.768 45.768 0 013.835-4.643c4.7-4.934 10.941-10.539 17.177-9.934 0 0 6.531 3.035-6.489 16.343 0 0-3.392 4.644-.144 4.169a14.488 14.488 0 016.117.084 3.748 3.748 0 012.543 3.969 9.794 9.794 0 01-1.983 4.731 17.952 17.952 0 01-1.256 1.522"
                            fill="rgba(0,0,0,0.25)"
                            style={{
                                mixBlendMode: "overlay",
                                isolation: "isolate",
                            }}
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 498"
                            d="M300.92 125.363a3.478 3.478 0 013.222 2.735c.851 3.255-1.194 6.546-3.179 9.3a51.707 51.707 0 01-4.876 5.7 6.807 6.807 0 00-1.449 2.819 35.049 35.049 0 014.318-.275c3.178.1 6.576 2.208 5.542 6.442a13.083 13.083 0 01-3.176 5.525c-.633.7-2.415.081-1.063-1.461 1.465-1.693 2.858-3.726 2.689-5.914a3.065 3.065 0 00-2.553-2.664 17.837 17.837 0 00-5.339.138 1.912 1.912 0 01-2.072-1.042c-.477-1.267.358-2.571.981-3.6.3-.493.7-1.044.7-1.044 3.282-3.364 6.932-6.771 7.786-11.577a3.141 3.141 0 00-.94-3.287 5.213 5.213 0 00-1.274 0c-6.682.442-12.088 6.271-16.227 11.049a52.145 52.145 0 00-2.584 3.273 8.374 8.374 0 01-.578.719c-1.389 1.578-3.733 2.432-5.46.727a3.742 3.742 0 00-3.349-1.28 4.546 4.546 0 00-.473.19c-2.424 1.211-3.071 4.185-3.565 6.46-1.486 6.835-1.034 13.977-.425 21.061q.324 3.769.83 7.521c9.526-4.775 18.931-9.885 27.1-16.342 0 0 1.615-.435 1.419.828-.063.409-.47.674-.825.949-8.573 6.642-18.217 11.884-27.955 16.665 0 0-1.109.264-1.313-.635-1.476-9.758-2.471-19.866-.771-29.333.668-3.717 1.739-7.889 5.439-9.048.183-.058 3.263-.288 5.025 1.6a1.43 1.43 0 001.93.423 7.91 7.91 0 001.946-2.091c3.726-4.995 8.216-9.6 13.46-12.492a14.76 14.76 0 017.059-2.039z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 499"
                            d="M288.476 140.793c.728.183.921 1 .1 1.883-8.94 9.743-15.959 21.334-19.541 33.919-.223.78-.5 1.9-.5 1.9-.439 1.022-1.048.65-1.37.4-.515-.4-.25-1.238-.053-1.98 3.544-13.353 11.1-25.652 20.356-35.647a.962.962 0 011.008-.475z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 500"
                            d="M277.663 146.238a.756.756 0 01.309.077c.618.281.474 1.189.395 1.966-.214 2.164-.606 4.409 0 6.488.513 1.77-.22 1.649-.735 1.6-.923-.084-1.142-1.577-1.221-2.835a35.522 35.522 0 01.315-6.449s.197-.87.937-.847z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 501"
                            d="M286.876 156.229c.927.114.855.654.8 1.02-.083.517-.382.638-1.559.868a42.3 42.3 0 00-11.681 4.635 1.132 1.132 0 01-.988-.1c-.508-.431-.318-1.2.5-1.635 3.938-2.057 8.007-4.017 12.352-4.722a3.208 3.208 0 01.576-.066z"
                            fill="#3b1b6a"
                        />
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 50">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 48">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 502"
                                d="M277.207 96.09a.606.606 0 01.192 1.128c-1.091.435-2.2.818-3.3 1.227-.529.183-1.212-.74-.435-1.093 1.091-.434 2.2-.818 3.3-1.227a.654.654 0 01.243-.035z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 503"
                                d="M268.106 89.148a.609.609 0 01.55.611c-.07 1.1-.19 2.19-.285 3.285-.063.567-1.188.77-1.174-.075.07-1.1.19-2.19.286-3.285a.6.6 0 01.623-.536z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 504"
                                d="M273.206 91.576a.614.614 0 01.45.928c-.716.963-1.474 1.893-2.212 2.84-.361.444-1.419 0-.943-.7.716-.963 1.474-1.894 2.211-2.841a.538.538 0 01.494-.227z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 505"
                                d="M134.009 101.336h121.725a15.394 15.394 0 0115.394 15.394v113.306H86.498V116.73a15.394 15.394 0 0115.394-15.394h21.893"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 506"
                                d="M255.86 100.277a16.781 16.781 0 0116.17 16.38v113.3l-.075.376-.213.318-.318.213-.376.075H86.42l-.376-.075a1.3 1.3 0 01-.531-.531l-.075-.376c0-37.85-.118-75.7 0-113.549a16.738 16.738 0 0116.38-16.275h22.024l.376.075.318.213.213.318.075.375-.075.376-.213.318-.318.213-.376.075c-7.372 0-14.744-.023-22.115 0a14.8 14.8 0 00-14.326 14.42v112.617h182.667c0-37.523.117-75.045 0-112.568a14.767 14.767 0 00-14.421-14.327H134.039l-.376-.075-.318-.213-.213-.319-.075-.376.075-.375.213-.318.318-.213.376-.075c40.607.002 81.216-.383 121.821.003z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 507"
                                d="M261.807 118.243v111.793H96.121v-111.86z"
                                fill="#5dbbff"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 508"
                                d="M261.807 118.243v111.793H96.121v-111.86z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 509"
                                d="M262.709 117.193v113.745H95.219V117.125zM97.319 229.134h163.586V119.378l-163.586-.065z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 510"
                                d="M201.369 234.546h-45.718a3.6 3.6 0 01-3.6-3.6v-.906h52.923v.906a3.6 3.6 0 01-3.605 3.6z"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 511"
                                d="M205.067 229.134l.187.02c.649.148.747.315.793.96a6.028 6.028 0 01-1.083 3.967 4.659 4.659 0 01-3.444 1.625q-22.922.142-45.846 0a4.7 4.7 0 01-4.526-4.526l.009-1.206c.151-.693.31-.793.971-.841zm-51.973 1.8a2.72 2.72 0 002.59 2.651c15.277.095 30.557.292 45.831 0a2.691 2.691 0 002.556-2.627v-.024z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 512"
                                d="M261.773 118.176H96.164a8.219 8.219 0 018.255-8.119h149.136a8.263 8.263 0 017.06 3.936c.063.1.118.2.173.3a8.113 8.113 0 01.985 3.883z"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 513"
                                d="M253.633 108.93a9.464 9.464 0 017.815 4.433 9.064 9.064 0 011.311 4.725l-.073.377-.213.319-.318.214-.376.075H96.164l-.378-.076-.319-.215-.212-.321a9.992 9.992 0 01.206-2.558 9.438 9.438 0 018.839-6.977c49.775-.469 99.559-.201 149.333.004zm-149.213 2.118a7.437 7.437 0 00-7.191 6.157l-.01.069h163.508a7.406 7.406 0 00-7.11-6.226c-49.732-.204-99.465-.001-149.197-.001z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 514"
                                d="M131.194 113.99c.578.042.772 1.118 0 1.175-1.217.03-2.434 0-3.652 0-.585-.015-.829-1.117-.029-1.176q1.84-.044 3.681.001z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 515"
                                d="M124.139 113.966c.6.089.785 1.159-.059 1.2h-14.833c-.622-.03-.872-1.16 0-1.2h14.833z"
                                fill="#3b1b6a"
                            />
                            <path
                                d="M250.193 229.134H97.324v-24.057s12.261-13.437 21.82-13.437a24.385 24.385 0 0112.271 3.287 11.581 11.581 0 011.506-1.194c.083-.059.169-.118.256-.17.28-.182.567-.351.859-.5.028-.078.051-.157.079-.232a16.774 16.774 0 0123.774 4.224 10.144 10.144 0 0111.2-.324 16.781 16.781 0 0123.558-12.351c.059.024.113.049.167.075a10.146 10.146 0 00-8.254-7.261l4.884-37.669h-3.5l-5.385 37.86-.01.066-.033.3a10.879 10.879 0 00-2.657.932 10.982 10.982 0 012.656-.933l.04-.305.008-.063 5.384-37.56h25.177l6.74 36.527A13.766 13.766 0 01237.198 194a9.31 9.31 0 015.306 3.2 9.31 9.31 0 00-5.306-3.2 13.766 13.766 0 00-19.334-17.651l-6.74-36.524h-2.735l5.606 39.711a11.777 11.777 0 00-3.778 8.808s23.54-3.959 23.359 20.841c.002 0 17.302 3.081 16.617 19.949zm-102.062-44.321a12.184 12.184 0 015.692 2.84 12.2 12.2 0 00-5.692-2.84zm22.593-10.711l.035.008-.035-.008zm74.4 31.789h.033l.253.011-.253-.011h-.033zm1.061.075l.155.018c.181.02.362.043.544.067l.232.036q.1.012.189.028-.094-.016-.189-.028l-.232-.036c-.182-.023-.363-.047-.544-.067l-.155-.018zm2.015.317l.264.059zm1.995.549h.008c.165.057.327.115.49.175zm.922.34l.255.1.253.107-.253-.107q-.127-.053-.255-.1zm1.3.58l.122.06.1.051-.1-.051-.122-.06zm2.309 1.354c.173.122.343.244.512.37s.327.249.489.374a.185.185 0 00.032.024c.157.13.311.256.465.386.323.272.634.56.934.855a16.3 16.3 0 01.649.671 8.695 8.695 0 00-.208-.22s0-.008-.008-.012c-.146-.15-.288-.3-.433-.438-.3-.3-.612-.579-.934-.855-.154-.13-.308-.257-.465-.386a.2.2 0 01-.032-.023c-.162-.126-.324-.253-.489-.375s-.343-.252-.514-.372zm3.64 3.315l.074.087a.237.237 0 00.027.032.2.2 0 01-.027-.032l-.074-.087zm-44.628-7a12.041 12.041 0 00-4.339-9.27 12.078 12.078 0 01-18.376 14.994 12.079 12.079 0 0022.715-5.724zm-36.592 23.718c-9.71-14.3-1.8-29.849-1.8-29.849s-15.741 8.648-9.048 29.849zm-43.64-24.028a22.138 22.138 0 00-29.694 20.191 22.426 22.426 0 00.227 3.837h6.207a21.201 21.201 0 01-.087-1.944 22.117 22.117 0 0122.116-22.117c.409-.005.82.007 1.226.028z"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 516"
                                d="M260.904 229.134h-10.6c.686-16.867-16.615-19.948-16.615-19.948.181-24.8-23.359-20.841-23.359-20.841a11.777 11.777 0 013.777-8.808l-5.601-39.711h2.726l6.736 36.638a13.757 13.757 0 0119.321 17.639 9.326 9.326 0 017.015 11.865.892.892 0 01.1 0c.165 0 .331 0 .5.008.114 0 .224 0 .334.012l.252.011c.189.012.374.023.559.043.126.008.248.02.37.035.181.02.362.043.544.067l.232.036c.154.02.3.047.453.074.079.012.158.028.236.044.134.023.264.051.394.079l.154.035c.387.087.771.184 1.15.3a25.91 25.91 0 01.697.219l.571.2c.189.066.4.154.6.237s.414.177.622.271c.182.087.359.174.54.264s.35.177.524.272c.22.122.441.244.658.378.292.174.575.355.851.54.051.036.1.071.154.1.173.122.342.244.512.37s.327.249.489.374a.2.2 0 00.031.024c.157.13.311.256.465.385.323.272.634.559.934.855a16.3 16.3 0 01.441.45q.207.213.4.437c.016.016.035.035.051.055.13.146.26.3.386.449a.232.232 0 00.027.031c.122.154.248.307.367.465s.256.335.378.5l.3.422a18.95 18.95 0 011.052 1.76c.094.177.185.359.276.544zm-68.259-44.622a16.78 16.78 0 00-23.56 12.351 10.144 10.144 0 00-11.2.324 16.774 16.774 0 00-23.779-4.221 12.25 12.25 0 0119.708-5.172 11.735 11.735 0 011.209-.7c0-.141-.007-.284-.007-.427a12.764 12.764 0 0122.837-7.842 10.877 10.877 0 012.66-.933l.033-.3.01-.065 5.385-37.7h3.5l-4.885 37.499a10.147 10.147 0 018.254 7.261c-.054-.025-.108-.051-.165-.075zm21.087 20.9a12.079 12.079 0 01-22.715 5.724 12.079 12.079 0 0018.376-14.994 12.041 12.041 0 014.339 9.274zM177.14 229.13h-10.846c-6.693-21.2 9.048-29.849 9.048-29.849s-7.911 15.556 1.798 29.853zm-43.64-24.028a23.83 23.83 0 00-1.23-.033 22.117 22.117 0 00-22.116 22.117c0 .655.028 1.3.087 1.944h-6.207a22.419 22.419 0 01-.227-3.837 22.139 22.139 0 0129.694-20.191z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 517"
                                d="M260.904 229.134h-10.6c.686-16.867-16.615-19.948-16.615-19.948.181-24.8-23.359-20.841-23.359-20.841a11.777 11.777 0 013.777-8.808l-5.601-39.711h2.726l6.736 36.638a13.757 13.757 0 0119.321 17.639 9.326 9.326 0 017.015 11.865.892.892 0 01.1 0c.165 0 .331 0 .5.008.114 0 .224 0 .334.012l.252.011c.189.012.374.023.559.043.126.008.248.02.37.035.181.02.362.043.544.067l.232.036c.154.02.3.047.453.074.079.012.158.028.236.044.134.023.264.051.394.079l.154.035c.387.087.771.184 1.15.3a25.91 25.91 0 01.697.219l.571.2c.189.066.4.154.6.237s.414.177.622.271c.182.087.359.174.54.264s.35.177.524.272c.22.122.441.244.658.378.292.174.575.355.851.54.051.036.1.071.154.1.173.122.342.244.512.37s.327.249.489.374a.2.2 0 00.031.024c.157.13.311.256.465.385.323.272.634.559.934.855a16.3 16.3 0 01.441.45q.207.213.4.437c.016.016.035.035.051.055.13.146.26.3.386.449a.232.232 0 00.027.031c.122.154.248.307.367.465s.256.335.378.5l.3.422a18.95 18.95 0 011.052 1.76c.094.177.185.359.276.544zm-68.259-44.622a16.78 16.78 0 00-23.56 12.351 10.144 10.144 0 00-11.2.324 16.774 16.774 0 00-23.779-4.221 12.25 12.25 0 0119.708-5.172 11.735 11.735 0 011.209-.7c0-.141-.007-.284-.007-.427a12.764 12.764 0 0122.837-7.842 10.877 10.877 0 012.66-.933l.033-.3.01-.065 5.385-37.7h3.5l-4.885 37.499a10.147 10.147 0 018.254 7.261c-.054-.025-.108-.051-.165-.075zm21.087 20.9a12.079 12.079 0 01-22.715 5.724 12.079 12.079 0 0018.376-14.994 12.041 12.041 0 014.339 9.274zM177.14 229.13h-10.846c-6.693-21.2 9.048-29.849 9.048-29.849s-7.911 15.556 1.798 29.853zm-43.64-24.028a23.83 23.83 0 00-1.23-.033 22.117 22.117 0 00-22.116 22.117c0 .655.028 1.3.087 1.944h-6.207a22.419 22.419 0 01-.227-3.837 22.139 22.139 0 0129.694-20.191z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 518"
                                d="M260.904 229.134h-10.6c.686-16.867-16.615-19.948-16.615-19.948.181-24.8-23.359-20.841-23.359-20.841a11.777 11.777 0 013.777-8.808l-5.601-39.711h2.726l6.736 36.638a13.757 13.757 0 0119.321 17.639 9.326 9.326 0 017.015 11.865.892.892 0 01.1 0c.165 0 .331 0 .5.008.114 0 .224 0 .334.012l.252.011c.189.012.374.023.559.043.126.008.248.02.37.035.181.02.362.043.544.067l.232.036c.154.02.3.047.453.074.079.012.158.028.236.044.134.023.264.051.394.079l.154.035c.387.087.771.184 1.15.3.214.064.422.127.63.195.019.008.449.158.638.229s.4.154.6.237.414.177.622.271c.182.087.359.174.54.264s.35.177.524.272c.22.122.441.244.658.378.292.174.575.355.851.54.051.036.1.071.154.1.173.122.342.244.512.37s.327.249.489.374a.2.2 0 00.031.024c.157.13.311.256.465.385.323.272.634.559.934.855a16.3 16.3 0 01.441.45q.207.213.4.437c.016.016.035.035.051.055.13.146.26.3.386.449a.232.232 0 00.027.031c.122.154.248.307.367.465s.256.335.378.5l.3.422a18.95 18.95 0 011.052 1.76c.094.177.185.359.276.544zm-68.259-44.622a16.78 16.78 0 00-23.56 12.351 10.144 10.144 0 00-11.2.324 16.774 16.774 0 00-23.779-4.221 12.25 12.25 0 0119.708-5.172 11.735 11.735 0 011.209-.7c0-.141-.007-.284-.007-.427a12.764 12.764 0 0122.837-7.842 10.877 10.877 0 012.66-.933l.033-.3.01-.065 5.385-37.7h3.5l-4.885 37.499a10.147 10.147 0 018.254 7.261c-.054-.025-.108-.051-.165-.075zm21.087 20.9a12.079 12.079 0 01-22.715 5.724 12.079 12.079 0 0018.376-14.994 12.041 12.041 0 014.339 9.274zM177.14 229.13h-10.846c-6.693-21.2 9.048-29.849 9.048-29.849s-7.911 15.556 1.798 29.853zm-43.64-24.028a23.83 23.83 0 00-1.23-.033 22.117 22.117 0 00-22.116 22.117c0 .655.028 1.3.087 1.944h-6.207a22.419 22.419 0 01-.227-3.837 22.139 22.139 0 0129.694-20.191z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 519"
                                d="M235.879 145.487a.515.515 0 01.421.207l6.057 7.125c.21.264.157.346.131.488a.6.6 0 01-1.027.274l-6.057-7.125c-.648-.816.05-.975.475-.969z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 520"
                                d="M240.057 145.735c.619.034 1.268 1.169 1.933 1.979.363.475-.424 1.262-.9.762-.714-.811-1.657-1.686-1.647-2.165a.605.605 0 01.614-.576z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 521"
                                d="M234.647 148.704a.626.626 0 01.4.184c1.117 1.248 2.17 2.552 3.256 3.829.382.472-.362 1.3-.876.784-1.117-1.249-2.171-2.552-3.256-3.829a.592.592 0 01.476-.968z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 522"
                                d="M150.544 141.63h-41.048a3.452 3.452 0 01-3.452-3.452v-4.824a3.452 3.452 0 013.451-3.452h40.908a3.452 3.452 0 013.452 3.452v4.779a3.477 3.477 0 01-3.458 3.5"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 523"
                                d="M164.38 161.175h-55.277a3.059 3.059 0 01-3.059-3.059v-5.61a3.059 3.059 0 013.058-3.058h55.225a3.059 3.059 0 013.059 3.059v5.57a3.075 3.075 0 01-3.052 3.1"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 524"
                                d="M287.293 233.851c.446.046.74.406.4.988a14.578 14.578 0 01-4.572 4.78s-1.293.084-.842-.768c.154-.291.9-.685 1.415-1.109a13.045 13.045 0 003.042-3.6.612.612 0 01.557-.291z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 525"
                                d="M290.587 230.036a2.154 2.154 0 012.152 2.591 13.706 13.706 0 01-3.883 6.88 14.549 14.549 0 01-10.147 4.061H78.864a14.248 14.248 0 01-14.029-10.934 2.155 2.155 0 012.15-2.6z"
                                fill="#d5d4ea"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 526"
                                d="M290.633 229.133a3.25 3.25 0 013 4.039q-.088.363-.2.72a14.377 14.377 0 01-.914 2.307 15.667 15.667 0 01-13.716 8.367q-100.014.284-200.029 0c-6.719-.052-13.239-4.764-14.845-11.418a3.258 3.258 0 012.959-4.013h.046q111.846-.812 223.699-.002zm-223.645 1.894a1.253 1.253 0 00-1.133 1.66 13.511 13.511 0 0012.932 9.851q100 .268 200.007 0c5.931-.055 11.7-4.189 12.99-10.143a1.214 1.214 0 00-1.179-1.368c-74.537-.541-149.078 0-223.617 0z"
                                fill="#3b1b6a"
                            />
                        </g>
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 49">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 527"
                                d="M199.518 24.107c3.158.092 6.265 1.742 9.008 3.673a39.149 39.149 0 019.21 9.522.824.824 0 01.2.634.585.585 0 01-1.052.2c-3.946-5.576-8.93-10.932-15.672-12.58-3.27-.8-6.765.9-9.8 2.975a31.913 31.913 0 00-4.79 4.074s-.458.441-.853.154c-.66-.48.5-1.5 1.478-2.4 3.282-3.016 7.182-5.617 11.5-6.189a4.492 4.492 0 01.771-.063z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 528"
                                d="M183.787 34.358c.53.043.665.655.166 1.274-.644.805-1.193 1.709-1.845 2.442-.411.425-1.419-.026-.779-.926a11.4 11.4 0 012.077-2.678.5.5 0 01.381-.112z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 529"
                                d="M183.709 104.972c.445.054.429.183.5.378a.588.588 0 01-.55.8h-9.75c-.312-.013-.338-.091-.424-.181a.608.608 0 01.424-1c3.268.001 6.537-.128 9.8.003z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 530"
                                d="M223.533 90.622a71.056 71.056 0 01-6.437 27.991c-.224.493-.45.962-.67 1.411l-.288.579a54.063 54.063 0 01-2.744 4.84 2.518 2.518 0 01-2.116 1.151h-23.623a2.519 2.519 0 01-2.117-1.151 54.1 54.1 0 01-2.743-4.84l-.288-.579c-.221-.45-.445-.919-.67-1.411a71.085 71.085 0 01-6.437-28 75.679 75.679 0 013.086-23.04c9.058-31.1 20.983-31.077 20.983-31.077s11.916-.024 20.978 31.077a75.6 75.6 0 013.086 23.049"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 531"
                                d="M199.498 35.603c3.659.11 7.025 3.067 9.811 6.34 6.908 8.112 10.589 18.871 13.071 29.17a73.084 73.084 0 01-5.149 49.58 44.453 44.453 0 01-3.075 5.462 3.576 3.576 0 01-2.822 1.506q-11.867.094-23.735 0c-2.326-.055-3.658-2.72-4.957-5.142-8.708-16.234-10.372-36.012-5.433-53.947 2.624-9.529 6.128-19.17 12.307-26.5 2.416-2.866 5.4-5.536 8.921-6.325a4.163 4.163 0 011.061-.144zm-.049 1.963c-3.342.222-6.353 3.15-8.94 6.385-6.108 7.636-9.473 17.359-11.862 26.861-4.449 17.7-2.51 36.992 6.879 52.83.563.951 1.076 2.034 2.1 2.058 7.9.062 15.8.187 23.7 0 .785-.03 1.289-.754 1.773-1.547 10.189-16.7 11.759-38.222 6.193-56.935-2.665-8.96-5.977-18.109-12.269-24.893-2.083-2.247-4.6-4.563-7.537-4.758z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 532"
                                d="M181.836 118.699a51.4 51.4 0 01-8.005 4.964 3.263 3.263 0 01-4.343-1.555c-6.273-14.53 3.662-28.515 5.912-31.406a71.119 71.119 0 006.436 27.997z"
                                fill="#ff9490"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 533"
                                d="M175.491 89.726c1.343.2.919 2.574 1.063 4.509a70.093 70.093 0 006.039 23.762 1.21 1.21 0 01-.184 1.5 40.6 40.6 0 01-8.372 5.146 4.282 4.282 0 01-5.6-2.5c-4.359-10.512-.631-23.194 6.132-31.972a1.042 1.042 0 01.922-.445zm-.991 3.933a42.429 42.429 0 00-2.729 4.842c-3.384 7.074-4.634 15.46-1.487 22.973a2.525 2.525 0 003.61 1.072 50.285 50.285 0 006.681-4.151s-1.131-2.694-1.912-4.864a72.8 72.8 0 01-4.12-19.938z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 534"
                                d="M229.44 122.108a3.259 3.259 0 01-4.34 1.555 51.511 51.511 0 01-8.005-4.964 71.059 71.059 0 006.437-27.991c2.264 2.905 12.175 16.879 5.908 31.4z"
                                fill="#ff9490"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 535"
                                d="M223.658 89.736a1.6 1.6 0 01.889.684c6.785 8.984 10.637 20.8 5.792 32.078a3.019 3.019 0 01-.218.431 4.447 4.447 0 01-5.687 1.5 52.76 52.76 0 01-7.735-4.811s-.788-.59-.5-1.334a70.331 70.331 0 006.348-27.605s.246-1.007 1.111-.943zm.714 4.1a72.5 72.5 0 01-6.043 24.518l-.008.019a46.517 46.517 0 007.186 4.4 2.385 2.385 0 003.17-1.378c3.7-8.956.928-19.786-4.243-27.721.001-.003-.026-.339-.061.159z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 536"
                                d="M208.769 126.595c8.862 17.716-12.439 24.833-12.439 24.833-.088-.952-1.157-2.056-6.618-7.513-7.474-7.469.227-17.321.227-17.321z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 537"
                                d="M208.86 125.693c.64.09.716.389.945.86 2.314 4.812 2.947 10.459.329 15.3-2.812 5.2-8.229 8.756-13.471 10.572 0 0-1.114.056-1.319-.887-.188-.8-1.079-1.513-1.872-2.348-1.454-1.529-2.959-3.013-4.454-4.508l-.183-.187a11.837 11.837 0 01-3.354-7.606 17.144 17.144 0 013.657-10.786 1.093 1.093 0 01.8-.415h18.83zm-18.42 2.1c-3.174 4.558-4.634 10.923-.115 15.565 1.926 1.942 3.936 3.807 5.707 5.87.395.459.864 1.153.864 1.153 6.241-2.549 12.184-6.86 12.972-13.928a16.381 16.381 0 00-1.688-8.608l-.025-.053z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 538"
                                d="M203.247 126.595c3.024 9.687-6.627 11.863-6.627 11.863-.039-.423.24-.877-1.831-4.176-2.553-4.068.895-7.687.895-7.687z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 539"
                                d="M203.247 126.595c3.024 9.687-6.627 11.863-6.627 11.863-.039-.423.24-.877-1.831-4.176-2.553-4.068.895-7.687.895-7.687z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 540"
                                d="M203.247 126.595c3.024 9.687-6.627 11.863-6.627 11.863-.039-.423.24-.877-1.831-4.176-2.553-4.068.895-7.687.895-7.687z"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 541"
                                d="M203.313 125.693a1.028 1.028 0 01.872.69l.072.236c1.069 3.668.366 7.846-2.547 10.359a11.764 11.764 0 01-4.787 2.492c-.669.169-1.241-.335-1.289-1.213-.109-1.571-1.669-3.036-2.284-4.571a7.582 7.582 0 011.605-7.669 1.155 1.155 0 01.731-.326h7.562zm-7.169 2.1a5.643 5.643 0 00-.332 6.493q.133.216.263.432a14.252 14.252 0 011.322 2.637 9.6 9.6 0 001.023-.4 7.638 7.638 0 004.413-6.027 9.877 9.877 0 00-.316-3.1l-.009-.038z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 542"
                                d="M210.173 69.464a10.707 10.707 0 11-10.707-10.707 10.707 10.707 0 0110.707 10.707"
                                fill="#5dbbff"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 543"
                                d="M199.541 57.774a11.649 11.649 0 11-.226 0zm-.139 1.961a9.727 9.727 0 108.83 13.94c2.764-5.733-1.51-13.783-8.578-13.939l-.252-.001z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 544"
                                d="M201.116 59.88l-10.745 13a8.877 8.877 0 01-.538-2.135l9.091-10.984a11.145 11.145 0 012.192.123"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 545"
                                d="M203.528 60.629l-11.983 14.47c-.148-.186-.493-.781-.493-.781l11.627-14.037s.368.139.493.19.356.158.356.158"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 546"
                                d="M201.116 59.88l-10.745 13a8.877 8.877 0 01-.538-2.135l9.091-10.984a11.145 11.145 0 012.192.123"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 547"
                                d="M203.528 60.629l-11.983 14.47c-.148-.186-.493-.781-.493-.781l11.627-14.037s.368.139.493.19.356.158.356.158"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 548"
                                d="M216.201 120.581a54.4 54.4 0 01-2.754 4.858 2.527 2.527 0 01-2.125 1.156H187.61a2.528 2.528 0 01-2.125-1.156 54.447 54.447 0 01-2.754-4.858z"
                                fill="#d5d4ea"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 549"
                                d="M216.248 119.685c.745.128 1.028.9.554 1.83a42.83 42.83 0 01-2.647 4.61 3.577 3.577 0 01-2.822 1.506q-11.867.092-23.734 0c-2.632-.062-4.1-3.424-5.574-6.321a1.35 1.35 0 01-.16-.963 1.032 1.032 0 01.929-.669h33.344zm-31.838 2.094q.678 1.259 1.42 2.483.17.279.345.556a1.857 1.857 0 001.428.99q11.858.281 23.722 0c.833-.033 1.347-.841 1.858-1.687q.665-1.1 1.276-2.232l.06-.111z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 550"
                                d="M206.426 104.178c0 9.647-3.092 19.789-6.905 19.789s-6.905-10.146-6.905-19.789 2.947-15.262 6.76-15.262 7.051 5.616 7.051 15.262"
                                fill="#ff9490"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 551"
                                d="M199.431 87.936c3.028.052 5.252 3.15 6.24 5.593 3.759 9.287 2.157 21.325-2.055 28.357q-.194.325-.41.637-.165.239-.345.467c-.99 1.256-2.548 2.351-4.294 1.811-2.108-.652-3.232-2.839-4.043-4.67-4.257-9.621-4.706-22.436.165-29.4a5.982 5.982 0 014.742-2.795zm-.09 1.961c-1.913.033-3.224 2-3.914 3.5-.146.319-.279.644-.4.973-2.96 8-1.777 17.948 1.26 24.893.749 1.712 1.933 3.72 3.233 3.72 1.667 0 2.477-1.989 3.234-3.72 4.143-9.473 4.688-22.525-.828-28.144a3.674 3.674 0 00-2.584-1.222z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 552"
                                d="M204.24 54.746a1.784 1.784 0 01.356.088 14.123 14.123 0 018.634 7.075.737.737 0 01-.054.624c-.276.364-.81.254-1.111-.313a12.918 12.918 0 00-8.035-6.32s-.452-.193-.439-.587.578-.572.649-.567z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 553"
                                d="M211.589 119.679h4s3.321-7.716 3.657-9.326a74.2 74.2 0 002.952-14.727 62.762 62.762 0 00.2-11.641c-.906-10.1-2.65-15.13-2.65-15.13a106.8 106.8 0 00-4.663-13.318 44.194 44.194 0 00-7.459-12.513 19.042 19.042 0 00-3.108-3.02 90.762 90.762 0 0110.4 20.967c3.69 11.339 4.883 22.271 4.227 29.32-2.049 22.007-7.422 29.387-7.422 29.387"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 554"
                                d="M210.988 121.483h3.593l-1.881 3.358a1.752 1.752 0 01-1.5.852h-2.854a28.5 28.5 0 002.566-4.21"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 555"
                                d="M210.988 121.483h3.593l-1.881 3.358a1.752 1.752 0 01-1.5.852h-2.854a28.5 28.5 0 002.566-4.21"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 556"
                                d="M206.272 138.51c.919.092-.014 1.7-1.053 2.891a21.819 21.819 0 01-2.515 2.434.725.725 0 01-.53.115c-.5-.135-.526-.756.106-1.3a18.673 18.673 0 003.456-3.88.619.619 0 01.536-.26z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 557"
                                d="M223.85 121.959a8.274 8.274 0 002.466 1.007 2.663 2.663 0 001.862-.679 8 8 0 00.78-1.6 27.27 27.27 0 001.409-5.75 29.757 29.757 0 00-.2-7.146 35.479 35.479 0 00-2.088-7.145 50.8 50.8 0 00-3.648-6.995 42.754 42.754 0 013.774 13.964c.3 4.79-.339 10.224-1.031 11.825-1.484 3.435-3.322 2.516-3.322 2.516"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 558"
                                d="M199.769 122.99c.631.07 2.239-.8 3.53-4.343a36.261 36.261 0 002.08-9.18c.334-3 .843-10.064-1.06-14.692-1.834-4.458-3.88-4.736-3.88-4.736a25.7 25.7 0 012.586 9.247c.309 4.946-.146 13.47-1.284 17.691a41.754 41.754 0 01-1.972 6.012"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 559"
                                d="M174.639 93.654a59.812 59.812 0 001.085 9.361 92.435 92.435 0 002.537 9.413c.668 2 2.337 5.942 2.337 5.942l-2.551 1.714s-5.795-9.659-3.408-26.43"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 560"
                                d="M201.78 95.655c.493.037.612.65.717 1.115a13.7 13.7 0 01.307 3.1s-.075.44-.393.552c-1.039.365-.752-2.438-1.225-4.008.003-.002-.059-.776.594-.759z"
                                fill="#3b1b6a"
                            />
                        </g>
                    </g>
                    <g>
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 561"
                            d="M335.478 243.566l19.245-.005"
                            fill="#ebe9f2"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 562"
                            d="M354.679 242.362a.862.862 0 01.836.69 1.037 1.037 0 01-.921 1.417l-19.192.006a.848.848 0 01-.85-.527 1.055 1.055 0 01.849-1.584l19.193-.006z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 563"
                            d="M24.855 243.567l301.9-.082"
                            fill="#ebe9f2"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 564"
                            d="M327.087 242.656l.321.205.212.305.075.36-.075.36-.213.305-.318.2-.375.072-301.782.078a1.322 1.322 0 01-.694-.275l-.213-.305-.075-.36s-.293-.7.98-.942l301.916-.078z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 565"
                            d="M1.1 243.567h15.035"
                            fill="#ebe9f2"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 566"
                            d="M16.357 242.664c.506.064.537.2.669.366a1.1 1.1 0 01-.745 1.74H.981c-.892-.037-.88-.293-.969-.889a1.07 1.07 0 01.968-1.22h15.3z"
                            fill="#3b1b6a"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default FriendlyIconSvg
