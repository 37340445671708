import * as React from "react"

function MeetBgrSvg(props) {
    return (
        <svg
            viewBox="0 0 540 490"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path
                d="M150.576 431.188c106.96 86.586 252.179 66.969 334.932-35.256C568.261 293.708 554.878 124.775 450.252 61 345.625-2.774 191.544-27.03 115.32 96.256c-76.224 123.286-71.703 248.346 35.256 334.932zM22.02 112.843c22.278 18.035 52.533 13.94 69.778-7.362 17.245-21.302 14.464-56.497-7.33-69.779-21.796-13.281-53.893-18.329-69.778 7.361-15.885 25.69-14.948 51.745 7.33 69.78z"
                fill="#e6e8f5"
                fillRule="nonzero"
            />
        </svg>
    )
}

export default MeetBgrSvg
