import { Button, withStyles } from '@material-ui/core';

const ButtonComponent = withStyles((theme) => ({
    label: {
        fontWeight: theme.typography.button.fontWeight,
        fontSize: theme.typography.button.fontSize,
    },
    contained: {
        padding: '15px 40px',
        boxShadow: 'none',
        color: theme.palette.text.white,
        borderRadius: 0,
        '&:hover': {
            boxShadow: 'none',
        }
    }
}))(Button);


export default ButtonComponent;