import React, { useCallback, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ImgComponent from './ImgComponent';
import DialogGalleryComponent from './DialogGalleryComponent';

const useStyles = makeStyles((theme) => ({
    item: {
        padding: theme.spacing(4),
        cursor: 'pointer',
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    }
}))

const GalleryListComponent = ({ gallery = [], md = 3, }) => {
    const classes = useStyles();
    const [activeImg, setActiveImg] = useState(0);
    const [showGallery, setShowGallery] = useState(false);

    const handleShowImg = useCallback((index) => {
        setActiveImg(index)
        setShowGallery(true)
    }, [])
    return (
        <>
            <Grid container alignItems='center' className={classes.imgCont}>
                {gallery?.length
                    ? gallery.map((img, key) =>
                        <Grid key={key} item md={md} sm={6} xs={6} className={classes.item} onClick={() => handleShowImg(key)}>
                            <ImgComponent
                                src={img.src}
                                alt={img.name}
                            // heightPer='60%'
                            />
                        </Grid>
                    )
                    : null
                }
            </Grid>
            <DialogGalleryComponent
                open={showGallery}
                showImgIndex={activeImg}
                setOpen={setShowGallery}
                imgs={gallery}
            />
        </>
    )
}


GalleryListComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(GalleryListComponent);