import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { classNames} from '../functions'

const useStyles = (heightPer) =>  makeStyles((theme) => ({
    imgRelativeCont: {
        position: 'relative',
        width: '100%',
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: heightPer,
        },
        '& span': {
            width: '100%',
            height: '100%',
        }
    },
    imgCont: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}))

const ImgComponent = ({ alt, src, heightPer = '100%', className = '' }) => {
    const classes = useStyles(heightPer)();

    return (
        <Box className={classes.imgRelativeCont}>
            <Box className={classes.imgCont}>
                <LazyLoadImage
                    effect="blur"
                    className={classNames([classes.img, className])}
                    alt={alt}
                    src={src}
                />
            </Box>
        </Box>
    )
}


ImgComponent.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
}
export default React.memo(ImgComponent);