import React from 'react'
import NewsPostComponent from '../../components/NewsPostComponent';
import { useSelector } from 'react-redux';
import { FETCH_NEWS_POST_DATA_REDUCER } from '../../reducers/NewsPostReducer';

const NewsPostPage = (props) => {
    const { data, loading} = useSelector(state => state.NewsPostReducer)
    return (
        <NewsPostComponent
            desc={data?.desc}
            title={data?.title}
            src={data?.src}
            loading={loading}
            fetchFun={FETCH_NEWS_POST_DATA_REDUCER}
            mobileSrc={data?.src_mobile}
            breadcrumbsTitle={data?.subtitle}
            date={data?.date}
            gallery={data?.gallery}
        />
    )
}

export default React.memo(NewsPostPage);