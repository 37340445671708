import React from 'react'
import { useTranslation } from 'react-i18next';
import PageContainerComponent from '../../components/PageContainerComponent';


const InformDisclPage = (props) => {

	const { t } = useTranslation();

	return (
		<PageContainerComponent
			title={t('inform_disc_page.title')}
			intro={t('inform_disc_page.text')}
		>
		</PageContainerComponent>
	)
}

export default React.memo(InformDisclPage);