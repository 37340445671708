import React from 'react'
import { Box, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SQUARE_CSS } from '../../constants/csses';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: 'none',
        ...SQUARE_CSS,
    },
    skeleton: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    box: {
        padding: 5,
        flexBasis: '50%',
        minWidth: '50%',
        [theme.breakpoints.down("sm")]: {
            minWidth: '100%',
            flexBasis: '100%',
        },
    }
}))

const NewsCardSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <Paper className={classes.paper}>
                <Skeleton
                    className={classes.skeleton}
                    variant="rect"
                    width={'100%'}
                    height={'100%'}
                />
            </Paper>
        </Box>
    )
}

export default React.memo(NewsCardSkeletonComponent);