import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import SectionsComponent from './SectionsComponent';
import { ARTICLE_CSS } from '../constants/csses';

const useStyles = makeStyles((theme) => ({
    desc: {
        ...ARTICLE_CSS(theme)
    },
    cont: {
        marginBottom: 160,
        position: 'relative',
        zIndex: 2,
    }
}))

const CustomContainerComponent = ({ title, desc, children}) => {
 const classes = useStyles();

    return (
        <MainContainerComponent title={title} showAnim>
            <Container className={classes.cont}>
                <Typography variant='h1'>{title}</Typography>
                <Typography
                    className={classes.desc}
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
               {children}
            </Container>
            <SectionsComponent />
        </MainContainerComponent>
    )
}


CustomContainerComponent.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
}
export default React.memo(CustomContainerComponent);