import * as React from "react"

function SectionBgrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={3500}
            height={1090.645}
            viewBox="0 0 3500 1090.645"
            {...props}
        >
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 772"
                d="M0 68.645L978.846 0 3500 68.645v1022H0z"
                fill="#f9f9f9"
            />
        </svg>
    )
}

export default SectionBgrSvg
