import * as React from "react"

function HeaderBgrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={549.669}
            height={600.381}
            viewBox="0 0 549.669 600.381"
            {...props}
        >
            <path
                d="M391.952 301.732c22.555 30.725 59.36 49.678 94.354 32.604a70.59 70.59 0 0032.37-94.437c-17.117-35.08-60.275-42.351-94.355-32.604s-54.923 63.71-32.37 94.437z"
                fill="#eff3f0"
            />
            <path
                data-name="Shape"
                d="M368.658 351.079c23.463 48.088.144 98.187-44.574 129.55S219 531.904 172.876 554.41s-104.577 6.574-129.544-44.595-4.154-112.136 44.574-129.55 98.816-54.577 151.208-73.78 106.08-3.494 129.544 44.595z"
                fill="#eff3f0"
            />
            <path
                data-name="Shape"
                d="M60.716 442.742a23.05 23.05 0 0031.326 10.772c11.63-5.675 17.462-21.289 10.787-31.32s-19.403-18.596-31.325-10.773-16.723 19.16-10.788 31.321zM116.562 434.23c-4.247-.333-7.387 2.921-7.832 7.545a6.945 6.945 0 007.565 7.812c4.264-.568 7.44-3.147 7.834-7.544s-3.319-7.48-7.567-7.813z"
                fill="#e0436b"
            />
            <path
                data-name="Shape"
                d="M208.644 247.074a6.88 6.88 0 009.354 3.215c3.472-1.694 5.213-6.356 3.224-9.352s-5.792-5.55-9.354-3.213-4.996 5.719-3.224 9.35z"
                fill="#7252a4"
            />
            <path
                data-name="Shape"
                d="M354.144 382.887c1.63 3.933 5.952 5.25 10.272 3.553a6.939 6.939 0 003.52-10.282c-2.44-3.539-6.177-5.194-10.27-3.551s-5.153 6.347-3.522 10.28z"
                fill="#f0c933"
            />
            <path
                data-name="Shape"
                d="M71.787 208.796c1.63 3.933 5.952 5.25 10.272 3.553a6.939 6.939 0 003.52-10.283c-2.44-3.538-6.178-5.194-10.27-3.55s-5.153 6.347-3.522 10.28z"
                fill="#e0436b"
            />
            <path
                data-name="Shape"
                d="M263.07 346.182c-3.51 2.413-3.9 6.915-1.342 10.79a6.941 6.941 0 0010.793 1.307c2.955-3.122 3.798-7.124 1.34-10.787s-7.282-3.723-10.791-1.31z"
                fill="#453264"
            />
            <path
                data-name="Shape"
                d="M473.751 332.545c3.678 5.008 9.686 8.095 15.403 5.305a11.52 11.52 0 005.296-15.405c-2.79-5.718-9.836-6.899-15.402-5.306s-8.976 10.398-5.297 15.406zM442.083 263.045c2.23 3.035 5.872 4.905 9.335 3.215a6.98 6.98 0 003.212-9.337c-1.69-3.464-5.961-4.18-9.335-3.214s-5.44 6.3-3.212 9.336z"
                fill="#eff3f0"
            />
            <path
                data-name="Shape"
                d="M445.522 86.495c23.308 47.77-.347 107.257-52.835 132.868s-116.933 50.146-165.078 80.546-120.297 10.733-137.242-40.124-5.697-104.309 52.835-132.868L308.28 46.371c57.515-28.063 113.932-7.649 137.242 40.124z"
                fill="#dcd7f5"
            />
        </svg>
    )
}

export default HeaderBgrSvg
