import * as React from "react"

function MobileLogoSvg(props) {
    return (
        <svg
            viewBox="0 0 113 49"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path fill="#00256f" d="M37.483-3.555H113v55.922H37.483z" />
            <path fill="#d9000d" d="M-.481-.683H44.49v50.668H-.481z" />
            <path
                d="M21.962 41.968c-1.4.157-2.95-1.29-3.064-3.01a4.923 4.923 0 011.428-3.79c1.094-1.145 2.259-2.193 3.442-3.242a30.941 30.941 0 004.821-4.647 6.254 6.254 0 001.385-3.777 4.506 4.506 0 00-2.54-3.995 1.225 1.225 0 00-1.82.637 2.13 2.13 0 01-2.714 1.162c-1.81-.372-3.6-.817-5.434-1.088a10.679 10.679 0 00-3.276-.02 3.133 3.133 0 00-2.907 2.783 5.992 5.992 0 01-.557-.614 3.394 3.394 0 011.04-4.99 6.044 6.044 0 013.093-.866c1.554-.058 3.118-.034 4.674-.02a.757.757 0 01.557.282c.4.774.854 1.05 1.681.8.504-.208.997-.444 1.475-.706.365-.184.758-.31 1.162-.373 1.108-.064 2.232.035 3.322-.096a7.247 7.247 0 004.319-2.541c.535-.566 1.024-1.184 1.652-1.733-.131.732-.27 1.459-.4 2.19-.168.815-.344 1.633-.505 2.439a1.598 1.598 0 01-1.736 1.44c-.791.024-1.59.017-2.384.024a.158.158 0 01-.047.07c.331.18.668.344 1 .525 3.838 2.05 4.907 5.875 2.773 9.636a25.415 25.415 0 01-4.538 5.354 806.3 806.3 0 00-5.087 5.046 5.772 5.772 0 00-.6.825 1.87 1.87 0 00-.215 2.294z"
                fill="#fff"
            />
            <path
                d="M13.168 10.591a2.749 2.749 0 012.832.777c.528.594 1.013 1.238 1.559 1.788.349.39.784.693 1.271.886.138.063.288.094.439.094a1.083 1.083 0 001.029-1.4c-.546-1.68.646-2.372 1.6-3.342.49.5.9.928 1.33 1.383.386.414.505 1.012.309 1.543a1.263 1.263 0 00.378 1.6c.574.325 1.3.221 1.76-.252.7-.685 1.323-1.44 1.958-2.193a2.795 2.795 0 012.923-.88c-.166.287-.29.511-.437.731a196.8 196.8 0 01-2.062 3.235 2.414 2.414 0 01-2.127 1.087c-2.7.007-5.418.013-8.114 0a2.393 2.393 0 01-2.085-1.063c-.841-1.288-1.668-2.6-2.563-3.994zM16.381 22.556c1.609.108 3.193.19 4.765.33a3.225 3.225 0 013.05 2.625c.03.193.044.386.044.58a3.832 3.832 0 01-3.814 3.815 3.812 3.812 0 01-1.73-.415 8.903 8.903 0 01-.78-.424c.512-.107.9-.164 1.278-.266a2.332 2.332 0 001.869-2.146 2.206 2.206 0 00-1.575-2.235c-.564-.236-1.151-.416-1.72-.665a2.313 2.313 0 01-1.387-1.2z"
                fill="#fff"
            />
            <g>
                <path
                    d="M82.5 15.267a2.944 2.944 0 01-3.148 2.918h-.088a2.953 2.953 0 01-2.939-2.938 2.953 2.953 0 013.065-2.936 2.94 2.94 0 013.11 2.92v.036zm-6.027 24V20.488h5.835v18.776l-5.835.003z"
                    fill="#fff"
                    fillRule="nonzero"
                />
            </g>
            <g>
                <path
                    d="M68.53 27.118a3.443 3.443 0 00-3.788-3.209c-3.282 0-4.364 3.32-4.364 6.06 0 2.708 1.042 5.877 4.26 5.877 2.413 0 3.75-1.473 4.07-3.782h4.945c-.685 4.943-4.044 7.643-8.981 7.643-5.63 0-9.414-3.965-9.414-9.562 0-5.8 3.464-10.1 9.522-10.1 4.4 0 8.474 2.31 8.769 7.071l-5.019.002z"
                    fill="#fff"
                    fillRule="nonzero"
                />
            </g>
            <g>
                <path
                    d="M87.632 41.263c0-.733-.02-1.212-.041-1.643h1.338l.051.92h.04a1.47 1.47 0 011.348-1.033 1.63 1.63 0 01.327.022v1.459a2.26 2.26 0 00-.418-.042 1.003 1.003 0 00-1.063.786 1.835 1.835 0 00-.03.347v2.528h-1.552v-3.344z"
                    fill="#87a4d4"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default MobileLogoSvg
