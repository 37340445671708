import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
    space: {
        marginBottom: 20,
        width: '100%',
        height: 200,
        [theme.breakpoints.down("md")]: {
            height: 350,
        },
    },
    btns: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            display: 'block'
        },
    },
    btn: {
        width: 'calc(50% - 10px)',
        height: 60,
        margin: 5,
        [theme.breakpoints.down("md")]: {
            width: '100%',
        },
    },
}))

const SelectedNewsCardSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Box>
            <Skeleton
                variant="rect"
                width={'50%'}
                height={'15px'}
                className={classes.space}
            />
            <Skeleton
                variant="rect"
                width={'100%'}
                height={'30px'}
                className={classes.space}
            />
            <Skeleton
                variant="rect"

                className={classes.space}
            />
            <Box className={classes.btns}>
                <Skeleton
                    variant="rect"
                    className={classes.btn}
                />
                <Skeleton
                    variant="rect"
                    className={classes.btn}
                />
            </Box>
        </Box>
    )
}

export default React.memo(SelectedNewsCardSkeletonComponent);