import * as React from "react"

function EventsBgrSvg(props) {
    return (
        <svg
            viewBox="0 0 455 455"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <g opacity={0.22}>
                <path
                    d="M85.6 387.685c89.879 82.287 217.897 64.202 309.358-32.12 91.46-96.321 73.094-247.005-32.998-309.267C255.868-15.963 138.893-13.729 52.601 78.42-33.69 170.569-4.28 305.4 85.6 387.685zM369.36 446.225c11.17 10.228 27.084 7.981 38.45-3.992 11.368-11.973 9.087-30.703-4.1-38.442-13.186-7.739-27.727-7.46-38.453 3.992-10.725 11.453-7.07 28.214 4.102 38.442zM411.687 378.178c5.585 5.114 13.541 3.99 19.226-1.997 5.684-5.986 4.538-15.354-2.048-19.223-6.586-3.87-13.87-3.73-19.233 1.996-5.363 5.727-3.535 14.107 2.055 19.224z"
                    fill="#f0c933"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}

export default EventsBgrSvg
