import React, { useMemo } from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import { useHistory } from 'react-router';
import BtnSvg from '../svgs/BtnSvg';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
    item: {
        zIndex: 3,
        position: 'relative',
        textTransform: 'uppercase',
        transition: 'all 0.2s ease-in',
        marginRight: 40,
        marginLeft: 40,
        fontWeight: 900,
        marginTop: 10,
        marginBottom: 10,
    },
    link: {
        position: 'relative',
        '& .btn-background': {
            opacity: 0,
        },
        '& .show-background': {
            opacity: 1,
        },
        // width: '100%',
        '&:hover': {
            '& .btn-background': {
                opacity: 1,
            },
            '& .item-menu': {
                color: theme.palette.primary.main,
                [theme.breakpoints.down("md")]: {
                    '&:hover': {
                        color: theme.palette.text.primary,
                    }
                },
            },
            '& path': {
                fill: theme.palette.text.primary,
            }
        }
    },
    btnIcon: {
        zIndex: 2,
        top: -20,
        left: 0,
        right: 0,
        margin: 'auto',
        height: 80,
        position: 'absolute',
        transition: 'all 0.2s ease-in',
        '& path': {
            transition: 'all 0.2s ease-in',
        },
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    }
}))

const MenuItemComponent = ({ to, title, external_link, onClick = () => {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const active = useMemo(() => history?.location?.pathname === to, [history, to])
    return (
        <LinkComponent to={to} className={classes.link} external_link={external_link} onClick={onClick}>
            <Typography
                className={classNames([classes.item, 'item-menu'])}
                color={'textPrimary'}
            >
                {title}
            </Typography>
            <BtnSvg className={classNames([classes.btnIcon, 'btn-background', active ? 'show-background' : ''])} />
        </LinkComponent>
    )
}


MenuItemComponent.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    external_link: PropTypes.bool,
}
export default React.memo(MenuItemComponent);