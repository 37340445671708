import * as React from "react"

function OpenedIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40.603}
            height={60.603}
            viewBox="0 0 40.603 60.603"
            {...props}
        >
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 133"
                fill="none"
                stroke="#444544"
                strokeLinecap="round"
                strokeWidth={3}
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1311"
                    d="M3 20.469l34.6-.332"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1313"
                    d="M6.999 30.208l25.879.008"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1314"
                    d="M2.999 40.469l34.6-.332"
                />
            </g>
        </svg>
    )
}

export default OpenedIconSvg
