import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PARTNERS_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_PARTNERS_DATA_REDUCER = createAsyncThunk(
    'PartnersReducer/fetchPartnersData',
    async () => {
        const response = await GET(PARTNERS_API);
        return response.body
    }
)

const initialState = {
    data: [
        {
            id: 1,
            name: 'Zut',
            src: 'https://s32500.pcdn.co/wp-content/uploads/google-logo.png',
            to: 'https://google.com'
        },
        {
            id: 1,
            name: 'Zut',
            src: 'https://bp.zut.edu.pl/fileadmin/pliki/bp/2014/logotypy/centralne%203wersowe.jpg',
            to: 'https://google.com'
        },
        {
            id: 1,
            name: 'Zut',
            src: 'https://s32500.pcdn.co/wp-content/uploads/google-logo.png',
            to: 'https://google.com'
        },
        {
            id: 1,
            name: 'Zut',
            src: 'https://bp.zut.edu.pl/fileadmin/pliki/bp/2014/logotypy/centralne%203wersowe.jpg',
            to: 'https://google.com'
        },
    ],
    loading: false,
    error: {},
};

export const PartnersReducer = createSlice({
    name: 'PartnersReducer',
    initialState,
    reducers: {
        RESET_NEWS_POST_REDUCER: (state) => {
            state.error = {}
        }
    },
    extraReducers: {
        [FETCH_PARTNERS_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_PARTNERS_DATA_REDUCER.fulfilled]: (state, { payload }) => {
            state.data = payload.data
            state.loading = false;
        },
        [FETCH_PARTNERS_DATA_REDUCER.rejected]: (state, { error }) => {
            state.data = {};
            state.loading = false;
            state.error = error;
        }
    }
})

export const { RESET_NEWS_POST_REDUCER } = PartnersReducer.actions

export default PartnersReducer.reducer