import React, { useCallback, useState } from 'react'
import { Box, Container, Drawer, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuItemComponent from './MenuItemComponent';
import { useSelector } from 'react-redux';
import ClosedIconSvg from '../svgs/ClosedIconSvg';
import OpenedIconSvg from '../svgs/OpenedIconSvg';
import LogoSvg from '../svgs/LogoSvg';
import MobileLogoSvg from '../svgs/MobileLogoSvg';
import LinkComponent from './LinkComponent';
import { MAIN_ROUTE } from '../constants/routes';
import FacebookComponent from './FacebookComponent';

const useStyles = makeStyles((theme) => ({
    headerCont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 40,
        margin: 'auto',
        left: 0,
        right: 0,
        paddingBottom: 40,
        position: 'absolute',
        top: 0,
        zIndex: 100,
        width: '100%',
        [theme.breakpoints.down("md")]: {
            paddingTop: 20,
            paddingBottom: 20,
            background: theme.palette.background.white,
            boxShadow: theme.shadows[1],
            // padding: '20px 24px',
            position: 'sticky',
            top: 0,
        },
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    },
    icon: {
        width: 65,
        height: 65,
    },
    paper: {
        paddingTop: 20,
        background: theme.palette.primary.main,
        minWidth: '80%',
        '& .item-menu': {
            [theme.breakpoints.down("md")]: {
                textAlign: 'right',
                lineHeight: 5,
            },
        }
    },
    openedIconCont: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 20,
        marginLeft: 20,
    },
    mobileLogo: {
        width: 100,
    },
    facebookCont: {
        '& path': {
            stroke: theme.palette.text.primary,
            transition: 'all 0.2s ease-in',
        },
        '& .facebook-letter': {
            fill: theme.palette.text.primary,
            transition: 'all 0.2s ease-in',
        },
        '&:hover': {
            '& path': {
                stroke: theme.palette.primary.main,
            },
            '& .facebook-letter': {
                fill: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down("md")]: {
            marginRight: theme.spacing(1),
        },
    },
    mobileBtnsBox: {
        display: 'flex',
        alignItems: 'center',
    }
}))



const HeaderComponent = (props) => {
    const classes = useStyles();
    const { data } = useSelector(state => state.MainReducer);
    const [openMobile, setOpenMobile] = useState(false)
    const theme = useTheme();
    const matchMd = useMediaQuery(theme.breakpoints.down('md'));

    const toggleDrawer = useCallback((event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenMobile(!openMobile)
    }, [openMobile])
    return (
        <>
            <Container className={classes.headerCont} component='header'>
                <LinkComponent to={MAIN_ROUTE}>
                    {
                        matchMd
                            ? <MobileLogoSvg
                                className={classes.mobileLogo}
                            />
                            : <LogoSvg
                            />

                    }

                </LinkComponent>
                {
                    matchMd
                        ? <Box className={classes.mobileBtnsBox}>
                            <FacebookComponent
                                className={classes.facebookCont}
                            />
                            <IconButton
                                size='small'
                                onClick={toggleDrawer}
                            >
                                <ClosedIconSvg
                                    className={classes.icon}
                                />
                            </IconButton>
                        </Box>
                        : <Box className={classes.menu}>
                            {data?.menu?.length
                                ? data.menu.map(({ title, link, external_link }, key) =>
                                    <MenuItemComponent
                                        to={link}
                                        key={key}
                                        external_link={external_link}
                                        title={title}
                                    />
                                )
                                : null
                            }
                            <FacebookComponent
                                className={classes.facebookCont}
                            />
                        </Box>
                }
            </Container>
            {matchMd ? <Drawer
                anchor={'right'}
                open={openMobile}
                onClose={toggleDrawer}
                classes={{
                    paper: classes.paper
                }}
            >
                <Box
                    className={classes.openedIconCont}
                >
                    <IconButton
                        size='small'
                        onClick={toggleDrawer}
                    >
                        <OpenedIconSvg
                            className={classes.icon}
                        />
                    </IconButton>
                </Box>
                {data?.menu?.length
                    ? data.menu.map(({ title, link, external_link }, key) =>
                        <MenuItemComponent
                            to={link}
                            key={key}
                            onClick={toggleDrawer}
                            external_link={external_link}
                            title={title}
                        />
                    )
                    : null
                }
            </Drawer>
                : null
            }
        </>
    )
}


HeaderComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(HeaderComponent);