import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MAIN_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_MAIN_DATA_REDUCER = createAsyncThunk(
    'MainPageReducer/fetchMainData',
    async () => {
        const response = await GET(MAIN_API);
        return response.body.data
    }
)


export const MainPageReducer = createSlice({
    name: 'MainPageReducer',
    initialState: {
        data: {  },
        loading: false,
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [FETCH_MAIN_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_MAIN_DATA_REDUCER.fulfilled]: (state, { payload}) => {
            state.data = {
                ...state.data,
                ...payload
            }
            state.loading = false;
        },
        [FETCH_MAIN_DATA_REDUCER.rejected]: (state, {payload}) => {
            state.data = {};
            state.loading = false;
            state.error = payload;
            state.error = {};
        }
    }
})

// export const { } = MainPageReducer.actions

export default MainPageReducer.reducer