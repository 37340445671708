import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NEWS_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_NEWS_DATA_REDUCER = createAsyncThunk(
    'NewsReducer/fetchNewsData',
    async (page) => {
        const response = await GET(NEWS_API, { page});
        return response.body
    }
)


export const NewsReducer = createSlice({
    name: 'NewsReducer',
    initialState: {
        data: [],
        meta: { },
        loading: false,
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [FETCH_NEWS_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_NEWS_DATA_REDUCER.fulfilled]: (state, { payload}) => {
            state.data = [
                ...payload?.data
            ];
            state.meta = payload.meta;
            state.loading = false;
        },
        [FETCH_NEWS_DATA_REDUCER.rejected]: (state, { payload}) => {
            state.data = {};
            state.loading = false;
            state.error = payload;
            state.error = {};
        }
    }
})

// export const { } = NewsReducer.actions

export default NewsReducer.reducer