import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfoComponent from '../../components/InfoComponent';
import CashBenefitsSvg from '../../svgs/forFamily/CashBenefitsSvg';
import NurseriesSvg from '../../svgs/forFamily/NurseriesSvg';
import EducationSvg from '../../svgs/forFamily/EducationSvg';
import FamilyCardSvg from '../../svgs/forFamily/FamilyCardSvg';
import PageContainerComponent from '../../components/PageContainerComponent';
import { Grid } from '@material-ui/core';

const ForFamilyPage = (props) => {
    const { t } = useTranslation();
    return (
        <PageContainerComponent
            title={t('for_family_page.title')}
            intro={t('for_family_page.intro')}
            infoTitle={t('for_family_page.info_title')}
        >
                <Grid container>
                    <InfoComponent
                        Icon={CashBenefitsSvg}
                        title={t('for_family_page.cash_benefits_title')}
                        desc={t('for_family_page.cash_benefits_desc')}
                    />
                    <InfoComponent
                        Icon={NurseriesSvg}
                        title={t('for_family_page.nurseries_title')}
                        desc={t('for_family_page.nurseries_desc')}
                    />
                    <InfoComponent
                        Icon={EducationSvg}
                        title={t('for_family_page.education_title')}
                        desc={t('for_family_page.education_desc')}
                    />
                    <InfoComponent
                        Icon={FamilyCardSvg}
                        title={t('for_family_page.family_card_title')}
                        desc={t('for_family_page.family_card_desc')}
                    />
                </Grid>
        </PageContainerComponent>
    )
}


ForFamilyPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(ForFamilyPage);