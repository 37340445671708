import React from 'react'
import { Grid } from '@material-ui/core';
import PageContainerComponent from '../../components/PageContainerComponent';
import { useTranslation } from 'react-i18next';
import InfoComponent from '../../components/InfoComponent';
import IndividualSvg from '../../svgs/about/IndividualSvg';
import ComplexSvg from '../../svgs/about/ComplexSvg';
import BirthSvg from '../../svgs/about/BirthSvg';
import KnowledgeSvg from '../../svgs/about/KnowledgeSvg';
import OfferSvg from '../../svgs/about/OfferSvg';
import InfoSvg from '../../svgs/about/InfoSvg';

const AboutPage = (props) => {
    const { t } = useTranslation();
    return (
        <PageContainerComponent
            title={t('about_page.title')}
            intro={t('about_page.intro')}
            infoTitle={t('about_page.info_title')}
        >
            <Grid container justify='space-between'>
                <InfoComponent
                    md={6}
                    Icon={IndividualSvg}
                    desc={t('about_page.individual')}
                />
                <InfoComponent
                    md={6}
                    Icon={KnowledgeSvg}
                    desc={t('about_page.knowledge')}
                />
                <InfoComponent
                    md={6}
                    Icon={ComplexSvg}
                    desc={t('about_page.complex')}
                />
                <InfoComponent
                    md={6}
                    Icon={OfferSvg}
                    desc={t('about_page.offer')}
                />
                <InfoComponent
                    md={6}
                    Icon={BirthSvg}
                    desc={t('about_page.birth')}
                />
                <InfoComponent
                    md={6}
                    Icon={InfoSvg}
                    desc={t('about_page.info')}
                />
            </Grid>
        </PageContainerComponent>
    )
}

export default React.memo(AboutPage);