import * as React from "react"

function LogoSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={254.719}
            height={48.812}
            viewBox="0 0 254.719 48.812"
            {...props}
        >
            <defs>
                <clipPath id="prefix__a">
                    <path
                        data-name="Rectangle 55"
                        fill="none"
                        d="M0 0h96.626v48.133H0z"
                    />
                </clipPath>
            </defs>
            <g data-name="Group 524">
                <g data-name="Group 526">
                    <g data-name="Group 525" clipPath="url(#prefix__a)">
                        <path
                            data-name="Rectangle 53"
                            fill="#00256f"
                            d="M37.123-4.407h75.517v55.921H37.123z"
                        />
                        <path
                            data-name="Rectangle 54"
                            fill="#d9000d"
                            d="M-.841-1.535H44.13v50.667H-.841z"
                        />
                        <g data-name="Group 524" fill="#fff" fillRule="evenodd">
                            <path
                                data-name="Path 1315"
                                d="M21.602 41.115c-1.4.157-2.95-1.29-3.064-3.009a4.92 4.92 0 011.428-3.791c1.094-1.144 2.259-2.193 3.442-3.242a30.993 30.993 0 004.821-4.647 6.252 6.252 0 001.385-3.777 4.5 4.5 0 00-2.54-3.995 1.223 1.223 0 00-1.82.637 2.121 2.121 0 01-2.714 1.162c-1.81-.372-3.6-.817-5.434-1.088a10.7 10.7 0 00-3.276-.02 3.123 3.123 0 00-2.907 2.783 5.979 5.979 0 01-.557-.614 3.393 3.393 0 011.04-4.989 6.043 6.043 0 013.093-.866c1.554-.059 3.118-.035 4.674-.02a.755.755 0 01.557.281c.4.775.854 1.051 1.681.8a15.022 15.022 0 001.475-.706 3.922 3.922 0 011.162-.373c1.108-.064 2.232.036 3.322-.095a7.248 7.248 0 004.319-2.542c.535-.565 1.024-1.184 1.652-1.733-.131.732-.27 1.459-.4 2.191-.168.814-.344 1.632-.505 2.438a1.591 1.591 0 01-1.736 1.441c-.791.023-1.59.016-2.384.023a.154.154 0 01-.047.07c.331.181.668.344 1 .525 3.838 2.051 4.907 5.875 2.773 9.636a25.438 25.438 0 01-4.538 5.354 806.3 806.3 0 00-5.087 5.046 5.776 5.776 0 00-.6.825 1.865 1.865 0 00-.215 2.295z"
                            />
                            <path
                                data-name="Path 1316"
                                d="M12.808 9.739a2.745 2.745 0 012.832.777c.528.594 1.013 1.238 1.559 1.788a3.38 3.38 0 001.271.886 1.078 1.078 0 001.468-1.307c-.546-1.679.646-2.372 1.6-3.342.49.5.9.929 1.33 1.384a1.5 1.5 0 01.309 1.543 1.259 1.259 0 00.378 1.6 1.455 1.455 0 001.76-.252c.7-.686 1.323-1.44 1.958-2.193a2.791 2.791 0 012.923-.881c-.166.288-.29.512-.437.732a196.8 196.8 0 01-2.062 3.235 2.413 2.413 0 01-2.127 1.087c-2.7.007-5.418.013-8.114 0a2.391 2.391 0 01-2.085-1.063c-.841-1.288-1.668-2.6-2.563-3.994z"
                            />
                            <path
                                data-name="Path 1317"
                                d="M16.021 21.703c1.609.109 3.193.19 4.765.331a3.216 3.216 0 013.05 2.625 3.814 3.814 0 01-5.5 3.979 8.853 8.853 0 01-.78-.424c.512-.107.9-.163 1.278-.265a2.326 2.326 0 001.869-2.147 2.2 2.2 0 00-1.575-2.235c-.564-.235-1.151-.415-1.72-.664a2.315 2.315 0 01-1.387-1.2z"
                            />
                        </g>
                    </g>
                </g>
                <path
                    data-name="Path 1318"
                    d="M82.14 14.414a2.93 2.93 0 01-3.148 2.918 2.939 2.939 0 11.038-5.874 2.926 2.926 0 013.11 2.956zm-6.027 24V19.636h5.835v18.775z"
                    fill="#fff"
                />
                <g data-name="Group 527">
                    <path
                        data-name="Path 1319"
                        d="M68.171 26.266a3.428 3.428 0 00-3.788-3.209c-3.283 0-4.364 3.32-4.364 6.06 0 2.707 1.042 5.877 4.26 5.877 2.413 0 3.75-1.474 4.07-3.783h4.944c-.684 4.944-4.044 7.644-8.981 7.644-5.63 0-9.414-3.966-9.414-9.562 0-5.8 3.464-10.1 9.523-10.1 4.4 0 8.474 2.31 8.768 7.071z"
                        fill="#fff"
                    />
                </g>
                <g data-name="Group 528" fill="#373e42">
                    <path
                        data-name="Path 1320"
                        d="M119.365 22.849q0 .256-.015.435a2.137 2.137 0 01-.046.307.876.876 0 01-.082.22 1.081 1.081 0 01-.164.21 2.088 2.088 0 01-.435.3 5.1 5.1 0 01-.788.353 6.888 6.888 0 01-1.065.282 7.019 7.019 0 01-1.3.113 6.825 6.825 0 01-2.458-.42 4.856 4.856 0 01-1.864-1.254 5.611 5.611 0 01-1.178-2.084 9.352 9.352 0 01-.41-2.909 9.211 9.211 0 01.451-3 6.247 6.247 0 011.26-2.2 5.273 5.273 0 011.941-1.352 6.554 6.554 0 012.494-.461 6 6 0 011.065.092 6.532 6.532 0 01.947.241 4.759 4.759 0 01.784.343 2.353 2.353 0 01.487.333 1.159 1.159 0 01.189.231.866.866 0 01.082.236 2.643 2.643 0 01.046.338q.015.195.015.481 0 .308-.02.522a1.35 1.35 0 01-.072.348.484.484 0 01-.123.195.245.245 0 01-.164.061.709.709 0 01-.389-.179 6.309 6.309 0 00-.609-.4 4.963 4.963 0 00-.891-.4 3.784 3.784 0 00-1.234-.179 2.916 2.916 0 00-2.463 1.245 4.317 4.317 0 00-.661 1.449 8.431 8.431 0 00.015 3.937 3.9 3.9 0 00.686 1.408 2.775 2.775 0 001.075.824 3.55 3.55 0 001.418.271 4.044 4.044 0 001.239-.169 5.218 5.218 0 00.9-.374q.374-.2.615-.369a.759.759 0 01.374-.164.29.29 0 01.164.041.306.306 0 01.1.164 1.6 1.6 0 01.062.343q.022.223.022.591z"
                    />
                    <path
                        data-name="Path 1321"
                        d="M128.75 19.675a1 1 0 01-.21.7.753.753 0 01-.578.225h-5.561a3.793 3.793 0 00.138 1.06 2.019 2.019 0 00.44.809 1.913 1.913 0 00.778.507 3.328 3.328 0 001.142.174 6.434 6.434 0 001.188-.1 7.91 7.91 0 00.886-.215q.374-.118.62-.215a1.147 1.147 0 01.4-.1.3.3 0 01.154.036.269.269 0 01.1.128.861.861 0 01.056.261q.015.169.015.425 0 .225-.01.384a2.39 2.39 0 01-.031.271.646.646 0 01-.061.19.722.722 0 01-.107.149 1.258 1.258 0 01-.363.194 5.775 5.775 0 01-.758.241 10.414 10.414 0 01-1.055.2 8.755 8.755 0 01-1.27.087 6.723 6.723 0 01-2.135-.307 3.8 3.8 0 01-1.531-.932 3.872 3.872 0 01-.917-1.577 7.4 7.4 0 01-.3-2.232 7.088 7.088 0 01.318-2.2 4.6 4.6 0 01.922-1.659 3.965 3.965 0 011.469-1.039 5.057 5.057 0 011.951-.358 5.108 5.108 0 011.961.338 3.537 3.537 0 011.336.937 3.78 3.78 0 01.768 1.419 6.18 6.18 0 01.246 1.782zm-2.5-.737a2.649 2.649 0 00-.435-1.7 1.685 1.685 0 00-1.439-.619 1.864 1.864 0 00-.855.184 1.748 1.748 0 00-.6.5 2.263 2.263 0 00-.369.737 3.653 3.653 0 00-.149.906z"
                    />
                    <path
                        data-name="Path 1322"
                        d="M138.752 24.508a.279.279 0 01-.061.179.45.45 0 01-.205.128 1.737 1.737 0 01-.394.077q-.251.025-.62.026t-.63-.026a1.733 1.733 0 01-.394-.077.449.449 0 01-.2-.128.278.278 0 01-.061-.179v-5.213a4.387 4.387 0 00-.1-1.044 2.059 2.059 0 00-.282-.65 1.256 1.256 0 00-.476-.42 1.485 1.485 0 00-.681-.148 1.706 1.706 0 00-.993.359 5.36 5.36 0 00-1.045 1.044v6.073a.279.279 0 01-.061.179.452.452 0 01-.21.128 1.822 1.822 0 01-.394.077q-.246.025-.625.026t-.625-.026a1.82 1.82 0 01-.394-.077.451.451 0 01-.21-.128.278.278 0 01-.062-.179v-9.135a.313.313 0 01.051-.179.376.376 0 01.184-.128 1.475 1.475 0 01.343-.077 4.518 4.518 0 01.528-.026 4.763 4.763 0 01.543.026 1.233 1.233 0 01.333.077.379.379 0 01.169.128.317.317 0 01.051.179v1.055a5.791 5.791 0 011.541-1.229 3.481 3.481 0 011.634-.41 3.615 3.615 0 011.572.307 2.758 2.758 0 011.034.835 3.394 3.394 0 01.568 1.234 7.185 7.185 0 01.174 1.7z"
                    />
                    <path
                        data-name="Path 1323"
                        d="M145.655 23.608a3.229 3.229 0 01-.057.692.742.742 0 01-.148.338.808.808 0 01-.271.174 2.562 2.562 0 01-.42.133 4.361 4.361 0 01-.522.087 5.275 5.275 0 01-.568.031 4.09 4.09 0 01-1.331-.194 2.208 2.208 0 01-.932-.6 2.493 2.493 0 01-.543-1.019 5.366 5.366 0 01-.174-1.454v-4.68h-1.1q-.195 0-.3-.241a2.237 2.237 0 01-.1-.8 4.181 4.181 0 01.026-.5 1.194 1.194 0 01.077-.322.376.376 0 01.128-.169.316.316 0 01.179-.051h1.089v-2.045a.318.318 0 01.056-.184.421.421 0 01.205-.138 1.611 1.611 0 01.4-.082q.251-.025.62-.026t.63.026a1.54 1.54 0 01.394.082.46.46 0 01.205.138.3.3 0 01.061.184v2.038h1.987a.316.316 0 01.179.051.378.378 0 01.128.169 1.211 1.211 0 01.077.322 4.163 4.163 0 01.026.5 2.242 2.242 0 01-.1.8q-.1.241-.3.241h-2V21.4a2.13 2.13 0 00.236 1.121.913.913 0 00.84.374 1.723 1.723 0 00.369-.036 2.359 2.359 0 00.292-.082q.128-.046.215-.082a.418.418 0 01.159-.036.214.214 0 01.118.036.243.243 0 01.087.143 2.221 2.221 0 01.056.292 3.554 3.554 0 01.027.478z"
                    />
                    <path
                        data-name="Path 1324"
                        d="M152.546 16.224q0 .369-.021.6a1.91 1.91 0 01-.061.369.362.362 0 01-.108.184.27.27 0 01-.169.051.561.561 0 01-.184-.036c-.068-.024-.145-.049-.23-.077s-.179-.053-.282-.077a1.478 1.478 0 00-.338-.036 1.147 1.147 0 00-.43.087 1.818 1.818 0 00-.445.272 3.285 3.285 0 00-.481.491 7.963 7.963 0 00-.537.758v5.694a.278.278 0 01-.062.179.451.451 0 01-.21.128 1.82 1.82 0 01-.394.077q-.246.025-.625.026t-.625-.026a1.818 1.818 0 01-.394-.077.453.453 0 01-.21-.128.279.279 0 01-.061-.179v-9.135a.313.313 0 01.051-.179.375.375 0 01.184-.128 1.475 1.475 0 01.343-.077 4.51 4.51 0 01.527-.026 4.76 4.76 0 01.543.026 1.235 1.235 0 01.333.077.379.379 0 01.169.128.317.317 0 01.051.179v1.137a7.236 7.236 0 01.676-.845 3.808 3.808 0 01.6-.528 1.9 1.9 0 01.573-.271 2.205 2.205 0 01.574-.077c.089 0 .184.005.287.015a2.759 2.759 0 01.317.051 2.068 2.068 0 01.287.082.566.566 0 01.179.1.327.327 0 01.082.113.91.91 0 01.046.169 2.424 2.424 0 01.031.323q.014.217.014.586z"
                    />
                    <path
                        data-name="Path 1325"
                        d="M161.96 24.509a.315.315 0 01-.051.179.375.375 0 01-.179.128 1.382 1.382 0 01-.338.077 4.516 4.516 0 01-.528.026 4.731 4.731 0 01-.548-.026 1.294 1.294 0 01-.333-.077.374.374 0 01-.174-.128.315.315 0 01-.051-.179v-1.055a5.788 5.788 0 01-1.541 1.229 3.461 3.461 0 01-1.623.41 3.643 3.643 0 01-1.582-.307 2.732 2.732 0 01-1.034-.84 3.373 3.373 0 01-.563-1.234 7.612 7.612 0 01-.169-1.746v-5.591a.293.293 0 01.056-.179.417.417 0 01.2-.128 1.852 1.852 0 01.4-.077q.251-.025.62-.026t.624.026a1.84 1.84 0 01.394.077.453.453 0 01.21.128.278.278 0 01.062.179v5.161a4.946 4.946 0 00.092 1.1 1.913 1.913 0 00.282.65 1.3 1.3 0 00.481.42 1.481 1.481 0 00.681.148 1.682 1.682 0 00.988-.359 5.893 5.893 0 001.06-1.044v-6.073a.3.3 0 01.056-.179.417.417 0 01.205-.128 1.833 1.833 0 01.394-.077q.246-.025.625-.026t.625.026a1.744 1.744 0 01.389.077.453.453 0 01.2.128.278.278 0 01.062.179z"
                    />
                    <path
                        data-name="Path 1326"
                        d="M178.244 24.508a.278.278 0 01-.062.179.449.449 0 01-.2.128 1.724 1.724 0 01-.389.077q-.246.025-.625.026t-.635-.026a1.82 1.82 0 01-.394-.077.415.415 0 01-.205-.128.294.294 0 01-.056-.179v-5.417a3.748 3.748 0 00-.087-.84 1.974 1.974 0 00-.266-.65 1.265 1.265 0 00-.45-.42 1.339 1.339 0 00-.65-.148 1.54 1.54 0 00-.932.359 6.217 6.217 0 00-1.024 1.044v6.073a.278.278 0 01-.062.179.451.451 0 01-.21.128 1.818 1.818 0 01-.394.077 6.144 6.144 0 01-.615.026q-.379 0-.625-.026a1.818 1.818 0 01-.394-.077.448.448 0 01-.21-.128.276.276 0 01-.062-.179v-5.417a3.766 3.766 0 00-.087-.84 2.048 2.048 0 00-.261-.65 1.21 1.21 0 00-.451-.42 1.345 1.345 0 00-.645-.148 1.55 1.55 0 00-.942.359 5.953 5.953 0 00-1.014 1.044v6.073a.278.278 0 01-.061.179.452.452 0 01-.21.128 1.824 1.824 0 01-.394.077q-.246.025-.625.026t-.625-.026a1.818 1.818 0 01-.394-.077.449.449 0 01-.21-.128.276.276 0 01-.062-.179v-9.135a.317.317 0 01.051-.179.379.379 0 01.185-.128 1.479 1.479 0 01.343-.077 4.505 4.505 0 01.527-.026 4.763 4.763 0 01.543.026 1.24 1.24 0 01.333.077.378.378 0 01.169.128.313.313 0 01.051.179v1.055a6.038 6.038 0 011.51-1.228 3.244 3.244 0 011.572-.41 4.026 4.026 0 011.014.117 2.9 2.9 0 01.8.338 2.533 2.533 0 01.61.528 3.088 3.088 0 01.43.686 7.691 7.691 0 01.8-.758 4.722 4.722 0 01.778-.517 3.553 3.553 0 01.768-.3 3.171 3.171 0 01.783-.1 3.462 3.462 0 011.545.316 2.7 2.7 0 011.009.835 3.361 3.361 0 01.548 1.234 6.6 6.6 0 01.164 1.495z"
                    />
                    <path
                        data-name="Path 1327"
                        d="M186.694 24.493a.284.284 0 01-.067.184.466.466 0 01-.22.133 2.122 2.122 0 01-.409.082 6.914 6.914 0 01-1.306 0 2.138 2.138 0 01-.415-.082.465.465 0 01-.22-.133.286.286 0 01-.066-.184V11.974a.285.285 0 01.066-.184.469.469 0 01.225-.133 2.272 2.272 0 01.415-.082 5.554 5.554 0 01.645-.031 5.668 5.668 0 01.655.031 2.141 2.141 0 01.409.082.468.468 0 01.22.133.284.284 0 01.067.184z"
                    />
                    <path
                        data-name="Path 1328"
                        d="M197.314 24.508a.278.278 0 01-.062.179.45.45 0 01-.2.128 1.745 1.745 0 01-.394.077c-.168.017-.374.026-.62.026s-.462-.009-.63-.026a1.736 1.736 0 01-.394-.077.447.447 0 01-.2-.128.277.277 0 01-.062-.179v-5.213a4.383 4.383 0 00-.1-1.044 2.053 2.053 0 00-.281-.65 1.253 1.253 0 00-.476-.42 1.483 1.483 0 00-.681-.148 1.705 1.705 0 00-.993.359 5.366 5.366 0 00-1.044 1.044v6.073a.278.278 0 01-.062.179.452.452 0 01-.21.128 1.825 1.825 0 01-.394.077q-.246.025-.625.026a6.24 6.24 0 01-.625-.026 1.825 1.825 0 01-.394-.077.453.453 0 01-.21-.128.279.279 0 01-.061-.179v-9.135a.312.312 0 01.051-.179.375.375 0 01.184-.128 1.472 1.472 0 01.343-.077 4.514 4.514 0 01.527-.026 4.761 4.761 0 01.543.026 1.235 1.235 0 01.333.077.378.378 0 01.169.128.315.315 0 01.051.179v1.055a5.794 5.794 0 011.541-1.229 3.482 3.482 0 011.634-.41 3.615 3.615 0 011.572.307 2.758 2.758 0 011.035.835 3.4 3.4 0 01.568 1.234 7.184 7.184 0 01.174 1.7z"
                    />
                    <path
                        data-name="Path 1329"
                        d="M201.78 12.281a1.445 1.445 0 01-.317 1.075 2.512 2.512 0 01-2.35.01 1.4 1.4 0 01-.3-1.034 1.48 1.48 0 01.312-1.081 2.474 2.474 0 012.35-.01 1.406 1.406 0 01.305 1.04zm-.195 12.227a.279.279 0 01-.061.179.454.454 0 01-.21.128 1.829 1.829 0 01-.395.077q-.246.025-.625.026t-.625-.026a1.814 1.814 0 01-.394-.077.45.45 0 01-.21-.128.278.278 0 01-.062-.179v-9.114a.279.279 0 01.062-.179.482.482 0 01.21-.133 1.745 1.745 0 01.394-.087 6.329 6.329 0 011.249 0 1.76 1.76 0 01.395.087.486.486 0 01.21.133.28.28 0 01.061.179z"
                    />
                    <path
                        data-name="Path 1330"
                        d="M210.23 23.055q0 .267-.015.446a2.653 2.653 0 01-.041.3.961.961 0 01-.061.2.743.743 0 01-.159.194 2.262 2.262 0 01-.42.287 4.141 4.141 0 01-.676.3 5.544 5.544 0 01-.824.215 5.1 5.1 0 01-.917.082 4.976 4.976 0 01-1.869-.328 3.511 3.511 0 01-1.367-.967 4.267 4.267 0 01-.835-1.562 7.228 7.228 0 01-.282-2.11 7.312 7.312 0 01.343-2.371 4.534 4.534 0 01.957-1.654 3.82 3.82 0 011.454-.973 5.177 5.177 0 011.843-.318 4.508 4.508 0 01.8.072 4.65 4.65 0 01.737.194 3.96 3.96 0 01.615.277 1.886 1.886 0 01.384.266 1.1 1.1 0 01.159.189.66.66 0 01.072.2 2.651 2.651 0 01.041.3c.011.12.015.265.015.435a2.359 2.359 0 01-.1.835c-.068.16-.157.241-.266.241a.629.629 0 01-.369-.143q-.195-.143-.461-.317a3.441 3.441 0 00-.635-.318 2.428 2.428 0 00-.88-.143 1.751 1.751 0 00-1.536.773 4 4 0 00-.532 2.268 5.677 5.677 0 00.133 1.3 2.762 2.762 0 00.394.942 1.659 1.659 0 00.655.568 2.092 2.092 0 00.917.189 2.384 2.384 0 00.916-.159 3.711 3.711 0 00.671-.353c.191-.129.352-.247.481-.353a.57.57 0 01.328-.159.23.23 0 01.153.051.36.36 0 01.1.189 2.366 2.366 0 01.056.353q.02.224.021.562z"
                    />
                    <path
                        data-name="Path 1331"
                        d="M214.029 24.816a6.719 6.719 0 01-.184 1.741 3.013 3.013 0 01-.558 1.121 2.337 2.337 0 01-.937.686 3.472 3.472 0 01-1.321.231 3.911 3.911 0 01-.717-.056 2.009 2.009 0 01-.43-.118.386.386 0 01-.184-.154.674.674 0 01-.072-.21q-.02-.118-.031-.266t-.01-.384q0-.287.015-.476a1.209 1.209 0 01.056-.3.449.449 0 01.093-.164.179.179 0 01.133-.051 1.693 1.693 0 01.21.025 2.171 2.171 0 00.343.026 1.185 1.185 0 00.461-.082.716.716 0 00.318-.261 1.307 1.307 0 00.184-.461 4.679 4.679 0 00.061-.9v-9.37a.277.277 0 01.062-.179.478.478 0 01.2-.133 1.66 1.66 0 01.389-.087 6.325 6.325 0 011.249 0 1.756 1.756 0 01.394.087.48.48 0 01.21.133.278.278 0 01.062.179zm.194-12.535a1.462 1.462 0 01-.312 1.075 2.512 2.512 0 01-2.35.01 1.385 1.385 0 01-.307-1.034 1.463 1.463 0 01.317-1.081 2.474 2.474 0 012.35-.01 1.423 1.423 0 01.303 1.04z"
                    />
                    <path
                        data-name="Path 1332"
                        d="M223.554 24.539a.272.272 0 01-.1.225.7.7 0 01-.322.118 4.387 4.387 0 01-.651.036 4.439 4.439 0 01-.67-.036.572.572 0 01-.3-.118.293.293 0 01-.087-.225v-.727a4.145 4.145 0 01-1.285.942 3.733 3.733 0 01-1.6.338 4.475 4.475 0 01-1.336-.19 3 3 0 01-1.055-.563 2.538 2.538 0 01-.692-.927 3.156 3.156 0 01-.246-1.29 2.922 2.922 0 01.312-1.388 2.54 2.54 0 01.932-.973 4.632 4.632 0 011.541-.568 10.991 10.991 0 012.14-.184h.891v-.553a2.908 2.908 0 00-.087-.753 1.252 1.252 0 00-.286-.538 1.184 1.184 0 00-.533-.318 2.843 2.843 0 00-.824-.1 4.242 4.242 0 00-1.152.143 6.642 6.642 0 00-.9.317q-.389.174-.65.318a.951.951 0 01-.425.143.3.3 0 01-.2-.071.526.526 0 01-.143-.2 1.413 1.413 0 01-.087-.328 2.757 2.757 0 01-.031-.43 1.935 1.935 0 01.051-.5.77.77 0 01.195-.333 1.921 1.921 0 01.5-.328 5.918 5.918 0 01.839-.333 7.776 7.776 0 011.05-.251 6.923 6.923 0 011.172-.1 7 7 0 011.838.21 3.1 3.1 0 011.254.645 2.511 2.511 0 01.717 1.121 5.251 5.251 0 01.225 1.628zm-2.529-3.912h-.985a5.276 5.276 0 00-1.065.092 2.036 2.036 0 00-.717.276 1.137 1.137 0 00-.4.445 1.346 1.346 0 00-.128.6 1.15 1.15 0 00.364.9 1.452 1.452 0 001.009.328 1.889 1.889 0 001-.276 4.153 4.153 0 00.927-.809z"
                    />
                    <path
                        data-name="Path 1333"
                        d="M230.499 23.608a3.241 3.241 0 01-.056.692.748.748 0 01-.148.338.813.813 0 01-.272.174 2.556 2.556 0 01-.42.133 4.36 4.36 0 01-.523.087 5.262 5.262 0 01-.568.031 4.094 4.094 0 01-1.332-.194 2.208 2.208 0 01-.932-.6 2.5 2.5 0 01-.543-1.019 5.374 5.374 0 01-.174-1.454v-4.68h-1.1q-.195 0-.3-.241a2.248 2.248 0 01-.1-.8 4.127 4.127 0 01.026-.5 1.2 1.2 0 01.077-.322.376.376 0 01.128-.169.315.315 0 01.179-.051h1.085v-2.045a.316.316 0 01.056-.184.419.419 0 01.2-.138 1.621 1.621 0 01.4-.082q.251-.025.62-.026t.63.026a1.542 1.542 0 01.395.082.456.456 0 01.2.138.3.3 0 01.061.184v2.038h1.987a.315.315 0 01.179.051.381.381 0 01.128.169 1.221 1.221 0 01.077.322 4.233 4.233 0 01.026.5 2.25 2.25 0 01-.1.8q-.1.241-.3.241h-2V21.4a2.133 2.133 0 00.236 1.121.913.913 0 00.84.374 1.727 1.727 0 00.369-.036 2.321 2.321 0 00.292-.082q.128-.046.215-.082a.419.419 0 01.159-.036.216.216 0 01.118.036.24.24 0 01.087.143 2.2 2.2 0 01.056.292 3.613 3.613 0 01.042.478z"
                    />
                    <path
                        data-name="Path 1334"
                        d="M236.819 24.857l-1.106 3.236a.666.666 0 01-.5.369 6.28 6.28 0 01-1.854.077.918.918 0 01-.368-.118.268.268 0 01-.133-.205.655.655 0 01.061-.3l1.219-3.062a.69.69 0 01-.241-.19.871.871 0 01-.159-.271l-3.144-8.4a1.728 1.728 0 01-.133-.548.373.373 0 01.123-.3.806.806 0 01.415-.149 5.811 5.811 0 01.773-.041q.44 0 .7.016a1.233 1.233 0 01.4.077.435.435 0 01.215.19 1.936 1.936 0 01.143.363l2.151 6.114h.031l1.966-6.237a.694.694 0 01.159-.353.663.663 0 01.323-.128 5.1 5.1 0 01.788-.041 5.428 5.428 0 01.732.041.875.875 0 01.43.154.371.371 0 01.138.3 1.489 1.489 0 01-.082.446z"
                    />
                    <path
                        data-name="Path 1335"
                        d="M254.719 15.353a1.528 1.528 0 01-.031.277 7.154 7.154 0 01-.113.471l-2.437 8.264a.866.866 0 01-.133.282.535.535 0 01-.251.169 1.828 1.828 0 01-.481.082q-.312.021-.814.021t-.819-.026a2.121 2.121 0 01-.5-.087.537.537 0 01-.262-.169.743.743 0 01-.123-.271l-1.536-5.551-.021-.092-.021.092-1.413 5.551a.8.8 0 01-.118.282.507.507 0 01-.261.169 2.073 2.073 0 01-.5.082q-.317.021-.819.021t-.824-.026a2.116 2.116 0 01-.492-.087.535.535 0 01-.261-.169.725.725 0 01-.123-.271l-2.417-8.264a3.36 3.36 0 01-.118-.466 1.916 1.916 0 01-.026-.282.3.3 0 01.056-.179.387.387 0 01.205-.123 1.923 1.923 0 01.4-.067q.25-.021.629-.021c.273 0 .5.007.666.021a1.634 1.634 0 01.4.072.381.381 0 01.2.139.693.693 0 01.087.21l1.812 6.708.021.123.02-.123 1.7-6.708a.559.559 0 01.1-.21.443.443 0 01.2-.139 1.419 1.419 0 01.374-.072q.241-.021.62-.021a5.954 5.954 0 01.615.026 1.863 1.863 0 01.374.072.383.383 0 01.194.123.59.59 0 01.087.179l1.823 6.759.031.113.021-.123 1.741-6.708a.488.488 0 01.082-.21.409.409 0 01.2-.139 1.6 1.6 0 01.389-.072q.246-.021.624-.021t.62.021a1.72 1.72 0 01.379.067.351.351 0 01.189.123.315.315 0 01.055.178z"
                    />
                    <path
                        data-name="Path 1336"
                        d="M120.03 44.747a.4.4 0 01-.041.189.327.327 0 01-.195.128 1.924 1.924 0 01-.451.072q-.3.021-.809.021-.43 0-.686-.021a1.511 1.511 0 01-.4-.077.437.437 0 01-.21-.143.8.8 0 01-.1-.21l-1.188-2.96q-.215-.5-.42-.891a2.847 2.847 0 00-.456-.65 1.659 1.659 0 00-.579-.394 2 2 0 00-.758-.133h-.84v5.044a.285.285 0 01-.066.184.464.464 0 01-.22.133 2.123 2.123 0 01-.41.082 6.912 6.912 0 01-1.306 0 2.134 2.134 0 01-.415-.082.429.429 0 01-.215-.133.3.3 0 01-.062-.184V32.693a.818.818 0 01.231-.65.837.837 0 01.568-.2h3.431q.522 0 .86.021t.614.051a5.594 5.594 0 011.439.389 3.452 3.452 0 011.086.722 3 3 0 01.681 1.055 3.862 3.862 0 01.235 1.4 4.107 4.107 0 01-.169 1.213 3.112 3.112 0 01-.5.978 3.271 3.271 0 01-.809.748 4.383 4.383 0 01-1.1.512 3.055 3.055 0 01.558.348 2.958 2.958 0 01.491.5 4.788 4.788 0 01.435.666 8.715 8.715 0 01.4.845l1.116 2.612q.154.389.2.568a1.1 1.1 0 01.06.276zm-3.472-8.991a1.967 1.967 0 00-.3-1.106 1.615 1.615 0 00-.973-.635 3.762 3.762 0 00-.466-.082 6.578 6.578 0 00-.722-.031h-1.209v3.758h1.372a3.284 3.284 0 001-.138 2.006 2.006 0 00.717-.389 1.6 1.6 0 00.43-.6 2.028 2.028 0 00.152-.778z"
                    />
                    <path
                        data-name="Path 1337"
                        d="M129.882 40.087a6.988 6.988 0 01-.307 2.13 4.5 4.5 0 01-.932 1.659 4.145 4.145 0 01-1.567 1.075 5.873 5.873 0 01-2.2.379 6.027 6.027 0 01-2.12-.338 3.805 3.805 0 01-1.5-.983 4.036 4.036 0 01-.881-1.587 7.385 7.385 0 01-.287-2.151 6.926 6.926 0 01.312-2.135 4.5 4.5 0 01.937-1.659 4.18 4.18 0 011.562-1.07 5.832 5.832 0 012.2-.379 6.154 6.154 0 012.13.333 3.725 3.725 0 011.49.978 4.077 4.077 0 01.875 1.587 7.433 7.433 0 01.288 2.161zm-2.652.1a6.718 6.718 0 00-.107-1.245 2.931 2.931 0 00-.364-.988 1.819 1.819 0 00-.681-.656 2.188 2.188 0 00-1.07-.235 2.261 2.261 0 00-1 .21 1.8 1.8 0 00-.706.615 2.938 2.938 0 00-.415.973 5.5 5.5 0 00-.138 1.3 6.417 6.417 0 00.113 1.244 3.071 3.071 0 00.364.988 1.731 1.731 0 00.681.65 2.236 2.236 0 001.065.231 2.283 2.283 0 001.014-.21 1.813 1.813 0 00.707-.609 2.814 2.814 0 00.41-.968 5.705 5.705 0 00.127-1.298z"
                    />
                    <path
                        data-name="Path 1338"
                        d="M139.745 44.746a.334.334 0 01-.051.189.375.375 0 01-.174.128 1.186 1.186 0 01-.333.072q-.21.021-.517.021-.328 0-.533-.021a1.244 1.244 0 01-.333-.072.408.408 0 01-.184-.128.312.312 0 01-.056-.189v-1.014a5.871 5.871 0 01-1.48 1.178 3.883 3.883 0 01-3.482.015 3.368 3.368 0 01-1.178-1.091 4.731 4.731 0 01-.661-1.6 8.828 8.828 0 01-.2-1.931 8.459 8.459 0 01.261-2.191 4.949 4.949 0 01.763-1.659 3.378 3.378 0 011.249-1.055 3.844 3.844 0 011.721-.369 3.07 3.07 0 011.393.312 4.963 4.963 0 011.239.916v-4.942a.321.321 0 01.056-.184.424.424 0 01.205-.138 1.747 1.747 0 01.394-.087 6.377 6.377 0 011.249 0 1.66 1.66 0 01.389.087.46.46 0 01.2.138.3.3 0 01.062.184zm-2.56-6.1a5.665 5.665 0 00-1.06-1.065 1.777 1.777 0 00-1.06-.369 1.484 1.484 0 00-.865.251 1.822 1.822 0 00-.584.671 3.561 3.561 0 00-.328.937 5.2 5.2 0 00-.108 1.06 6.646 6.646 0 00.092 1.116 3.5 3.5 0 00.3.968 1.837 1.837 0 00.558.681 1.449 1.449 0 00.881.256 1.754 1.754 0 00.517-.077 1.965 1.965 0 00.512-.251 3.435 3.435 0 00.538-.456 9.136 9.136 0 00.6-.691z"
                    />
                    <path
                        data-name="Path 1339"
                        d="M147.888 44.039a3.583 3.583 0 01-.031.507 1.424 1.424 0 01-.082.333.331.331 0 01-.133.169.37.37 0 01-.184.046h-5.97a.514.514 0 01-.425-.184.968.968 0 01-.148-.6v-.471a3.258 3.258 0 01.015-.333 1.332 1.332 0 01.061-.287 1.8 1.8 0 01.123-.281 3.065 3.065 0 01.2-.318l3.42-5.284h-3.241q-.195 0-.312-.246a1.944 1.944 0 01-.117-.788 3.985 3.985 0 01.026-.5 1.186 1.186 0 01.077-.318.328.328 0 01.328-.22h5.581a.869.869 0 01.246.031.339.339 0 01.169.113.58.58 0 01.1.225 1.519 1.519 0 01.036.359v.5a2.854 2.854 0 01-.02.364 2 2 0 01-.062.3 1.446 1.446 0 01-.118.287q-.077.144-.179.307l-3.4 5.274h3.6a.387.387 0 01.174.041.327.327 0 01.139.154 1.085 1.085 0 01.087.312 3.456 3.456 0 01.04.508z"
                    />
                    <path
                        data-name="Path 1340"
                        d="M151.77 32.518a1.445 1.445 0 01-.318 1.075 2.512 2.512 0 01-2.35.01 1.4 1.4 0 01-.3-1.034 1.48 1.48 0 01.312-1.08 2.471 2.471 0 012.35-.01 1.4 1.4 0 01.306 1.039zm-.195 12.228a.276.276 0 01-.062.179.449.449 0 01-.21.128 1.818 1.818 0 01-.394.077q-.246.025-.625.026t-.625-.026a1.823 1.823 0 01-.394-.077.448.448 0 01-.21-.128.278.278 0 01-.061-.179v-9.115a.279.279 0 01.061-.179.483.483 0 01.21-.133 1.771 1.771 0 01.394-.087 6.279 6.279 0 011.25 0 1.767 1.767 0 01.394.087.483.483 0 01.21.133.277.277 0 01.062.179z"
                    />
                    <path
                        data-name="Path 1341"
                        d="M162.032 44.746a.277.277 0 01-.061.179.447.447 0 01-.2.128 1.739 1.739 0 01-.394.077q-.251.025-.62.026t-.63-.026a1.731 1.731 0 01-.394-.077.449.449 0 01-.205-.128.279.279 0 01-.061-.179v-5.213a4.4 4.4 0 00-.1-1.044 2.067 2.067 0 00-.281-.65 1.257 1.257 0 00-.476-.42 1.484 1.484 0 00-.681-.149 1.705 1.705 0 00-.993.359 5.381 5.381 0 00-1.045 1.044v6.073a.277.277 0 01-.061.179.45.45 0 01-.21.128 1.828 1.828 0 01-.395.077q-.246.025-.625.026t-.625-.026a1.819 1.819 0 01-.394-.077.446.446 0 01-.21-.128.277.277 0 01-.062-.179v-9.135a.314.314 0 01.051-.179.374.374 0 01.184-.128 1.473 1.473 0 01.343-.077 4.566 4.566 0 01.527-.026 4.818 4.818 0 01.542.026 1.236 1.236 0 01.333.077.375.375 0 01.169.128.313.313 0 01.051.179v1.055a5.807 5.807 0 011.541-1.229 3.479 3.479 0 011.634-.41 3.616 3.616 0 011.572.307 2.752 2.752 0 011.034.835 3.4 3.4 0 01.568 1.234 7.2 7.2 0 01.174 1.7z"
                    />
                    <path
                        data-name="Path 1342"
                        d="M172.447 44.746a.277.277 0 01-.062.179.447.447 0 01-.2.128 1.74 1.74 0 01-.395.077q-.251.025-.619.026t-.63-.026a1.738 1.738 0 01-.394-.077.446.446 0 01-.2-.128.278.278 0 01-.062-.179v-5.213a4.414 4.414 0 00-.1-1.044 2.056 2.056 0 00-.282-.65 1.255 1.255 0 00-.476-.42 1.481 1.481 0 00-.681-.149 1.706 1.706 0 00-.993.359 5.381 5.381 0 00-1.044 1.044v6.073a.277.277 0 01-.062.179.449.449 0 01-.21.128 1.817 1.817 0 01-.394.077q-.246.025-.625.026t-.625-.026a1.818 1.818 0 01-.394-.077.45.45 0 01-.21-.128.278.278 0 01-.061-.179v-9.135a.315.315 0 01.051-.179.375.375 0 01.184-.128 1.476 1.476 0 01.343-.077 4.561 4.561 0 01.527-.026 4.821 4.821 0 01.543.026 1.241 1.241 0 01.333.077.377.377 0 01.169.128.313.313 0 01.051.179v1.055a5.8 5.8 0 011.541-1.229 3.479 3.479 0 011.633-.41 3.617 3.617 0 011.572.307 2.748 2.748 0 011.034.835 3.391 3.391 0 01.568 1.234 7.211 7.211 0 01.174 1.7z"
                    />
                    <path
                        data-name="Path 1343"
                        d="M179.054 45.095l-1.106 3.236a.666.666 0 01-.5.369 4.827 4.827 0 01-1.2.113 4.715 4.715 0 01-.655-.036.921.921 0 01-.369-.117.27.27 0 01-.133-.205.665.665 0 01.062-.3l1.219-3.062a.691.691 0 01-.241-.189.88.88 0 01-.159-.271l-3.144-8.4a1.736 1.736 0 01-.133-.548.374.374 0 01.123-.3.805.805 0 01.415-.148 5.762 5.762 0 01.773-.041q.44 0 .7.016a1.232 1.232 0 01.4.077.435.435 0 01.215.189 1.963 1.963 0 01.143.363l2.151 6.114h.031l1.966-6.237a.7.7 0 01.159-.353.667.667 0 01.323-.128 5.058 5.058 0 01.788-.041 5.386 5.386 0 01.732.041.876.876 0 01.43.154.37.37 0 01.138.3 1.494 1.494 0 01-.082.445z"
                    />
                    <path
                        data-name="Path 1344"
                        d="M189.5 43.288q0 .266-.015.445a2.576 2.576 0 01-.041.3.908.908 0 01-.062.2.734.734 0 01-.158.194 2.247 2.247 0 01-.42.287 4.125 4.125 0 01-.676.3 5.477 5.477 0 01-.824.215 5.075 5.075 0 01-.917.082 4.975 4.975 0 01-1.869-.328 3.509 3.509 0 01-1.367-.967 4.259 4.259 0 01-.834-1.562 7.22 7.22 0 01-.282-2.11 7.315 7.315 0 01.343-2.371 4.535 4.535 0 01.957-1.654 3.819 3.819 0 011.454-.973 5.173 5.173 0 011.843-.318 4.509 4.509 0 01.8.072 4.664 4.664 0 01.737.195 3.961 3.961 0 01.614.276 1.894 1.894 0 01.384.266 1.082 1.082 0 01.158.19.642.642 0 01.072.2 2.629 2.629 0 01.041.3q.015.179.016.435a2.374 2.374 0 01-.1.835q-.1.241-.266.241a.629.629 0 01-.369-.143q-.195-.143-.461-.317a3.458 3.458 0 00-.635-.318 2.43 2.43 0 00-.881-.143 1.751 1.751 0 00-1.536.773 4 4 0 00-.533 2.268 5.672 5.672 0 00.133 1.3 2.765 2.765 0 00.394.942 1.66 1.66 0 00.656.568 2.089 2.089 0 00.916.19 2.387 2.387 0 00.917-.159 3.713 3.713 0 00.671-.353q.287-.194.481-.353a.568.568 0 01.328-.159.231.231 0 01.154.051.364.364 0 01.1.19 2.347 2.347 0 01.056.353q.021.226.021.56z"
                    />
                    <path
                        data-name="Path 1345"
                        d="M199.25 44.746a.278.278 0 01-.061.179.448.448 0 01-.2.128 1.738 1.738 0 01-.395.077q-.25.025-.619.025t-.63-.025a1.732 1.732 0 01-.394-.077.449.449 0 01-.205-.128.279.279 0 01-.061-.179v-5.213a4.414 4.414 0 00-.1-1.044 2.069 2.069 0 00-.282-.65 1.257 1.257 0 00-.476-.42 1.483 1.483 0 00-.681-.149 1.705 1.705 0 00-.993.359 5.376 5.376 0 00-1.045 1.044v6.073a.278.278 0 01-.061.179.451.451 0 01-.21.128 1.827 1.827 0 01-.395.077q-.246.025-.625.025t-.625-.025a1.816 1.816 0 01-.394-.077.448.448 0 01-.21-.128.278.278 0 01-.061-.179V31.279a.3.3 0 01.061-.184.459.459 0 01.21-.138 1.762 1.762 0 01.394-.087 6.381 6.381 0 011.249 0 1.774 1.774 0 01.395.087.462.462 0 01.21.138.3.3 0 01.061.184v5.049a4.939 4.939 0 011.357-.978 3.368 3.368 0 011.439-.322 3.617 3.617 0 011.572.307 2.731 2.731 0 011.034.84 3.44 3.44 0 01.569 1.244 7.384 7.384 0 01.174 1.726z"
                    />
                </g>
                <path
                    data-name="Path 1346"
                    d="M87.272 40.411c0-.734-.02-1.213-.041-1.643h1.338l.051.919h.04a1.467 1.467 0 011.348-1.032 1.567 1.567 0 01.327.021v1.459a2.249 2.249 0 00-.418-.041 1 1 0 00-1.063.786 1.858 1.858 0 00-.03.347v2.528h-1.552z"
                    fill="#87a4d4"
                />
            </g>
        </svg>
    )
}

export default LogoSvg
