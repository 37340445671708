import React from 'react'
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import FacebookSvg from '../svgs/FacebookSvg';
import { FACEBOOK_LINK } from '../constants';


const FacebookComponent = ({ className = ''}) => {

	return (
		<LinkComponent external_link to={FACEBOOK_LINK} className={className}>
			<FacebookSvg />
		</LinkComponent>
	)
}


FacebookComponent.propTypes = {
	className: PropTypes.string,
}
export default React.memo(FacebookComponent);