import React from 'react'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';

const useStyles = makeStyles((theme) => ({
    subTitle: {
        marginTop: 80,
        marginBottom: 80,
        fontSize: theme.typography.h3.fontSize,
    },
    cont: {
        position: 'relative',
        zIndex: 2,
    }
}))

const PageContainerComponent = ({ children, title, intro, infoTitle}) => {
    const classes = useStyles();

    return (
        <MainContainerComponent
            title={title}
            space
        >
            <Container className={classes.cont}>
                <Grid container  >
                    <Grid item md={10} xs={12}>
                        <Typography variant='h1' className={classes.title}>{title}</Typography>
                        <Typography
                            className={classes.desc}
                            dangerouslySetInnerHTML={{ __html: intro }}
                        />
                        <Typography variant='h2' className={classes.subTitle}>{infoTitle}</Typography>
                    </Grid>
                </Grid>
                <Box>
                  
                    {children}
                </Box>
            </Container>
        </MainContainerComponent>
    )
}


PageContainerComponent.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.string,
    infoTitle: PropTypes.string,
}
export default React.memo(PageContainerComponent);