import * as React from "react"

function ClosedIconSvg(props) {
    return (
        <svg
            viewBox="0 0 53 73"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinecap="round"
            {...props}
        >
            <path
                d="M8.75 26.21l34.6-.332M8.75 36.38l34.6-.332M8.75 46.21l34.6-.332"
                fill="none"
                stroke="#444544"
                strokeWidth={3}
            />
        </svg>
    )
}

export default ClosedIconSvg
