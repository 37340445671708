import {  withStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const PaginationComponent = withStyles((theme) => ({
    root: {
        '& .Mui-selected': {
            background: 'none',
            color: theme.palette.primary.main,
        },
        '& .MuiButtonBase-root': {
            fontWeight: 900,
            background: 'none !important',
            fontSize: theme.typography.body1.fontSize
        }
    }
}))(Pagination);


export default PaginationComponent;