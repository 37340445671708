import React from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomTextFieldComponent from './CustomTextFieldComponent';

const TextFieldComponent = (
    {
        multiline,
        className = '',
        customHelperText,
        label,
        autoComplete,
        fullWidth = true,
        placeholder,
        type = 'text',
        formikProps,
        hiddenError,
        rows = 1,
        size,
        id,
        onBlur = () => { },
        onChange = () => { },
        variant = 'outlined',
        InputProps = {},
        classNameBox = '',
    }
) => {
    const {t} = useTranslation();
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
   
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <Box className={classNameBox}>
            <CustomTextFieldComponent
                autoComplete={autoComplete}
                error={customHelperText ? Boolean(customHelperText) : isError()}
                label={label}
                // margin="normal"
                size={size}
                fullWidth={fullWidth}
                multiline={multiline}
                type={type}
                placeholder={placeholder || t(`form.${id}`)}
                name={id}
                rows={rows}
                onChange={handleChange}
                onBlur={handleBlur}
                variant={variant}
                InputProps={InputProps}
                className={className}
                value={value}
                helperText={customHelperText ? customHelperText : isError() ? error : hiddenError ? '' : ' '}
            />
        </Box>
    )
}

TextFieldComponent.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    formikProps: PropTypes.object,
    InputProps: PropTypes.object,
    autoComplete: PropTypes.string,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    variant: PropTypes.string,
}

export default React.memo(TextFieldComponent);