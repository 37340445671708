import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TextSkeletonComponent from './TextSkeletonComponent';

const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        height: 400,
    },
    descCont: {
        [theme.breakpoints.down("sm")]: {
            order: 2,
        },
    },
    imgCont: {
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            order: 1,
        },
    },
    content: {
        marginTop: 20,
    },
    title: {
        width: '40%',
        height: 30,
        marginTop: 20,
        marginBottom: 20,
    },
    date: {
        width: 75,
        height: 75,
    }
}))

const NewsPostSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <section>
            <Skeleton
                variant="rect"
                className={classes.date}
            />
            <Skeleton
                variant="rect"
                className={classes.title}
            />
            <Grid container justify='space-between' >
                <Grid item md={5} xs={12} className={classes.descCont}>
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                    <TextSkeletonComponent />
                </Grid>
                <Grid item md={6} xs={12} className={classes.imgCont}>
                    <Skeleton
                        variant="rect"
                        className={classes.img}
                    />
                </Grid>
            </Grid>
        </section>
    )
}

export default React.memo(NewsPostSkeletonComponent);