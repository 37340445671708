import React, { useCallback, useEffect } from 'react'
import { Box, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import NewsCardComponent from './NewsCardComponent';
import PaginationComponent from './PaginationComponent';
import SectionsComponent from './SectionsComponent';
import { useDispatch } from 'react-redux';
import NewsCardSkeletonComponent from './skeletons/NewsCardSkeletonComponent';
import EmptyListSvg from '../svgs/EmptyListSvg';


const useStyles = makeStyles((theme) => ({
    newsBox: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paginationCont: {
        marginTop: 80,
        marginBottom: 80,
        display: 'flex',
        justifyContent: 'center',
    },
    cont: {
        position: 'relative',
        zIndex: 1,
    },
    emptyIcon: {
        width: '80%',
        height: 'auto'
    }
}))

const NewsListComponent = ({ title, data, meta, to, loading, fetchData = () => { } }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch, fetchData])
    const handlePagination = useCallback((e, page) => {
        window.scrollTo(0, 0);
        dispatch(fetchData(page));
    }, [dispatch, fetchData])
    return (
        <MainContainerComponent title={title}>
            <Container className={classes.cont}>
                <Box className={classes.newsBox}>
                    {loading
                        ? <>
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                            <NewsCardSkeletonComponent />
                        </>
                        :data?.length
                            ? data.map(({ date, title, intro, src, slug, localization }, key) =>
                                <NewsCardComponent
                                    key={key}
                                    date={date}
                                    title={title}
                                    intro={intro}
                                    localization={localization}
                                    src={src}
                                    to={`${to}/${slug}`}
                                    gray={key === 0 || (key + 1) === data.length}
                                />
                            )
                            :
                            <Box display='flex' justifyContent='center'>
                                <EmptyListSvg
                                    className={classes.emptyIcon}
                                />
                            </Box>
                    }
                </Box>
                <Box className={classes.paginationCont}>
                    {data?.length
                        ? <PaginationComponent
                            onChange={handlePagination}
                            count={meta?.last_page}

                            color='primary'
                            className={classes.pagination}
                            showFirstButton
                            showLastButton
                        />
                        : null
                    }
                </Box>
            </Container>
            <SectionsComponent
            />
        </MainContainerComponent>
    )
}


NewsListComponent.propTypes = {
    meta: PropTypes.object,
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
}
export default React.memo(NewsListComponent);