import React from 'react'
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/styles/makeStyles'
import { useMemo } from 'react';
import { useCallback } from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    rightArr: {
        padding: '5px !important',
        position: 'absolute',
        right: '10px !important',
        top: '50%',
        [theme.breakpoints.down('xs')]: {
            top: '40%',
            right: '5px !important',
        },
    },
    leftArr: {
        padding: '5px !important',
        position: 'absolute',
        left: '10px !important',
        top: '50%',
        [theme.breakpoints.down('xs')]: {
            top: '40%',
            left: '5px !important',
        },
    },
    gall: {
        '& .image-gallery-image': {

        }
    },
    dialog: {
        maxWidth: 'none',
        width: 'auto',
        '& img': {
            maxHeight: '90vh'
        }
    },
    paper: {
        background: 'rgba(0,0,0,0)',
        boxShadow: 'none',
        '& > div': {
            background: 'rgba(0,0,0,0)',
        }
    },
    carousel: {
        '& .__JnHV': {
            bottom: 0,
            top: 'none',
        }
    },
    icon: {
        fontSize: 50,
        color: theme.palette.text.white,
    },
    closeIconBtn: {
        position: 'absolute',
        right: 0,
        opacity: 0.75,
        '&:hover': {
            opacity: 1,
        }
    },
    closeIcon: {
        fontSize: 35,
        color: theme.palette.text.white,
    }
}))

const DialogGalleryComponent = ({ imgs, open, setOpen, showImgIndex }) => {
    const classes = useStyles()
    const images = useMemo(() => imgs?.length
        ? imgs.map((img) => ({
            src: img.src,
            // thumbnail: img.src,
            // originalClass: classes.gall
        }))
        : []
        , [imgs])

    const handleClose = useCallback(() => {
        setOpen(!open)
    }, [open, setOpen])
    return (
        <Dialog maxWidth={false} classes={{ root: classes.dialog, paperWidthFalse: classes.paper }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Carousel
                className={classes.carousel}
                canAutoPlay={false}
                hasThumbnails={false}
                showThumbnails={false}
                shouldMinimizeOnSwipeDown={false}
                hasThumbnailsAtMax={false}
                hasMediaButtonAtMax={false}
                hasSizeButtonAtMax={false}
                hasSizeButton={false}
                isLoop={false}
                index={showImgIndex}
                images={images}
                objectFit='contain'
                minIcon={<FullscreenExitIcon className={classes.icon} />}
                maxIcon={<FullscreenIcon className={classes.icon} />}
                leftIcon={<KeyboardArrowLeftIcon className={classes.icon} />}
                rightIcon={<KeyboardArrowRightIcon className={classes.icon} />}
                shouldLazyLoad
            />
            <IconButton
                onClick={handleClose}
                className={classes.closeIconBtn}
            >
                <CloseIcon
                    className={classes.closeIcon}
                />
            </IconButton>
        </Dialog>
    )
}


export default React.memo(DialogGalleryComponent);