import React from 'react'
import {  Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from '../constants';

const NewsHeaderComponent = ({ date, localization }) => {
    return (
        <>
            <Typography
                color='primary'
                variant='h4'
                className='post-text'
            >
                {localization
                    ? moment(date).format(DATE_FORMAT)
                    : moment(date).format('DD')
                }
            </Typography>
            <Typography
                variant='button'
                color='primary'
                className={'post-text'}
            >
                {localization
                    ? localization
                    : moment(date).format('MM.YYYY')
                }
            </Typography>
        </>
    )
}


NewsHeaderComponent.propTypes = {
    date: PropTypes.string,
    localization: PropTypes.string,
}
export default React.memo(NewsHeaderComponent);