import React, { useState } from 'react'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ContactImg from '../../imgs/contact.jpg';
import ContactIconSvg from '../../svgs/ContactIconSvg';
import LinkComponent from '../../components/LinkComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import SubmitButtonComponent from '../../components/form/SubmitButtonComponent';
import { POST } from '../../functions/superagentSending';
import { CONTACT_API } from '../../constants/api';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { RECAPTCHA_ACTION } from '../../constants';

const useStyles = makeStyles((theme) => ({
    cont: {
        position: 'relative',
        zIndex: 3,
    },
    formCont: {
        background: theme.palette.background.gray,
    },
    imgCont: {
        '& span': {
            width: '100%',
            [theme.breakpoints.down("xs")]: {
                overflow: 'hidden',
            },
        }
    },
    img: {
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down("xs")]: {
            transform: 'scale(2)',
        },
    },
    address: {
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
        },
    },
    form: {
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(3),
        },
    },
    btn: {
        paddingLeft: 60,
        paddingRight: 60,
    },
    icon: {
        width: 150,
        height: 150,
        [theme.breakpoints.down("sm")]: {
            width: 100,
            height: 100,
        },
    }
}))

const newsLetterSchema = (t) => Yup.object().shape({
    first_name: Yup.string()
        .required(t('validation.required')),
    message: Yup.string()
        .required(t('validation.required'))
        .min(10, t('validation.min', { item: 10 })),
    email: Yup.string()
        .required(t('validation.required'))
        .email(t('validation.email')),
});


const ContactPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [successSent, setSuccessSent] = useState(false);
    const [errorSent, setErrorSent] = useState(false);
    // const { executeRecaptcha } = useGoogleReCaptcha();

    const formik = useFormik({
        validationSchema: newsLetterSchema(t),
        initialValues: {
            first_name: '',
            message: '',
            email: '',
        },
        onSubmit: values => {
            setLoading(true)
            setSuccessSent(false)
            setErrorSent(false)
            // executeRecaptcha(RECAPTCHA_ACTION).then((token) => {
            //  _recaptcha: token
            POST(CONTACT_API, { ...values, })
                .then((success) => {
                    window.dataLayer.push({
                        event: 'custom_event',
                        eventProps: {
                            action: `Contact form sent`,
                        }
                    });
                    setLoading(false)
                    setSuccessSent(true)
                })
                .catch((error) => {
                    setLoading(false)
                    setErrorSent(true)
                })
            // })
        },
    });

    return (
        <MainContainerComponent
            title={t('contact_page.title')}
            space
        >
            <Container className={classes.cont}>
                <Box className={classes.formCont}>
                    <Grid container>
                        <Grid
                            item
                            md={7}
                            xs={12}
                            className={classes.address}
                        >
                            <ContactIconSvg
                                className={classes.icon}
                            />
                            <LinkComponent to={`https://maps.google.com/?q=${t('contact_page.full_address')}`} external_link>
                                <Typography
                                    variant='h1'
                                    dangerouslySetInnerHTML={{ __html: t('contact_page.address') }}
                                />
                            </LinkComponent>
                            <LinkComponent to={`telto:${t('contact_page.phone_full')}`} external_link>
                                <Typography
                                    component='p'
                                    variant='h1'
                                    dangerouslySetInnerHTML={{ __html: t('contact_page.phone') }}
                                />
                            </LinkComponent>
                            <LinkComponent to={`telto:${t('contact_page.second_phone_full')}`} external_link>
                                <Typography
                                    component='p'
                                    variant='h1'
                                    dangerouslySetInnerHTML={{ __html: t('contact_page.second_phone') }}
                                />
                            </LinkComponent>
                        </Grid>
                        <Grid
                            item
                            md={5}
                            xs={12}
                            className={classes.form}
                        >
                            <form className={classes.formCont} onSubmit={formik.handleSubmit}>
                                <TextFieldComponent
                                    fullWidth
                                    autoComplete='given-name'
                                    formikProps={formik}
                                    id='first_name'
                                />
                                <TextFieldComponent
                                    type='email'
                                    fullWidth
                                    autoComplete='email'
                                    formikProps={formik}
                                    id='email'
                                />
                                <TextFieldComponent
                                    multiline
                                    rows={5}
                                    fullWidth
                                    formikProps={formik}
                                    id='message'
                                />
                                <Box display='flex' justifyContent='flex-end'>
                                    <SubmitButtonComponent
                                        title={t('btns.send')}
                                        className={classes.btn}
                                        loading={loading}
                                        error={errorSent}
                                        success={successSent}
                                    />
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.imgCont}>
                    <LinkComponent external_link to={`https://maps.google.com/?q=${t('contact_page.full_address')}`}>
                        <LazyLoadImage
                            alt={t('contact_page.title')}
                            src={ContactImg}
                            className={classes.img}
                            effect='blur'
                        />
                    </LinkComponent>
                </Box>
            </Container>
        </MainContainerComponent>
    )
}


ContactPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(ContactPage);