import React, { useEffect } from 'react'
import { Container, Grid, makeStyles, Typography, useMediaQuery, useTheme, } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import NewsHeaderComponent from './NewsHeaderComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ARTICLE_CSS } from '../constants/csses';
import GalleryListComponent from './GalleryListComponent';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import NewsPostSkeletonComponent from './skeletons/NewsPostSkeletonComponent';

const useStyles = makeStyles((theme) => ({
    title: {
        width: '33.333333%',
        marginTop: 20,
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
    },
    cont: {
        position: 'relative',
        zIndex: 2,
    },
    descCont: {
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.down("sm")]: {
            order: 2,
        },
    },
    imgCont: {
        marginBottom: 20,
        '& span': {
            width: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            display: 'none',
        },
        [theme.breakpoints.down("sm")]: {
            order: 1,
        },
    },
    img: {
        width: '100%',
        OObjectFit: 'cover',
    },
    desc: {
        ...ARTICLE_CSS(theme),
    }
}))

const NewsPostComponent = ({ desc, gallery, title, src, mobileSrc, loading, breadcrumbsTitle, localization, date, fetchFun = () => { } }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { slug } = useParams();
    useEffect(() => {
        if (slug)
            dispatch(fetchFun(slug))
    }, [dispatch, fetchFun, slug])

    return (
        <MainContainerComponent title={breadcrumbsTitle || title} space>
            <Container className={classes.cont}>
                {loading
                    ? <NewsPostSkeletonComponent />
                    : <>
                        {date || localization
                            ? <NewsHeaderComponent
                                date={date}
                                localization={localization}
                            />
                            : null
                        }
                        <Typography variant='h1' className={classes.title}>{title}</Typography>
                        <Grid container justify='space-between' >
                            <Grid item md={5} xs={12} className={classes.descCont}>
                                <Typography
                                    className={classes.desc}
                                    dangerouslySetInnerHTML={{ __html: desc }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.imgCont}>
                                <LazyLoadImage
                                    src={matchSm ? mobileSrc : src}
                                    alt={title}
                                    effect='blur'
                                    className={classes.img}
                                />
                            </Grid>
                        </Grid>
                        <GalleryListComponent
                            gallery={gallery}
                        />
                    </>
                }
            </Container>

        </MainContainerComponent>
    )
}


NewsPostComponent.propTypes = {
    title: PropTypes.string,
    loading: PropTypes.bool,
    gallery: PropTypes.array,
}
export default React.memo(NewsPostComponent);