import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NewsListComponent from '../../components/NewsListComponent';
import { EVENTS_ROUTE } from '../../constants/routes';
import { FETCH_EVENTS_DATA_REDUCER } from '../../reducers/EventsReducer';

const EventsPage = (props) => {
    const { meta, data, loading} = useSelector(state => state.EventsReducer);
    const { t } = useTranslation();
    return (
        <NewsListComponent
            data={data}
            meta={meta}
            to={EVENTS_ROUTE}
            loading={loading}
            fetchData={FETCH_EVENTS_DATA_REDUCER}
            title={t('events_page.title')}
        />
    )
}

export default React.memo(EventsPage);