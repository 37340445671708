import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ARTICLE_CSS } from '../constants/csses';

const useStyles = (hasTitle) => makeStyles((theme) => ({
    icon: {
        width: 190,
        height: 190,
        // [theme.breakpoints.down("sm")]: {
        //     width: 100,
        //     height: 100,
        // },
    },
    title: {
        marginTop: 48,
        fontSize: theme.typography.h3.fontSize,
        [theme.breakpoints.down("sm")]: {
            marginTop: 20,
        },
    },
    infoCont: {
        paddingRight: 40,
        display: 'flex',
        alignItems: hasTitle ? 'flex-start' : 'center',
        [theme.breakpoints.down("md")]: {
            paddingRight: 0,
        },
        [theme.breakpoints.down("sm")]: {
            alignItems: 'flex-start',
            paddingRight: 0,
            flexDirection: 'column',
        },
    },
    desc: {
        ...ARTICLE_CSS(theme),
    },
    content: {

        marginLeft: 20,
    },
}))

const InfoComponent = ({ title, desc, Icon, md = 12, containerClassName = '' }) => {
    const classes = useStyles(Boolean(title))();

    return (
        <Grid
            item
            md={md}
            sm={8}
            xs={12}
            className={classes.infoCont}
            data-aos="fade-up"
        >
            <Box>
                <Icon
                    className={classes.icon}
                />
            </Box>
            <Box className={classes.content}>
                <Typography
                    variant='h2'
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography
                    className={classes.desc}
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
            </Box>
        </Grid>
    )
}


InfoComponent.propTypes = {
    Icon: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
}
export default React.memo(InfoComponent);