export const API_URL = process.env.NODE_ENV === 'production' ? '' : '';



export const DATE_FORMAT = 'DD.MM.YYYY'

// LINKS

export const FACEBOOK_LINK = 'https://m.facebook.com/Centrum-Inicjatyw-Rodzinnych-w-Szczecinie-105614251756095/'
export const SOFINE_LINK = 'https://sofine.pl/'
export const FRIENDLY_LINK = 'http://przyjaznyrodzinie.szczecin.pl/chapter_117000.asp'

export const RECAPTCHA_ACTION = 'contact_form'