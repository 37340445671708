import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';

const useStyles = (transform) => makeStyles((theme) => ({
    background: {
        height: 400,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        '& path:first-of-type': {
            animation: `$backgroundAnim 4.5s ease-in-out infinite`,
        }
    },
    grid: {
        position: 'relative',
        marginBottom: 20,
        marginTop: 20,
    },
    content: {
        height: 400,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1,

    },
    title: {
        marginTop: 20,
        marginBottom: 20,
    },
    icon: {
        [theme.breakpoints.down("xs")]: {
            height: 'auto',
            width: '80%',
        },
        
    },
    "@keyframes backgroundAnim": {
        "0%": {
            transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        },
        "50%": {
            transform: transform,
            // transform: "translateY(-5px)"
        },
        "100%": {
            transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
        }
    },
    // "@-webkit-keyframes backgroundAnim": {
    //     "0%": {
    //         transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
    //     },
    //     "50%": {
    //         transform: transform,
    //         // transform: "translateY(-5px)"
    //     },
    //     "100%": {
    //         transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)"
    //     }
    // },
}))

const SectionComponent = ({ transform, Background, to, Icon, title, external_link }) => {
    const classes = useStyles(transform)();

    return (
        <Grid item md={6} xs={12} className={classes.grid}>
            <Background className={classes.background} />
            <LinkComponent to={to} external_link={external_link}>
                <Box className={classes.content}>
                    <Icon
                        className={classes.icon}
                    />
                    <Typography variant='h2' className={classes.title}>{title}</Typography>
                </Box>
            </LinkComponent>
        </Grid>
    )
}


SectionComponent.propTypes = {
    title: PropTypes.string,
}
export default React.memo(SectionComponent);