
import TextField from '@material-ui/core/TextField'
import withStyles from '@material-ui/styles/withStyles'

const CustomTextFieldComponent = withStyles((theme) => ({
    root: {
        marginTop: 0,
        '& input': {
            fontWeight: 900,
            padding: '15px 20px',
            border: `2px solid ${theme.palette.primary.main}`,
            "&::placeholder": {
                fontWeight: 'lighter',
                opacity: 1,
             
                color: theme.palette.primary.main,
            },
        },
        '& textarea': {
            fontWeight: 900,
            "&::placeholder": {
                fontWeight: 'lighter',
                opacity: 1,
                color: theme.palette.primary.main,
            },
        },
        '& fieldset': {
            border: `2px solid ${theme.palette.primary.main}`,
        },
        '&>div': {
            borderRadius: 0,
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.main,
        },
    },
}))(TextField);


export default CustomTextFieldComponent;