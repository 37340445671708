import React from 'react'
import { Card, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { SQUARE_CSS } from '../../constants/csses';

const useStyles = makeStyles((theme) => ({
    imgCont: {
        padding: theme.spacing(2),
    },
    card: {
        boxShadow: 'none',
        ...SQUARE_CSS,
    },
    skeleton: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    }
}))

const PartnerSkeletonComponent = (props) => {
    const classes = useStyles();

    return (
        <Grid
            item
            md={2}
            sm={3}
            xs={6}
            className={classes.imgCont}
        >
            <Card
                className={classes.card}
            >
                <Skeleton
                    variant='rect'
                    className={classes.skeleton}
                />
            </Card>
        </Grid>
    )
}


PartnerSkeletonComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(PartnerSkeletonComponent);