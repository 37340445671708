import React, { useEffect } from 'react'
import {  Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinkComponent from '../../components/LinkComponent';
import { MEET_ROUTE } from '../../constants/routes';
import CustomContainerComponent from '../../components/CustomContainerComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PARTNERS_DATA_REDUCER } from '../../reducers/PartnersReducer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PartnerSkeletonComponent from '../../components/skeletons/PartnerSkeletonComponent';

const useStyles = makeStyles((theme) => ({
    img: {
        width: '100%',
        cursor: 'pointer'
    },
    imgCont: {
        padding: theme.spacing(2),
        '& span': {
            width: '100%',
        }
    },
    imgs: {
        marginTop: 80,
        marginBottom: 80,
        [theme.breakpoints.down("sm")]: {
            marginTop: 40,
            marginBottom: 40,
        },
    }
}))

const PartnersPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data, loading } = useSelector(state => state.PartnersReducer);
    const dispatch = useDispatch();

    useEffect(() => {
           dispatch(FETCH_PARTNERS_DATA_REDUCER()) 
    }, [dispatch])

    return (
        <CustomContainerComponent
            title={t('partners_page.title')}
            desc={t('partners_page.desc')}
        >
            <LinkComponent to={MEET_ROUTE}>
                <Typography component='span'>
                    {t('partners_page.link')}
                </Typography>
            </LinkComponent>
            <Grid container className={classes.imgs}>
                {loading
                    ? <>
                    <PartnerSkeletonComponent />
                    <PartnerSkeletonComponent />
                    <PartnerSkeletonComponent />
                    <PartnerSkeletonComponent />
                    </>
                    : data?.length
                        ? data.map(({ to, name, src, }, key) =>
                            <Grid
                                item
                                md={2}
                                sm={3}
                                xs={6}
                                key={key}
                                className={classes.imgCont}
                            >
                                <LinkComponent external_link to={to}>
                                    <LazyLoadImage
                                        effect='blur'
                                        src={src}
                                        className={classes.img}
                                        alt={name}
                                    />
                                </LinkComponent>
                            </Grid>
                        )
                        : null
                }
            </Grid>
        </CustomContainerComponent>
    )
}

export default React.memo(PartnersPage);