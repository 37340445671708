import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NewsListComponent from '../../components/NewsListComponent';
import { NEWS_ROUTE } from '../../constants/routes';
import { FETCH_NEWS_DATA_REDUCER } from '../../reducers/NewsReducer';

const NewsPage = (props) => {
    const { meta, data, loading} = useSelector(state => state.NewsReducer);
    const { t } = useTranslation();
    return (
        <NewsListComponent
            data={data}
            meta={meta}
            loading={loading}
            to={NEWS_ROUTE} 
            fetchData={FETCH_NEWS_DATA_REDUCER}
            title={t('news_page.title')}
        />
    )
}

export default React.memo(NewsPage);