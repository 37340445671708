import * as React from "react"

function EventsIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={326.114}
            height={261.998}
            viewBox="0 0 326.114 261.998"
            {...props}
        >
            <g data-name="Awards">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 70">
                    <path
                        d="M192.627 118.476c-.154 3.9-4.089 6.757-5.642 7.739a.565.565 0 00-.095.874 7.8 7.8 0 012.38 5.422.565.565 0 00.825.5c2.574-1.331 9.769-5.806 9.381-14.1a.568.568 0 00-.837-.468 4.1 4.1 0 01-3.033.47 9.235 9.235 0 01-2.128-.924.566.566 0 00-.851.487z"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 683"
                        d="M193.218 117.308c1.018.043 1.93 1.1 3.2 1.131.9.022 1.666-.684 2.512-.664 1.793.042 1.328 3.732.347 6.405q-.234.638-.531 1.25a18.737 18.737 0 01-8.382 8.123c-.381.189-.125.288-.847.088-.9-.251-.794-1.519-1.025-2.518a9.01 9.01 0 00-2.11-3.685 1.286 1.286 0 01.5-1.879q.581-.383 1.131-.81c2.039-1.589 3.857-3.626 4.006-6.335a.961.961 0 01.121-.447 1.115 1.115 0 011.078-.659zm0 1.292c-.206 3.526-3.135 6.335-5.842 8.077 0 0 2.38 2.758 2.486 5.641v.126c5.065-2.642 9.218-7.375 9-13.407a6.727 6.727 0 01-3.975.287c-.751-.271-1.651-.937-1.664-.724z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 684"
                        d="M32.142 83.571c-.61-3.851 2.69-7.426 4.022-8.692a.564.564 0 00-.078-.876 7.8 7.8 0 01-3.393-4.853.565.565 0 00-.907-.331c-2.265 1.808-8.446 7.6-6.446 15.658a.568.568 0 00.912.3 4.108 4.108 0 012.883-1.054 9.285 9.285 0 012.268.491.566.566 0 00.739-.639"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 685"
                        d="M32.188 68.091c.948.056 1.106 1.294 1.5 2.2a9.028 9.028 0 002.82 3.271 1.288 1.288 0 01-.121 1.939q-.5.49-.952 1.015c-1.692 1.96-3.063 4.366-2.691 7a.878.878 0 01.006.235 1.25 1.25 0 01-1.751.961 8.674 8.674 0 00-1.428-.33c-2.014-.294-4.248 2.661-4.869.045a13.729 13.729 0 01.357-7.43 19.238 19.238 0 016.349-8.653c.433-.331.382-.266.78-.253zm-.129 1.288a21.436 21.436 0 00-2.313 2.191c-3.045 3.355-4.954 7.974-3.84 12.673a6.659 6.659 0 013.843-1.059 15.1 15.1 0 011.788.431c-.01-.065-.018-.131-.027-.2-.387-3.341 1.781-6.6 4.171-8.914 0 0-2.878-2.258-3.541-5.046.001.002.039-.174-.081-.078z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 686"
                        d="M152.375 13.533c1.413-3.633 6.065-5.059 7.853-5.481a.564.564 0 00.374-.8 7.8 7.8 0 01-.486-5.9.565.565 0 00-.616-.743c-2.867.421-11.126 2.311-13.457 10.277a.568.568 0 00.64.715 4.1 4.1 0 013.021.542 9.29 9.29 0 011.712 1.566.566.566 0 00.96-.18"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 687"
                        d="M159.609.001c.826.03 1.26.893.986 1.86a8.448 8.448 0 00.6 5.23 1.29 1.29 0 01-1.082 1.615c-.449.115-.894.247-1.333.4-2.447.84-4.825 2.173-5.849 4.687a.867.867 0 01-.113.207 1.244 1.244 0 01-2-.052 8.769 8.769 0 00-1.068-1c-1.436-1.145-4.082-.082-4.321-1.726a3.809 3.809 0 01.489-1.8c2.132-5.294 7.974-8.566 13.5-9.4a.892.892 0 01.191-.021zm-.155 1.232a21.446 21.446 0 00-3.1.727c-4.323 1.365-8.3 4.4-9.7 9.012a6.718 6.718 0 013.852 1.022 15.039 15.039 0 011.324 1.274q.037-.092.077-.183c1.349-3.081 4.866-4.807 8.094-5.6 0 0-1.353-3.382-.516-6.142.001-.001.121-.133-.031-.111z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 688"
                        d="M288.129 168.314c-.154 3.9-4.089 6.757-5.642 7.738a.564.564 0 00-.095.874 7.8 7.8 0 012.38 5.422.564.564 0 00.824.5c2.574-1.331 9.769-5.806 9.381-14.1a.568.568 0 00-.837-.467 4.107 4.107 0 01-3.033.47 9.258 9.258 0 01-2.128-.924.566.566 0 00-.85.487z"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 689"
                        d="M294.475 167.618c1.822.128 1.278 3.768.31 6.4q-.234.638-.531 1.249a18.719 18.719 0 01-8.381 8.123.85.85 0 01-.178.074 1.162 1.162 0 01-.34.057 1.3 1.3 0 01-1.193-1.381 8.435 8.435 0 00-2.27-4.866 1.29 1.29 0 01.5-1.878c.387-.255.766-.524 1.132-.81 2.042-1.591 3.856-3.681 4.006-6.336a.863.863 0 01.04-.233 1.245 1.245 0 011.905-.6 8.675 8.675 0 001.337.6 4.239 4.239 0 003.154-.319 1.009 1.009 0 01.509-.08zm-5.745.825c-.206 3.526-3.134 6.334-5.841 8.077 0 0 2.407 2.814 2.488 5.7v.061c5.009-2.612 9.222-7.273 9-13.406a6.727 6.727 0 01-3.975.287c-.761-.266-1.661-.933-1.672-.719z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 690"
                        d="M109.313 219.519c-3.814.808-7.555-2.3-8.888-3.567a.564.564 0 00-.87.123 7.8 7.8 0 01-4.671 3.639.565.565 0 00-.284.923c1.923 2.168 8.029 8.042 15.97 5.628a.568.568 0 00.248-.927 4.106 4.106 0 01-1.2-2.825 9.278 9.278 0 01.373-2.29.566.566 0 00-.676-.7"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 691"
                        d="M100.066 215.194c.749.024 1.317.84 2.036 1.4 2.043 1.587 4.464 2.848 7.125 2.326a.865.865 0 01.235-.019 1.244 1.244 0 011.05 1.7 8.626 8.626 0 00-.255 1.444c-.12 1.285.98 2.237 1.279 3.383.454 1.739-3.3 2.239-6.116 1.946q-.676-.07-1.342-.208a18.721 18.721 0 01-9.934-6.128c-.276-.321-.288-.086-.294-.763-.008-.938 1.27-1.176 2.19-1.649a9.03 9.03 0 003.053-2.95 1.213 1.213 0 01.973-.482zm-.032 1.259a8.676 8.676 0 01-4.876 3.816l-.06.016c3.753 4.2 9.317 7.152 15.208 5.428a6.658 6.658 0 01-1.255-3.783 15.028 15.028 0 01.337-1.807l-.195.036c-3.318.56-6.687-1.437-9.118-3.7 0-.004-.011-.054-.041-.006z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 692"
                        d="M286.956 64.332c-3.813.808-7.555-2.3-8.888-3.567a.564.564 0 00-.87.123 7.8 7.8 0 01-4.671 3.64.565.565 0 00-.283.923c1.922 2.168 8.029 8.042 15.97 5.628a.568.568 0 00.248-.927 4.106 4.106 0 01-1.2-2.825 9.256 9.256 0 01.373-2.291.566.566 0 00-.677-.7"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 693"
                        d="M277.708 60.006c.747.024 1.316.84 2.036 1.4 2.043 1.587 4.461 2.846 7.125 2.326a.879.879 0 01.234-.019 1.249 1.249 0 011.051 1.7 8.639 8.639 0 00-.255 1.444 5.13 5.13 0 001.149 3.074 1.238 1.238 0 01-.853 1.786 13.734 13.734 0 01-7.438.028 19.269 19.269 0 01-8.97-5.894c-.275-.321-.287-.082-.293-.763-.008-.952 1.269-1.176 2.19-1.649a9.012 9.012 0 003.053-2.95 1.211 1.211 0 01.971-.483zm-.032 1.259a8.7 8.7 0 01-4.814 3.8l-.121.033c3.8 4.257 9.415 7.122 15.207 5.428a6.774 6.774 0 01-1.255-3.782 14.929 14.929 0 01.338-1.808l-.2.036c-3.318.559-6.687-1.437-9.118-3.7.005-.01-.006-.055-.037-.01z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 694"
                        d="M244.65 217.461c-.61-3.85 2.69-7.426 4.022-8.692a.564.564 0 00-.078-.876 7.8 7.8 0 01-3.394-4.852.565.565 0 00-.907-.331c-2.264 1.808-8.446 7.6-6.446 15.658a.568.568 0 00.913.3 4.1 4.1 0 012.883-1.054 9.267 9.267 0 012.268.491.566.566 0 00.739-.639"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 695"
                        d="M244.692 201.98c.916.054 1.1 1.244 1.473 2.134a8.911 8.911 0 002.849 3.339 1.29 1.29 0 01-.12 1.941c-.33.326-.648.663-.951 1.015-1.693 1.962-3.064 4.366-2.691 7a.879.879 0 01.006.235 1.245 1.245 0 01-1.751.961 8.667 8.667 0 00-1.429-.33c-2.023-.3-4.25 2.655-4.869.045a13.733 13.733 0 01.357-7.43 19.278 19.278 0 016.349-8.653c.427-.333.382-.267.777-.257zm-.129 1.287a21.411 21.411 0 00-2.312 2.191c-3.045 3.355-4.954 7.974-3.84 12.672a6.777 6.777 0 013.843-1.058 15.025 15.025 0 011.788.431 5.393 5.393 0 01-.027-.2c-.388-3.342 1.782-6.6 4.171-8.914 0 0-2.873-2.24-3.541-5.045-.001.004.038-.174-.082-.074z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 696"
                        d="M80.063 29.325c-.155 3.9-4.089 6.757-5.642 7.739a.564.564 0 00-.095.874 7.8 7.8 0 012.38 5.422.564.564 0 00.824.5c2.574-1.331 9.769-5.806 9.381-14.1a.567.567 0 00-.837-.468 4.106 4.106 0 01-3.034.47 9.264 9.264 0 01-2.129-.924.566.566 0 00-.848.487z"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 697"
                        d="M80.659 28.158c1.018.043 1.931 1.1 3.2 1.131.872.022 1.579-.657 2.476-.665 2.343-.018.971 5.774-.611 8.533a19.275 19.275 0 01-7.918 7.249c-.378.188-.149.259-.811.1-.891-.218-.828-1.472-1.043-2.454a9.028 9.028 0 00-2.126-3.759 1.288 1.288 0 01.5-1.879q.581-.383 1.131-.809c2.045-1.594 3.859-3.685 4.006-6.335a.968.968 0 01.121-.447 1.114 1.114 0 011.075-.665zm0 1.293c-.207 3.526-3.135 6.335-5.842 8.077 0 0 2.38 2.758 2.487 5.64v.126c5.018-2.618 9.222-7.252 9-13.406a6.727 6.727 0 01-3.975.287c-.755-.269-1.654-.938-1.665-.725z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 698"
                        d="M42.239 170.175c-.154 3.9-4.089 6.757-5.642 7.738a.564.564 0 00-.094.874 7.8 7.8 0 012.38 5.422.565.565 0 00.825.5c2.574-1.331 9.768-5.806 9.38-14.1a.567.567 0 00-.837-.468 4.106 4.106 0 01-3.034.47 9.272 9.272 0 01-2.128-.924.566.566 0 00-.85.488z"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 699"
                        d="M42.835 169.006c1.016.043 1.94 1.1 3.2 1.131.888.022 1.626-.685 2.513-.664 1.783.041 1.328 3.732.346 6.405q-.234.638-.531 1.25a18.734 18.734 0 01-8.381 8.123c-.38.188-.125.288-.846.088-.874-.243-.8-1.476-1.008-2.445a9.034 9.034 0 00-2.126-3.759 1.286 1.286 0 01.5-1.879q.581-.383 1.132-.81c2.042-1.592 3.858-3.684 4.005-6.335a.96.96 0 01.122-.447 1.114 1.114 0 011.074-.658zm0 1.293c-.207 3.526-3.134 6.336-5.842 8.077 0 0 2.408 2.814 2.488 5.7v.061c5-2.606 9.222-7.273 9-13.407a6.775 6.775 0 01-3.975.287c-.756-.265-1.656-.932-1.666-.718z"
                        fill="#dcd6e3"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 700"
                        d="M243.582 27.358c-3.09 2.376-7.808 1.187-9.557.622a.565.565 0 00-.732.487 7.8 7.8 0 01-2.64 5.3.564.564 0 00.143.954c2.67 1.125 10.715 3.785 16.834-1.823a.568.568 0 00-.177-.942 4.105 4.105 0 01-2.3-2.029 9.283 9.283 0 01-.653-2.226.566.566 0 00-.915-.344"
                        fill="#fff"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 701"
                        d="M243.998 26.629c1.444.089 1.223 2.583 2.045 3.62.555.7 1.58.827 2.12 1.5 1.113 1.4-2.053 3.423-4.7 4.368-.426.152-.86.283-1.3.392a18.73 18.73 0 01-11.606-1.235c-.388-.171-.3.089-.609-.594-.373-.826.623-1.557 1.232-2.34a9.03 9.03 0 001.525-4.041 1.29 1.29 0 011.762-.82c.444.134.893.252 1.346.35 2.531.549 5.3.6 7.431-.98.439-.298.43-.23.754-.22zm-.126 1.27c-2.84 2.1-6.873 1.649-9.945.686 0 0-.595 3.6-2.739 5.521l-.094.083c5.237 2.184 11.567 2.357 16.06-1.674a6.719 6.719 0 01-2.766-2.869c-.28-.758-.341-1.876-.516-1.747z"
                        fill="#dcd6e3"
                    />
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 66">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 702"
                            d="M237.117 159.315c-18.97 7.522-46 15.215-76.369 14.664-49.72-.9-86.794-20.831-96.783-26.783a3.513 3.513 0 01-1.4-4.478l2.756-6.046 8.682-19.05.005.005c.27.192 9.6 6.8 26.518 13.16q2.39.9 4.992 1.8a190.29 190.29 0 0056.108 9.948 164.473 164.473 0 0059.678-9.372c.141-.047.275-.093.415-.145.513-.177 1.028-.369 1.541-.56.218-.078.436-.161.649-.244l.575-.218c.566-.208 1.121-.426 1.666-.638l.2-.078c15.163-5.942 24.442-12.247 26.149-13.446.192-.135.285-.208.285-.208l11.256 25.06a3.513 3.513 0 01-1.448 4.489 189.746 189.746 0 01-18.925 9.423"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 703"
                            d="M74.242 117.086s2.281 1.519 4.126 2.613a154.655 154.655 0 0039.32 15.959c32.751 8.691 67.841 8.747 100.118-1.918 12.458-4.117 23.292-8.933 33.53-15.822.443-.3 1.084-.755 1.084-.755a.608.608 0 01.921.232c3.759 8.368 7.557 16.719 11.276 25.1a4.229 4.229 0 01-1.675 5.193 177.809 177.809 0 01-18.194 9.114c-.018.008-.818.359-.834.364-.531.19-.662-.119-.662-.119s-.469-.151-.016-.856c0 0 2.692-1.223 4.959-2.273 4.806-2.228 9.532-4.584 14.13-7.272a3 3 0 001.167-3.7l-10.953-24.385q-.262.18-.526.358a130.513 130.513 0 01-27.309 13.658c-.991.375-1.99.749-2.986 1.1l-.931.325c-37.438 13.021-79.741 11.882-117.387-1.5-10.146-3.607-20.133-7.952-29.076-13.911l-.064-.043c-3.72 8.164-7.583 16.26-11.166 24.5a3 3 0 001.122 3.607c11.286 6.963 23.638 12.023 36.224 16.219 24.117 8.04 49.849 11.817 74.926 10.159a204.928 204.928 0 0061.53-14.265l.463.008s.492-.023.315.8l-.333.323c-28.722 11.353-60.04 16.74-90.838 13.926a202.606 202.606 0 01-78.962-23.866q-1.3-.715-2.584-1.458c-2.2-1.274-4.075-3.4-2.958-5.969.913-2.036 1.85-4.061 2.776-6.091l8.682-19.05s.09-.449.785-.305z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 704"
                            d="M110 122.215l-4.7 10.369q-2.6-.888-4.992-1.8c-16.923-6.362-26.248-12.968-26.518-13.16l-.005-.005z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 705"
                            d="M73.859 117.021l36.217 4.593s.677.306.476.851l-4.7 10.369c-.43.788-1.067.215-1.719-.012-10.667-3.73-21.3-8.208-30.532-14.587a.919.919 0 01-.386-.407.622.622 0 01.644-.807zm2.594 1.628a141.988 141.988 0 0028.4 13.14l.124.043 4.135-9.119-32.812-4.161z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 706"
                            d="M109.999 122.215l-36.217-4.593-8.682 19.05-26.119-9.878a1.019 1.019 0 01-.059-1.881l16.977-7.675a1.291 1.291 0 00.678-1.628l-6.346-17.018a1.019 1.019 0 011.355-1.293z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 707"
                            d="M51.217 96.608a1.716 1.716 0 01.6.132l58.423 24.917a.615.615 0 01-.314 1.158l-35.774-4.537-8.5 18.644a.814.814 0 01-.766.315c-8.716-3.3-17.463-6.515-26.149-9.89a1.669 1.669 0 01-.094-2.975c5.655-2.621 11.457-4.93 17.039-7.7a.708.708 0 00.34-.817c-1.917-5.766-4.3-11.368-6.368-17.081a1.691 1.691 0 011.563-2.166zm-.04 1.211a.431.431 0 00-.38.551c2.065 5.7 4.234 11.353 6.351 17.03a1.96 1.96 0 01-.955 2.369c-5.65 2.639-11.493 4.871-17.046 7.708-.36.192.052.751.052.751l25.586 9.677 8.447-18.534a.668.668 0 01.628-.35l31.906 4.046c-18.147-7.741-35.954-16.333-54.442-23.222a.44.44 0 00-.118-.025z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 708"
                            d="M252.503 117.83c-1.708 1.2-10.986 7.5-26.15 13.446-.062.026-.13.052-.2.078-.535.208-1.095.42-1.666.638l-.576.218c-.213.083-.431.166-.649.244q-.762.28-1.541.56c-.14.052-.275.1-.415.145l-4.79-10.768z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 709"
                            d="M252.604 117.233c.571.142.771.734-.168 1.38a131.772 131.772 0 01-27.731 13.946 19.027 19.027 0 01-3.288 1.2.549.549 0 01-.661-.35l-4.789-10.768a.634.634 0 01.477-.847l35.984-4.562a1.266 1.266 0 01.176.001zm-35.2 5.657l4.231 9.512a141.678 141.678 0 0028.35-13.62l.044-.029z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 710"
                            d="M216.52 122.394l36.217-4.593 8.682 19.05 26.119-9.878a1.019 1.019 0 00.06-1.881l-16.979-7.676a1.291 1.291 0 01-.678-1.628l6.346-17.018a1.019 1.019 0 00-1.355-1.293z"
                            fill="#fff"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 711"
                            d="M275.363 96.783a1.672 1.672 0 011.5 2.165c-2.068 5.714-4.452 11.315-6.368 17.081a.708.708 0 00.34.817c5.582 2.774 11.384 5.083 17.039 7.7a1.662 1.662 0 01-.095 2.975l-26.149 9.89a.815.815 0 01-.766-.315l-8.5-18.644-35.775 4.537s-.46-.019-.617-.327a.636.636 0 01.3-.831c19.481-8.31 38.883-16.807 58.443-24.929a1.7 1.7 0 01.648-.119zm-.034 1.212a.447.447 0 00-.161.037l-54.413 23.21 31.906-4.046a.843.843 0 01.628.35l8.443 18.537c8.538-3.229 17.239-6.058 25.615-9.688a.428.428 0 00.007-.748c-5.639-2.614-11.327-5.121-16.991-7.681a1.957 1.957 0 01-1.008-2.347c2.058-5.72 4.52-11.3 6.371-17.088a.428.428 0 00-.394-.534z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 712"
                            d="M104.056 143.137a.652.652 0 01.423.212l5.983 7.039c.385.484-.347 1.418-.923.785l-5.983-7.038c-.677-.852.065-1.011.5-.998z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 713"
                            d="M108.443 143.397c.642.03 1.331 1.227 2.032 2.082.358.469-.344 1.4-.923.784-.953-1.083-2.316-2.306-1.414-2.793a.506.506 0 01.305-.073z"
                            fill="#dcd6e3"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 714"
                            d="M102.746 146.527a.641.641 0 01.408.19c1.177 1.316 2.288 2.69 3.431 4.035.386.478-.331 1.385-.9.808-1.177-1.316-2.288-2.69-3.431-4.035a.61.61 0 01.492-.998z"
                            fill="#dcd6e3"
                        />
                    </g>
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 69">
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 67">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 715"
                                d="M206.183 56.166c-.07 2.985-.255 5.921-.55 8.793-.036.384-.081.763-.121 1.143-.036.279-.065.549-.1.824-.1.8-.2 1.583-.319 2.367-.049.359-.1.711-.158 1.066a73.48 73.48 0 01-.351 2.116c-.057.351-.121.7-.19 1.05-.1.545-.206 1.09-.314 1.628a.052.052 0 01-.009.032 82.24 82.24 0 01-.283 1.361l-.089.4c-.081.355-.161.7-.246 1.05a22.949 22.949 0 01-.238 1c-.1.42-.2.836-.311 1.252q-.757 2.908-1.668 5.67c-.146.46-.3.917-.465 1.373-.141.416-.29.836-.44 1.248-.016.056-.04.1-.056.157-.15.4-.3.8-.456 1.2a3.083 3.083 0 01-.049.137c-.133.331-.263.667-.4 1-.087.23-.182.453-.281.678-.07.187-.147.372-.226.555s-.154.385-.243.575c-.145.343-.291.679-.444 1.018-.173.388-.343.776-.521 1.155-.182.4-.367.784-.557 1.167-.134.279-.27.557-.412.832-.061.121-.126.238-.182.36a70.827 70.827 0 01-1.607 2.973c-.17.3-.344.594-.514.885-.073.125-.15.25-.231.379-.166.287-.34.566-.513.844s-.332.529-.494.788c-.235.359-.465.715-.7 1.066-.032.049-.065.1-.1.15-.2.3-.4.6-.611.893s-.413.586-.628.872c-.032.044-.069.1-.105.141-.242.339-.493.666-.74.994-6 7.8-13.321 13.054-21.349 14.763v26.997h-11.316v-26.987c-8.006-1.708-15.323-6.967-21.3-14.766a64.132 64.132 0 01-6.234-9.984 88.336 88.336 0 01-7.89-23.857q-.781-4.18-1.235-8.566c-.295-2.872-.48-5.808-.546-8.793a2.491 2.491 0 012.489-2.547h80.811a2.5 2.5 0 012.492 2.548z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 716"
                                d="M204.102 52.696a3.61 3.61 0 013.489 3.535c-.055 3.323-.308 6.649-.668 9.924l-.09.768c-.326 2.8-.793 5.582-1.341 8.314 0 0-.465 2.148-.737 3.34a91.374 91.374 0 01-3.121 10.376c-.017.048-.035.1-.053.145a66.576 66.576 0 01-.527 1.389 77.6 77.6 0 01-3.2 7.174c-.249.476-.3.588-.435.845q-.918 1.741-1.925 3.434c-.17.28-.063.1-.3.5-.381.628-.9 1.444-.9 1.444-5.623 8.551-13.877 16.635-24.266 19.145v26.179c-.072.818-.263.944-1.019 1.01h-11.497c-.826-.071-.953-.261-1.019-1.01v-26.18a34.768 34.768 0 01-.463-.115c-13.062-3.393-22.924-15.256-28.864-28.365a97.674 97.674 0 01-8.242-38.313 3.629 3.629 0 013.489-3.538q40.843-.255 81.689-.001zm-81.507 1.831a1.534 1.534 0 00-1.49 1.493c.362 18.6 5.118 37.912 17.005 52.211 5.075 6.1 11.724 11.146 19.516 12.817a1.1 1.1 0 01.8.987v25.975h9.384v-25.975c.1-1.169.847-.984 1.706-1.2 8.329-2.086 15.359-8.039 20.419-14.909a67.064 67.064 0 005.592-9.07 3.71 3.71 0 01.354-.7q.9-1.813 1.7-3.679s.587-1.4 1.19-2.9c.145-.4.151-.4.224-.588a78.307 78.307 0 003.2-10.349c.384-1.343.589-2.461.845-3.756 0 0 .357-1.753.51-2.685a106.2 106.2 0 001.572-15.608c.028-1.018-.461-2.05-1.454-2.069-27.025-.165-54.049.005-81.073.005zm80.529 20.143l-.05.11s.134-.244.05-.11z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 717"
                                d="M187.139 160.43h-47.864a11.316 11.316 0 0111.316-11.316h25.232a11.315 11.315 0 0111.316 11.316z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 718"
                                d="M175.886 148.213a12.591 12.591 0 0112.231 12.231s-.163 1.034-1.009 1.089h-47.8c-1.291-.085-1.082-2.393-.583-4.234a12.558 12.558 0 0111.8-9.086q12.682-.039 25.361 0zm-25.278 1.875a10.538 10.538 0 00-10.24 9.238l45.68.044a10.54 10.54 0 00-10.176-9.281c-8.425-.026-16.842-.001-25.263-.001z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 719"
                                d="M198.168 175.33h-70.1v-4.8a10.108 10.108 0 0110.104-10.1h49.889a10.108 10.108 0 0110.108 10.108z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 720"
                                d="M188.226 159.286a11.438 11.438 0 0111.046 11.115v4.753c-.063.8-.262.951-1.01 1.01h-70.09c-.8-.063-.951-.262-1.01-1.01 0-2.92-.306-5.84.652-8.509a11.32 11.32 0 0110.322-7.358c16.697-.162 33.394-.054 50.09-.001zm-49.959 2.106a9.363 9.363 0 00-9.1 9.1v3.738h68.07c0-2.348.227-4.7-.533-6.816a9.275 9.275 0 00-8.506-6.022c-16.643-.052-33.286 0-49.93 0z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 721"
                                d="M204.493 200.174h-82.478a1.679 1.679 0 01-1.679-1.679v-18.214a9.639 9.639 0 019.639-9.639h66.558a9.639 9.639 0 019.639 9.639v18.214a1.679 1.679 0 01-1.679 1.679z"
                                fill="#7c76bb"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 722"
                                d="M196.553 169.678a10.9 10.9 0 0110.579 10.58c.02 6.1 0 12.2 0 18.3a2.772 2.772 0 01-2.62 2.682c-27.512.341-55.028 0-82.542 0a2.773 2.773 0 01-2.682-2.62c-.078-6.143-.058-12.287 0-18.43a10.9 10.9 0 0110.511-10.511c22.254-.215 44.501-.073 66.754-.001zm-66.614 2.019a8.837 8.837 0 00-8.631 8.573c-.019 6.1-.078 12.2 0 18.3a.691.691 0 00.651.651q41.249.51 82.5 0a.691.691 0 00.651-.651c.076-6.117.057-12.235 0-18.353a8.848 8.848 0 00-8.572-8.518c-22.2-.074-44.401-.002-66.601-.002z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 723"
                                d="M102.503 64.934h18.332q.448 4.377 1.234 8.556h-20.221a.35.35 0 00-.349.366c.126 2.956 2.745 23.037 28.448 23.458a64.176 64.176 0 006.228 9.967c-31.956 1-41.819-20.568-44.918-33.937a6.866 6.866 0 016.693-8.41z"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 724"
                                d="M120.773 64.106l.176.018c1.121.249.84 1.078.906 1.686q.4 3.562 1.031 7.087l.11.639a1.052 1.052 0 01-1 1.059h-19.351a23.808 23.808 0 0013.634 18.9 34.306 34.306 0 0013.4 2.729s.638-.188 1.278 1.05a63.341 63.341 0 005.824 9.232 1.174 1.174 0 01.2.729c-.076.627-.785.876-1.525.894-11.973.261-24.246-2.952-32.864-11.471-6.073-6-9.957-14.012-11.986-22.345-1.163-4.775 2.168-10.189 7.461-10.208zm-23.014 1.932a6.133 6.133 0 00-5.751 7.424q.055.237.113.474a55.355 55.355 0 002.183 6.972c3.322 8.54 9.192 16.2 17.261 20.7 6.9 3.853 14.958 5.316 22.855 5.316 0 0-1.583-2.342-2.719-4.232q-1.143-1.9-2.163-3.869c-9.459-.256-19.119-3.83-24.514-11.6a25.9 25.9 0 01-4.683-12.953 1.4 1.4 0 011.352-1.607h19.272q-.059-.335-.115-.67-.479-2.862-.807-5.756l-.022-.2z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 725"
                                d="M228.393 89.434c-6.341 9.795-17.7 18.44-38.112 17.856.249-.325.5-.652.741-.99l.1-.143a69.624 69.624 0 001.234-1.76l.1-.152c.236-.349.468-.7.7-1.065q.246-.385.493-.783c.173-.278.345-.56.514-.846q.12-.19.227-.379c.173-.291.345-.581.514-.88a70.061 70.061 0 001.6-2.969c25.53-.514 28.137-20.507 28.263-23.454a.35.35 0 00-.349-.366h-20.031q.1-.524.19-1.049c.126-.7.244-1.406.349-2.114.059-.354.11-.708.16-1.065q.17-1.175.316-2.362c.034-.274.063-.547.1-.825.042-.379.084-.758.122-1.141h22.7a6.868 6.868 0 016.7 8.413 55.46 55.46 0 01-2.843 8.87"
                                fill="#ffce93"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 726"
                                d="M206.432 66.501c-.233 2.02-.521 4.037-.87 6.033l-.023.129h18.931a1.857 1.857 0 01.761.109c.884.683.468 2.233.24 3.51a26.209 26.209 0 01-12.815 18.2 34.768 34.768 0 01-15.583 3.865s-1.542 2.836-2.365 4.2l-.263.428q-1.072 1.725-2.253 3.387c10.683-.011 21.585-2.952 29.507-10.417a38.282 38.282 0 005.755-6.92s.48-.672 1.1-.556c.746.139 1.188.756.355 2.011a40.233 40.233 0 01-24.99 16.724 57.412 57.412 0 01-13.26 1.161c-2.178-.047-1.563-1.288-1.17-1.735 0 0 1.53-2.08 2.41-3.421a66.935 66.935 0 003.55-6.121c.526-1.023 2.539-.767 4.21-.922 7.5-.7 15.144-3.877 19.654-10.225 2.388-3.361 4.372-11.343 4.372-11.343h-19.327c-1.428-.09-.664-2.825-.3-5.379q.285-2 .5-4s.057-1.13 1.032-1.19c7.606 0 15.213-.082 22.819 0a8.122 8.122 0 017.6 9.475 50.737 50.737 0 01-2.872 9.056c-.247.595-1.38 1.11-1.867.261-.455-.793.6-2.471 1.134-4.011q.669-1.941 1.191-3.929a9.014 9.014 0 00.408-4.328 5.994 5.994 0 00-5.666-4.508h-21.851l-.054.456z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 727"
                                d="M170.815 120.623a1.012 1.012 0 01.141 2.01 33.856 33.856 0 01-6.675 1.138s-.982-.14-1.072-.887c-.159-1.318 2.427-1.195 4.477-1.569q1.427-.261 2.826-.655a.661.661 0 01.303-.037z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 728"
                                d="M166.198 121.542s1.88-.242 3.674-.673a41.422 41.422 0 006.76-2.7 45.14 45.14 0 006.963-5.1c2.368-2.01 4.881-5.312 7.034-8.039s5.813-10.12 6.531-11.628a98.168 98.168 0 004.746-13.566 125.753 125.753 0 002.575-14.5c.3-2.666.5-6.812.588-9.055a1.508 1.508 0 00-1.507-1.559h-10.421a128.774 128.774 0 01-2.936 30.864 62.367 62.367 0 01-24.007 35.956"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 729"
                                d="M164.773 123.749a25 25 0 003.036-.395v24.656h-3.036z"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 730"
                                d="M185.938 159.329a9.69 9.69 0 00-3.43-6.646 10.374 10.374 0 00-6.894-2.465h-.286s4.644 1.321 5.573 9.108z"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 731"
                                d="M197.272 169.814a8.436 8.436 0 00-2.974-6.063 8.9 8.9 0 00-6.41-2.217h-.26s4.221 1.2 5.065 8.28z"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 732"
                                d="M178.068 64.698l-.223.057.162.153a4.484 4.484 0 01-4.346 7.537l-.218-.061.06.214a4.356 4.356 0 01-.444 3.546l6.293 13.418a.336.336 0 01-.433.452l-6.41-2.653-2.059 6.624a.334.334 0 01-.622.04l-6.563-14.003a4.683 4.683 0 01-3.7 0l-6.475 13.813a.417.417 0 01-.776-.052l-1.993-6.421-6.217 2.569a.416.416 0 01-.537-.561l6.2-13.224a4.357 4.357 0 01-.448-3.546l.06-.214-.214.061a4.486 4.486 0 01-4.35-7.537l.162-.153-.218-.057a4.484 4.484 0 010-8.7l.218-.056-.162-.154a4.67 4.67 0 01-.832-1.066h34.865a4.644 4.644 0 01-.836 1.066l-.162.154.223.056a4.484 4.484 0 010 8.7"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 733"
                                d="M136.296 66.528c-3.056-.158-2.979-3.556-2.979-3.556-.082 3.733-3.142 3.556-3.142 3.556a3.459 3.459 0 013.142 3.465s.393-3.167 2.979-3.465"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 734"
                                d="M139.307 70.21c-1.879-.1-1.831-2.186-1.831-2.186-.05 2.295-1.931 2.186-1.931 2.186a2.126 2.126 0 011.931 2.13s.242-1.947 1.831-2.13"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 735"
                                d="M161.371 93.558a.492.492 0 01.413.211l6.81 8.01a.47.47 0 01.142.442.628.628 0 01-1.065.343l-6.81-8.011c-.658-.836.191-1.005.51-.995z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 736"
                                d="M166.373 93.855a.635.635 0 01.415.2q.931 1.054 1.821 2.142c.372.49-.434 1.3-.922.784q-.93-1.054-1.821-2.142a.617.617 0 01.507-.984z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 737"
                                d="M159.871 97.416a.646.646 0 01.407.188c1.342 1.495 2.6 3.06 3.9 4.591.375.467-.287 1.423-.9.809-1.342-1.495-2.6-3.061-3.9-4.591a.6.6 0 01.493-.997z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 738"
                                d="M151.579 171.463a.572.572 0 01.5.549v6.435c-.014.418-.146.42-.32.5a.569.569 0 01-.784-.5v-6.435a.524.524 0 01.604-.549z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 739"
                                d="M157.956 170.088a.621.621 0 01.468.6v16.006c-.028.613-1.062.906-1.1 0v-16.008a.591.591 0 01.632-.598z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 740"
                                d="M223.043 196.468c.927.066.295 1.846-.147 3.3-.188.621-.488 1.527-.488 1.527-.248.591-1.434.485-1.059-.655.054-.162.106-.325.158-.488q.508-1.6.922-3.232a.639.639 0 01.614-.452z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 741"
                                d="M220.358 204.552c1.229.17-.272 2.715-1.319 4.783a72.307 72.307 0 01-8.544 12.986s-.6.423-.942 0c-.28-.349.051-.82.372-1.217a68.63 68.63 0 009.7-15.887l.152-.351c.178-.291.216-.326.581-.314z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 742"
                                d="M161.359 73.261l-6.476 13.816a.417.417 0 01-.775-.053l-2-6.419-6.214 2.571a.417.417 0 01-.537-.562l6.2-13.226a4.637 4.637 0 002.6 2 4.336 4.336 0 004.485-1.19l.156-.161.051.216a4.331 4.331 0 002.51 3.008z"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 743"
                                d="M151.669 68.379c.7.1.712.432.887.68a3.565 3.565 0 005.343.453l.239-.243a.824.824 0 01.36-.2 1.042 1.042 0 011.268.65 4.116 4.116 0 002 2.617s.811.629.508 1.353l-6.48 13.825a1.465 1.465 0 01-2.647-.181l-1.671-5.372-5.246 2.169a1.465 1.465 0 01-1.789-1.936l6.2-13.235a1.053 1.053 0 011.028-.58zm-4.746 13.275l4.881-2.016a1.056 1.056 0 011.271.662l1.543 4.959 5.427-11.58a3.917 3.917 0 01-.459-.355 5.4 5.4 0 01-1.241-1.583 6.849 6.849 0 01-3.53.805 5.683 5.683 0 01-1.658-.472 8.659 8.659 0 01-1.342-.858z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 744"
                                d="M180.718 83.254l-6.412-2.653-2.06 6.623a.334.334 0 01-.621.043l-6.564-14.006a4.278 4.278 0 002.243-2.259 5.362 5.362 0 00.257-.758l.051-.216.155.161a4.337 4.337 0 004.486 1.19 4.651 4.651 0 002.6-2l6.29 13.421a.334.334 0 01-.425.454z"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 745"
                                d="M174.917 68.376a1.081 1.081 0 01.855.58l6.294 13.429a1.377 1.377 0 01-1.726 1.806l-5.4-2.234-1.748 5.618a1.382 1.382 0 01-2.479.129l-6.571-14.015c-.463-1.107 1.314-1.428 2.06-2.745a5.049 5.049 0 00.422-1.148c.14-.5.234-.48.415-.6.448-.3.916-.243 1.5.345a3.608 3.608 0 005.457-.688 1.126 1.126 0 01.921-.477zm-.323 2.849a5.618 5.618 0 01-6.489.538 6.951 6.951 0 01-1.732 1.918l5.425 11.579 1.571-5.038a1.057 1.057 0 011.322-.554l4.8 1.987-4.892-10.437z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 746"
                                d="M179.866 57.939l-.222.057.162.155a4.483 4.483 0 01-4.346 7.534l-.219-.06.06.212a4.36 4.36 0 01-.444 3.546 4.649 4.649 0 01-2.6 2 4.338 4.338 0 01-4.486-1.189l-.155-.162-.051.216a5.361 5.361 0 01-.257.758 4.275 4.275 0 01-2.243 2.259 4.676 4.676 0 01-3.7 0 4.33 4.33 0 01-2.5-3.014l-.051-.216-.155.162a4.338 4.338 0 01-4.485 1.189 4.638 4.638 0 01-2.6-2 4.348 4.348 0 01-.447-3.546l.06-.212-.216.06a4.484 4.484 0 01-4.349-7.534l.162-.155-.219-.057a4.483 4.483 0 010-8.7l.219-.057-.162-.155a4.484 4.484 0 014.349-7.534l.216.064-.06-.216a4.486 4.486 0 017.537-4.353l.155.162.051-.216a4.487 4.487 0 018.7 0l.051.216.155-.162a4.485 4.485 0 017.534 4.353l-.06.216.219-.064a4.507 4.507 0 015.643 5.256 4.455 4.455 0 01-1.3 2.278l-.162.155.222.057a4.483 4.483 0 01-.006 8.697z"
                                fill="#ff9490"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 747"
                                d="M163.263 32.528a5.557 5.557 0 014.789 2.871 6.708 6.708 0 013.386-.785 5.636 5.636 0 015.058 5.644 6.8 6.8 0 013.216.936 5.668 5.668 0 011.669 7.5 6.749 6.749 0 012.379 2.54 5.648 5.648 0 01-2.358 7.2s-.177-.23.135.322c1.823 3.309-.768 8.274-4.992 8.127a6.83 6.83 0 01-.967 3.251 5.641 5.641 0 01-7.472 1.633 6.746 6.746 0 01-2.776 2.48 5.648 5.648 0 01-6.984-2.5 6.354 6.354 0 01-3.618.794 5.631 5.631 0 01-4.807-5.64 6.568 6.568 0 01-3.329-.987 5.654 5.654 0 01-1.559-7.415s.267.112-.28-.21c-3.234-1.953-3.515-7.528.263-9.535 0 0 .175.23-.137-.322-1.818-3.3.721-8.276 5-8.127 0 0 .036.289.042-.346.078-3.767 4.762-6.808 8.391-4.541a7.782 7.782 0 011.034-1.333 5.574 5.574 0 013.917-1.557zm-.089 2.019a3.521 3.521 0 00-3.327 2.591 1.288 1.288 0 01-.358.757c-.954.876-2.026-1.246-3.947-1.284a3.613 3.613 0 00-3.4 4.675s.076 1.536-1.675 1.125a3.424 3.424 0 00-2.54.4 3.578 3.578 0 00-.62 5.5 1.306 1.306 0 01.468.824c.07 1.23-2.245 1.038-3.177 2.734a3.575 3.575 0 002.2 5.1s1.716.622.355 2.071a3.433 3.433 0 00-.929 2.225 3.61 3.61 0 004.672 3.4s1.522-.076 1.119 1.713a3.476 3.476 0 00.991 3.214 3.559 3.559 0 004.888-.066 1.5 1.5 0 01.58-.441c.662-.226 1.239.5 1.387 1.039a3.627 3.627 0 005.534 1.78 3.55 3.55 0 001.173-1.859 1.294 1.294 0 01.55-.888c1.05-.58 1.926 1.392 3.758 1.424a3.605 3.605 0 003.393-4.681s-.156-1.521 1.76-1.114a3.4 3.4 0 002.457-.413 3.573 3.573 0 00.617-5.5 1.3 1.3 0 01-.468-.824c-.07-1.231 2.25-1.038 3.18-2.732a3.575 3.575 0 00-2.2-5.1 1.3 1.3 0 01-.93-.718c-.218-.658.548-1.211.972-1.864 1.454-2.246-.806-6.048-3.919-5.171a1.3 1.3 0 01-1.161-.147c-.551-.482-.1-1.41-.1-2.258.021-2.647-3.725-4.634-5.956-2.4a1.317 1.317 0 01-.941.5c-1.054-.03-.975-1.768-2-2.714a3.417 3.417 0 00-2.411-.898z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 748"
                                d="M170.228 51.441a7.333 7.333 0 11-3.948-4.523"
                                fill="#fff"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 749"
                                d="M163.267 45.241a7.439 7.439 0 013.5.8c.915.5.425 2.291-1.334 1.624-3.729-1.388-8.445 1.573-8.551 5.762a6.587 6.587 0 008.208 6.208 6.5 6.5 0 004.17-7.9 1.259 1.259 0 01.344-1.085c.657-.515 1.527.028 1.778 1.237a8.627 8.627 0 01-5.889 9.732c-4.738 1.346-10.292-2.4-10.615-7.5a8.586 8.586 0 018.116-8.88c.09.003.181.002.273.002z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 750"
                                d="M153.913 59.892c.515.028.767.681 1.176 1.153a11.171 11.171 0 001.393 1.354 11.37 11.37 0 001.757 1.175.778.778 0 01.313.6c-.1.915-1.758.086-3.042-1.008a12.571 12.571 0 01-2.109-2.318s-.157-.958.512-.956z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 751"
                                d="M186.817 29.21a.621.621 0 01.236 1.149c-1.092.506-2.205.961-3.309 1.441-.543.222-1.276-.7-.509-1.1 1.191-.552 2.365-1.525 3.582-1.49z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 752"
                                d="M177.119 22.619a.628.628 0 01.572.6c-.01 1.126-.072 2.251-.108 3.377-.033.586-1.178.858-1.211-.01.01-1.126.072-2.251.108-3.377a.585.585 0 01.639-.59z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 753"
                                d="M182.481 24.816a.632.632 0 01.48.928c-.679 1.025-1.4 2.021-2.1 3.03-.361.5-1.409 0-1.01-.668.679-1.025 1.4-2.021 2.1-3.03a.622.622 0 01.53-.26z"
                                fill="#3b1b6a"
                            />
                        </g>
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 754"
                            d="M196.582 171.746c.067 0 .658.035.725.042a9.022 9.022 0 014.975 2.2 8.659 8.659 0 012.788 6.923v17.535s-.324.731-.728.624h-2.226c-2.409.082-20.774.276-20.774.276l-3.486.017c.563-.765 1.2-1.572 1.934-2.438a40.266 40.266 0 007.53-13.665c.093-.316.211-.624.328-.936 2.4-6.435 5.856-6.188 7.558-5.657l-.028-.458a33.439 33.439 0 00-.553-4.463z"
                            fill="rgba(0,0,0,0.25)"
                            style={{
                                mixBlendMode: "overlay",
                                isolation: "isolate",
                            }}
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 755"
                            d="M197.325 260.891c-.889-14.706-2.383-40.225-2.213-42.794.242-3.628 6.171-8.833 10.287-14.641s3.389-6.536 2.662-11.01-1.684-17.913-4.621-24.558a8 8 0 00-4.557-4.584 1.291 1.291 0 00-1.7 1.47 2765.464 2765.464 0 001.935 9.553 32.4 32.4 0 01.566 4.37l.028.457c-1.7-.53-5.137-.776-7.533 5.638-.117.311-.234.618-.327.933a40.111 40.111 0 01-7.5 13.62c-5.323 6.322-5.969 9.492-5.2 16.221a26.538 26.538 0 01.162 4.048l-1.732 41.277z"
                            fill="#ffd0c2"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 756"
                            d="M198.483 162.362c2.14.062 4.131 2.065 5.332 4.18 2.059 3.626 2.7 7.926 3.405 12.174.864 5.245 1.3 10.594 2.178 15.712 1.205 7.208-7.258 13.754-11.857 20.624a7.019 7.019 0 00-1.434 4.025c.077 8.055.722 16.144 1.184 24.235q.421 7.372.865 14.741l.088 1.465.085 1.408a1.1 1.1 0 01-1.007 1.07H177.59c-.839-.076-.971-.281-1.009-1.051.575-13.792 1.225-27.582 1.724-41.377.137-4.421-1.125-8.86.437-13.168 1.378-3.8 4.41-6.772 6.71-10a38.721 38.721 0 005.47-10.96c1.119-3.5 3.009-7.38 7.223-7.4h.231l.208-.225a32.398 32.398 0 00-.293-2.236 40.51 40.51 0 00-.169-.906c-.652-3.2-1.332-6.4-1.942-9.6a2.392 2.392 0 012.187-2.711zm-.043 2.057c-.377 0-.2.71-.091 1.238.577 2.871 1.124 5.755 1.748 8.626.086.4.079.4.114.587a35.135 35.135 0 01.476 3.948l.028.458c-.02.559-.12.578-.243.721-.677.785-2.038-.238-3.524.332-2.706 1.037-3.668 4.452-4.319 6.464a45.4 45.4 0 01-8.219 14.24c-1.864 2.31-3.611 4.8-4.229 7.692-.785 3.671.262 7.474.131 11.111l-1.677 40.23h17.618c-.809-13.413-1.869-26.844-2.153-40.305a8.714 8.714 0 01.8-4.134c2.914-5.775 8.77-9.753 11.987-16.24 1.336-2.7.219-5.985-.19-9.328-.867-7.1-1.267-14.4-3.954-21.048a7.661 7.661 0 00-4.085-4.53.818.818 0 00-.183-.06.167.167 0 00-.035-.002z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 757"
                            d="M199.66 176.191c1.131.185 1.065 1.817 1.055 3.379-.039 5.821-3.416 11.248-3.5 16.532a1.314 1.314 0 01-.484.955c-.764.467-1.619-.253-1.5-1.719.286-3.505 1.827-6.822 2.594-10.212a24.345 24.345 0 00.723-7.718s.095-1.296 1.112-1.217z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 758"
                            d="M195.609 182.019c.574.031.883.758 1.76.718.709-.007 1.1 1.38-.5 1.207-.835-.09-1.862-.626-1.884-1.3a.621.621 0 01.624-.625z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 759"
                            d="M179.175 214.063c.886.051.962 1.237 1.255 2.119a5.408 5.408 0 002.012 2.83 1.3 1.3 0 01.423 1.064c-.212.933-1.562.8-2.564-.227a7.089 7.089 0 01-2.172-4.791.976.976 0 011.046-.995z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 760"
                            d="M185.119 203.423c1.165.092.584.814.282 1.169a7.866 7.866 0 00-1.667 4.923.728.728 0 01-.26.488c-.389.269-.963.008-.948-.647a8.957 8.957 0 012.073-5.69.642.642 0 01.52-.243z"
                            fill="#3b1b6a"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 761"
                            d="M191.268 260.068h4.957l-1.134-18.872s-.928-18.15-.963-20.144-.172-3.162.481-4.916 4.026-5.81 4.026-5.81c5.913-7.012 7.493-9.522 8.387-11.378a6.427 6.427 0 00.378-4.159c-.1-.344-.756-4.847-.756-4.847l-.894-7.264s0 4.515-.138 8.4c-.01.3-.014.59-.016.868a13.684 13.684 0 01-4.16 9.734c-3.422 3.332-9.015 9.1-10.124 12.188-2.166 6.038-.644 18.872-.619 26.022.012 3.414.619 20.178.619 20.178"
                            fill="rgba(0,0,0,0.25)"
                            style={{
                                mixBlendMode: "overlay",
                                isolation: "isolate",
                            }}
                        />
                        <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 68">
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 762"
                                d="M129.096 260.618c.888-14.666 2.383-40.114 2.213-42.675-.242-3.618-6.172-8.809-10.287-14.6s-3.389-6.517-2.662-10.98 1.684-17.864 4.621-24.49a7.992 7.992 0 014.556-4.571 1.29 1.29 0 011.7 1.466c-.441 2.2-1.212 6-1.927 9.494 0 .012 0 .02-.008.032a32.148 32.148 0 00-.565 4.358l-.028.455c1.7-.528 5.138-.773 7.533 5.623.117.31.234.616.327.93a39.978 39.978 0 007.5 13.582c5.324 6.3 5.97 9.466 5.2 16.176a26.43 26.43 0 00-.162 4.036l1.721 41.165z"
                                fill="#ffd0c2"
                                fillRule="evenodd"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 763"
                                d="M128.411 177.955c4.154.146 6.443 5.455 7.337 8.171a43.349 43.349 0 007.832 13.445c2.565 3.17 4.722 6.806 4.947 10.92.166 3.036-.508 6.051-.4 9.02l1.721 41.162c-.04.839-.24.98-1.009 1.049h-19.744c-.849-.077-.984-.29-1.008-1.068.8-13.269 1.739-26.667 2.178-39.9a10.316 10.316 0 00-2.125-6.906c-3.43-4.591-7.807-8.516-10.414-13.758-1.644-3.307-.383-7.328.034-10.767.88-7.257 1.388-14.778 4.116-21.345 1.049-2.526 2.906-4.94 5.546-5.6a2.365 2.365 0 012.815 2.641c-.613 3.2-1.289 6.386-1.943 9.576a31.6 31.6 0 00-.475 3.28s-.003.066.592.08zm-.442-13.661c-1.392.04-3.364 2.3-4.268 4.507-3.277 7.989-3.226 17.194-4.712 25.845-1.1 6.557 7.72 12.959 11.919 19.693a8.959 8.959 0 011.413 5.21c-.281 13.314-1.334 26.608-2.135 39.9l-.02.338h17.637l-1.679-40.167c-.162-4.45 1.222-8.973-.527-13.193-1.375-3.318-4.051-5.912-6.163-8.843a41.886 41.886 0 01-5.877-11.7l-.071-.208-.058-.162c-.971-2.611-2.352-5.582-5.214-5.561a5.9 5.9 0 00-1.212.2c-.549.113-.591.019-.759-.066-.792-.405-.492-2.216-.3-3.675.1-.762.219-1.518.376-2.274.008-.042-.014.073.01-.039q.268-1.307.533-2.612l1-4.931c.157-.779.4-1.891.393-2a.289.289 0 00-.257-.265z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 764"
                                d="M126.93 176.146a1.054 1.054 0 01.955 1.1c-.371 4 .652 8.059 1.852 11.708a24.286 24.286 0 011.49 7.089 1.242 1.242 0 01-.34.813c-.658.582-1.63-.01-1.692-1.188-.185-3.174-1.567-6.193-2.337-9.2a27.1 27.1 0 01-1-9.29s.207-1.039 1.072-1.032z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 765"
                                d="M130.852 181.965c.957.047.037 2.309-1.938 1.913-.594-.119-.655-1.183.362-1.185.661-.016 1.113-.736 1.576-.728z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 766"
                                d="M147.431 213.475c1.174.074.953 1.956.3 3.595a7.366 7.366 0 01-2.631 3.458 1.228 1.228 0 01-.824.136 1.044 1.044 0 01-.207-1.872 6.483 6.483 0 002.32-4.442 1.073 1.073 0 011.042-.875z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 767"
                                d="M141.368 203.309c.52.051.853.7 1.188 1.228a9.333 9.333 0 011.337 4.827s-.058.457-.4.579c-.847.3-.76-1.521-1.114-2.816a6.922 6.922 0 00-1.5-2.812s-.246-1.035.489-1.006z"
                                fill="#3b1b6a"
                            />
                            <path
                                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 768"
                                d="M144.538 220.712s.594.046 1.578-1.006l1.676 40.085h-4.538z"
                                fill="rgba(0,0,0,0.25)"
                                style={{
                                    mixBlendMode: "overlay",
                                    isolation: "isolate",
                                }}
                            />
                        </g>
                    </g>
                    <g fill="#3b1b6a">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 769"
                            d="M325.257 260.067a.967.967 0 01-.093 1.928H302.89c-1.068-.047-1.378-1.871 0-1.932h22.274z"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 770"
                            d="M294.285 260.167a1.256 1.256 0 01.5.494l.077.37-.077.37-.219.313-.327.21-.387.073H26.148l-.386-.073a1.311 1.311 0 01-.546-.523l-.077-.37.077-.37.219-.313a1.369 1.369 0 01.714-.283h267.792z"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 771"
                            d="M19.176 260.067c.809.1.854.465.913.8a.957.957 0 01-1 1.125H.939c-1.046-.041-1.452-1.874 0-1.932h18.15z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default EventsIconSvg
