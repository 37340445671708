import React from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import Root from './Root';
import mainTheme from './themes/MainTheme';
import moment from 'moment';
import 'moment/locale/pl';
import { CookiesProvider } from 'react-cookie';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AOS from 'aos';
import 'aos/dist/aos.css'

function App() {
  useEffect(() => {
    // eslint-disable-next-line 
    moment.locale('pl');
    AOS.init({
    })
  }, []);
  return (
    <CookiesProvider>
      <MuiThemeProvider
        theme={mainTheme}
      >
        <CssBaseline />
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
          <Root />
        </GoogleReCaptchaProvider>
      </MuiThemeProvider>
    </CookiesProvider>
  );
}

export default App;
