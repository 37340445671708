import * as React from "react"

function FamilySvg(props) {
    return (
        <svg
            data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 108"
            xmlns="http://www.w3.org/2000/svg"
            width={907.123}
            height={515.86}
            viewBox="0 0 907.123 515.86"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 79">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 75">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 773"
                        d="M853.715 270.552c16.444-10.286 25.88-12.912 18.92-20.686s-12.218-6.894-22.246 12.013a71.906 71.906 0 00-5.115 11.5z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 774"
                        d="M853.574 270.572c6.711-.621 33.478 2.36 33.247 18.737s-18.511 9.49-23.559 4.257-16.1-17.248-19.411-19.668c6.893-2.352 9.723-3.326 9.723-3.326z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 775"
                        d="M870.909 267.537s-24.176 1.984-60.669 20.6"
                        fill="none"
                        stroke="#8cb2ef"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 776"
                        d="M815.665 285.581c11.314 3.694 20.915 6.927 27.77 9.511s10.982 8.817 5.952 18.347c-10.078 19.091-33.1-18.158-39.137-25.3-1.29-1.519 3.518-3.178 5.415-2.558z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 777"
                        d="M868.677 267.931c12.066-7.76 21.021-12.833 25.043-4.093s-20.246 8.236-25.043 4.093z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 76">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 778"
                        d="M749.853 428.911c4.008-.735 17.73 9.818 28.151 1.911s5.865-15.8 9.895-20.483 20.578-3.068 27.029-10.558 5.424-12.451 9.858-17.6 21.762-7.755 20.95-18.646-13.93-9.716-18.774-8.016-10.17 7.55-15.4 7.94-8.838-4.756-16.579-2.05-13.961 9.582-21.366 10.136-14.616-4.758-26.333-.665-18.4 25.394-17.354 28.674c.9 2.813-9.083 15.6-6.951 23.46l8.836 7.61c5.566 2.007 10.203-.278 18.038-1.713z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 779"
                        d="M823.458 369.62s-53.817 21.848-97.761 55.734"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 780"
                        d="M784.348 388.469s13.986.376 22.286 5.839"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 781"
                        d="M762.796 400.372s5.258-8.6 6.351-13.98"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 77">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 782"
                        d="M764.138 378.874c44.507 13.884 110.82-37.5 126.953-55.25s-20.03-22.787-48.687-23.885c-17.121-.655-33.035-25.793-69.265-12.933s-56.143 44.608-49.406 51.388-7.537 25.72 40.405 40.68z"
                        fill="#d7dbf2"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 783"
                        d="M739.934 355.992c13.433-11.977 45.845-34.988 111.523-37.647"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 1"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                        d="M794.749 326.921l10.461-26.49"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 784"
                        d="M811.668 322.941s24.156 20.343 37.35 22.7"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 78">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 785"
                        d="M901.899 178.342c11.269 10.737 1.681 21.752-4.278 25.386s-10.953 2.306-15.729 7.315 1.77 22.384-9.764 34.489-30.829 7.3-36.43 10.714-.191 9.339-3.771 13.1a16.056 16.056 0 01-2.736 2.225c2.356-12.4-4.691-20.219-1.93-28.248 3.544-10.315 31.734-18.507 37.4-35.013 4.228-12.3 2.566-19.575 3.058-27.183 9.588-8.209 20.797-13.229 34.18-2.785z"
                        fill="#e6e8f6"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 786"
                        d="M777.036 298.697c6.093-3.829 35.5 4.487 47.412-15.3s-.733-29.754 2.813-40.069 31.734-18.507 37.4-35.013.74-23.967 4.639-35.314 30.608-26.969 22.144-44.3-29.2-6.8-36.041-.85-11.732 19.039-20.05 23.1-17.6-2.011-28.527 7.5-16.616 24.856-28.4 30.611-27.08 1.762-43.611 16.149-13.538 53.686-9.676 58.381c3.31 4.026-4.681 31.523 3.966 43.022l19.471 6.692c10.446-.349 16.552-7.134 28.46-14.609z"
                        fill="#fff"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 787"
                        d="M858.902 153.272s-73.936 71.067-123.8 155.4"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 788"
                        d="M807.113 209.786s23.177-8.541 40.363-5.009"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 789"
                        d="M779.57 243.417s2.99-17.539 1.259-27.083"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
            </g>
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 83">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 80">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 790"
                        d="M263.427 96.628c-12.058-11.14-16.3-18.262-21.1-10.849s-2.913 11.51 14.761 15.494a60.936 60.936 0 0010.523 1.621z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 791"
                        d="M263.477 96.74c-2-5.345-5.522-27.866 7.9-31.319s11.867 13.007 8.72 18.291-10.508 16.98-11.746 20.227c-3.451-5.105-4.874-7.199-4.874-7.199z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 792"
                        d="M257.14 83.257s7 19.3 30.318 44.964"
                        fill="none"
                        stroke="#8cb2ef"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 793"
                        d="M284.165 124.357c.5-10.059 1.006-18.621 1.591-24.791s4.758-10.932 13.658-8.944c17.834 3.983-7.465 31.07-11.95 37.589-.958 1.394-3.382-2.165-3.299-3.854z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 794"
                        d="M257.957 84.993c-9.021-8.125-15.154-14.312-8.911-19.541s11.229 14.704 8.911 19.541z"
                        fill="#8cb2ef"
                        fillRule="evenodd"
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 81">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 795"
                        d="M284.146 167.196c-25.086 12.187-69.269-9.981-80.69-18.663s9.3-15.586 26.034-19.354c10-2.252 16.62-18.769 39.33-15.154s34.658 24.841 34.658 24.841z"
                        fill="#e6e8f6"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 796"
                        d="M295.89 151.101c-9.206-5.582-30.779-15.584-69.7-9.993"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 2"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                        d="M278.628 143.573l-12.35-19.729"
                    />
                </g>
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 82"
                    fill="none"
                    stroke="#8cb2ef"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 797"
                        d="M197.336 166.589c-15.658-4.932-23.946-5.078-19.9-12.925s8.545-8.246 20.78 5.115a60.732 60.732 0 016.632 8.331z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 798"
                        d="M197.456 166.575c-5.63.918-26.927 9.048-23.256 22.417s17.183 3.841 20.206-1.517 9.521-17.553 11.722-20.241c-6.147-.462-8.672-.659-8.672-.659z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 799"
                        d="M196.958 166.471s43.421 2.362 77.964 16.109"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 800"
                        d="M226.27 169.888c-8.177-7.141-25.5-20.381-11.944-25.757s14.9 8.763 15.384 13.652 1.991 13.038 1.991 13.038z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 801"
                        d="M231.704 170.817c-7.8 6.372-15.661 10.12-20.728 13.693s-7.124 9.557-.977 16.3 18.548 2.441 21.685-2.385 4.062-14.535 4.641-19.651 1.417-6.806 1.417-6.806z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 802"
                        d="M255.131 176.051c-6.048-7.593-13.406-19.155-15.3-26.8s6.415-15.511 15.748-9.447 10.83 15.731 9.607 20.428-6.019 16.957-6.019 16.957z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 803"
                        d="M275.41 182.071c-9.164 1.6-20.383 4.677-26.243 8.431-6.631 4.247-5.994 15.677 4.953 17.682a23.729 23.729 0 004.066.4z"
                    />
                </g>
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 804"
                d="M312.331 117.047c-85.441 95.8-70.93 199.968-3.251 260.46 49.186 43.963 121.646 20.495 169.066 68.642s142.986 96.718 225.552 52.088c101.756-55 25.62-149.681 43.867-217.473S853.127 101.649 793.77 25.355c-56.935-73.184-160.048 35.37-255.2 35.052s-146.018-33.301-226.239 56.64z"
                fill="#e6e8f6"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 805"
                d="M218.96 247.57c6.355-9.542 24.609-7.535 32.9-1.907 13.209 8.967 11.065 14.262 22.1 22.473 5.2 3.87 16.978 2.842 24.957 9.927 5.994 5.319 1.88 13.167 6.22 19.56 3.4 5.011 17.065 1.6 24.041 15.44 3.511 6.97 1.261 24.166-15.243 27.546-13.575 2.78-22.587-9.176-35.952-10.6-15.748-1.683-31.07 19.637-42-17.908a30.281 30.281 0 01-4.269-9.137c-14.125-17.712-27.847-48.536-12.754-55.394z"
                fill="#4247ca"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 806"
                d="M13.393 471.14c27.368-10.81 213.223-32.727 238.418-20.783s-23.21 5.665-22.82 17.964 141.215 11.169 141.915 33.235-179.232-3.667-253.8 3.929C34.009 513.95-28.529 487.696 13.393 471.14z"
                fill="#e6e8f6"
                fillRule="evenodd"
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 84">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 807"
                    d="M148.387 380.142a1.968 1.968 0 01-1.448-.091c-.241-.135 2.329-4.654 2.329-4.654s-.108 3.883-.881 4.745z"
                    fill="#edba68"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 808"
                    d="M162.456 367.467c-1.751.193-11.256.735-11.618.86s-4.872 3.772-4.872 3.772l-2.341 6.708a4.948 4.948 0 003.4-4.323l2.5-1.6-1.084 2.568s-2.287 7.577-2.5 7.893 1.639-.335 2.275-1.2a34.025 34.025 0 001.947-3.831s-.927 4.273-1 4.78a1.771 1.771 0 001.589-.721l.019.337a4.827 4.827 0 002.408-1.222 4.9 4.9 0 001.305-1.978s2.479-1.429 2.867-1.722a23.957 23.957 0 013.688-1.435c.989-.3 4.182-1.887 4.566-2.483a8.948 8.948 0 00.711-2.057z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 809"
                    d="M151.669 373.791a17.287 17.287 0 00-1 2.568 15.594 15.594 0 00-.5 2.57"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 810"
                    d="M150.801 382.347c.634-1.078 1.635-3.389 1.951-3.927a27.3 27.3 0 012.728-2.6"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 811"
                d="M204.828 356.651c-13.861 14.057-43.014 19.1-43.014 19.1l-1.182-7.024s24.274-15.179 31.205-19.32z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 812"
                d="M220.968 312.42c4.529 8.873-11.578 37.834-16.14 44.231l-16.142-5.478c3.623-7.215 12.773-32.815 14.273-35.748 2.565-5.018 15.179-8.55 18.009-3.005z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 813"
                d="M218.329 307.76c7.357 3.767 6.091 15.081-5.805 34.535l-18.972-11.344c5.693-11.316 12.642-29.4 24.777-23.191z"
                fill="#fff"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
                fillRule="evenodd"
            />
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 86"
                fillRule="evenodd"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 814"
                    d="M311.876 466.713l-7.938 9.233-14.323-11.193 7.938-9.234z"
                    fill="#f2d291"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 85">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 815"
                        d="M233.337 358.656c4.633 25.573 10.1 51.619 16.863 75.3 17.169 15.1 25.485 20.086 40.358 31.535l7.882-9.278c-13.421-12.989-18.744-23.393-29.416-31.957.582-17.962 3.5-34.392.426-50.449-1.386-7.245-8.287-15.42-9.7-23.021z"
                        fill="#f2d291"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 816"
                        d="M314.411 468.692l-16.868 19.616s-3.384-1.589-3.364-3.5c.056-4.8 2.478-8.134 3.671-11.738.334-1.008.943-1.61 2.911-2.306l4.683-1.652 3.833-4.429z"
                        fill="#f66f82"
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 817"
                    d="M297.849 473.069l-2.911-3.409s3.075-2.47 4.7-1.791 2.72 4.384 2.72 4.384z"
                    fill="#f66f82"
                />
            </g>
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 88"
                fillRule="evenodd"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 818"
                    d="M233.356 425.592c1.292 14.753-3.1 34.377-6.39 51.527-4.978-.391-7.219.183-12.2-.208-.733-18.129-.551-35.742-.879-54.382 2.7-19.327 5.373-55.452 9.106-75.18l26.36 5.312c-.464 6.154-12.435 60.979-15.997 72.931z"
                    fill="#f2d291"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 87">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 819"
                        d="M225.27 495.219l-12.058-1.7 1.7-18.1 12.056 1.7z"
                        fill="#f2d291"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 820"
                        d="M224.971 498.422l-25.616-3.621s-.49-3.708 1.136-4.722c4.072-2.547 8.185-2.3 11.861-3.243 1.028-.262 1.864-.077 3.511 1.207l3.918 3.052 5.8.839z"
                        fill="#f66f82"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 821"
                        d="M212.352 486.836l1.3-4.29s3.738 1.255 4.045 2.994-2.223 4.656-2.223 4.656z"
                        fill="#f66f82"
                    />
                </g>
            </g>
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 89">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 822"
                    d="M135.396 452.801a20.994 20.994 0 004.837 13.458c3.193 3.5 5.358 4.82 5.782 10.568a57.214 57.214 0 01.143 8.985s-5.929.922-3.893 4.356c2.493 1.116 7.76.04 7.76.04s2.6-2.516 2.744-4.006.357-8.589.646-11.235.6-3.808-1.1-6.586 2.983-7.782 4.612-13.452-18.469-17.2-21.531-2.128z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 823"
                    d="M99.758 433.865c-3.173 2.8-5.983 8.9-8.165 14.634s-1.98 6.226-5.915 10.434a57 57 0 01-6.5 6.2s-4.7-3.729-5.84.1c.871 2.589 5.235 5.726 5.235 5.726s3.615.2 4.8-.7 6.484-5.665 8.69-7.151c10.843-7.305 3-1.882 17.106-11.017 4.955-3.21 3.737-26.208-9.411-18.226z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 824"
                    d="M98.673 401.26c-2.217-.546-8.15-1.727-11.882 1.087s-3.85 6.659-4.595 8-5.618 3.737-6.852 5.37c-2 2.657 3.9 5.678 6.777 5.834.972 1.087-.482 3.332-2.339 4.8-2.4 1.9.792 2.96 2.977 3.083s2.6-1.571 6.926-2.859 1.677 12.3 1.46 14.877 2.875 13.716 19.618 14.819c19.676 1.3 14.173-2.46 35.676 3.519 2.641.733 7.749 7.3 14.223 5.709s7.255-9.833 5.638-15.623-4.118-11.937-12.4-15.908-11.05-4.83-26.032-5.2-16.93-6.515-18.565-8.852-.55-14.657-10.63-18.656z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 825"
                    d="M82.121 421.547a34.093 34.093 0 009.332-1.045c4.456-1.253-1.06 3.75-5.03 4.71s-6.434 3.673-8.343 6.937-5.535.575-3.983-2.364 3.883-6.788 8.024-8.238z"
                    fill="#f66f82"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 826"
                    d="M97.351 404.945c.868-3.792.052-12.017-1.359-14.715s4-.538 6.268 3.752 5.219 10.037 3.15 13.953-8.059-2.99-8.059-2.99z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 827"
                    d="M88.251 405.924c-1.111-2.643-4.39-10.228-6.393-11.238s2.167-2.04 5.366-.441 6.044 3.652 6.488 6.887-5.461 4.792-5.461 4.792z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 828"
                    d="M139.137 455.13c.985 4.116 8.362 10.267 14.8 12.683 4.675 1.756 6.526 2.83 8.4 8.277a57.113 57.113 0 012.431 8.651s-5.5 2.4-2.653 5.206c2.7.442 7.515-1.94 7.515-1.94s1.87-3.1 1.627-4.573-1.843-8.4-2.24-11.029-.389-3.835-2.74-6.089.47-7.641.6-13.541-28.625-13-27.74 2.355z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 829"
                    d="M99.248 442.389c-2.418 3.472-3.7 10.068-4.456 16.155s-.445 6.517-3.264 11.541a57.007 57.007 0 01-4.843 7.571s-5.451-2.506-5.647 1.481c1.461 2.308 6.445 4.319 6.445 4.319s3.559-.663 4.5-1.826 4.953-7.041 6.742-9.012c8.8-9.671 2.466-2.539 14-14.765 4.045-4.291-2.605-26.342-13.477-15.464z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 830"
                    d="M158.321 437.137c6.7 2.5 14.657.544 16.273-6.029a7.111 7.111 0 00-7.12-9.06c5.589-4.961 21.775 3.6 13.259 18.7-3.96 7.024-12.65 5.859-16.338 3.5-6.02-3.868-6.074-7.111-6.074-7.111z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 831"
                    d="M91.488 427.588c5.385.929 14.7-4.093 17.938-8.341l2.747 3.814c-3.49 4.569-14.5 10.284-20.215 9.3z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 832"
                    d="M118.069 431.733c-4.2-3.193-9.658-.916-11.535 1.916-2.915 4.4-6.266 2.385-8.64 5.044-3.908 4.367-2.6 18.208 8.069 11.368 4.658-2.988 5.559-6.538 11.258-6.617s5.979-7.807.848-11.711z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 833"
                    d="M93.749 400.551c-2.948 2.065-4.961 4.44-5.624 7.917-.742 3.9 1.739 3.87 1.269 7.089-.424 2.925 12.289 7.749 19.647 3.858-1.292-3.228-.866-14.385-10.367-18.155a24.248 24.248 0 00-4.925-.709z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 834"
                    d="M166.935 456.919a19.542 19.542 0 00-.636-7.051c-1.618-5.79-4.118-11.935-12.4-15.908-7.641-3.665-10.585-4.677-22.734-5.1-3.53 3.819-5.341 9.712-1.209 13.022 5.776 4.629 7.568 2.356 10.853 9.091s9.3 13.4 16.564 11.975a15.865 15.865 0 009.562-6.029z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 835"
                    d="M145.945 455.567a19.4 19.4 0 001.785 4.558c2.8 2.261 6.067 3.521 9.64 2.821a15.757 15.757 0 008.9-5.215 29.868 29.868 0 00.6-4.957c.133-5.896-21.81-12.56-20.925 2.793z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 836"
                    d="M93.491 408.307a2.356 2.356 0 102.036 2.273 2.173 2.173 0 00-2.036-2.273z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 837"
                    d="M75.039 415.718c.939-.426 3.816-.3 4.222.9.281.833-.181 1.259-.9 1.35s-.719 1.083-1.168 1.8-4.301-3.077-2.154-4.05z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 838"
                    d="M169.878 419.655c5.107-1.6 13.61 2.961 13.716 11.364.122 9.827-9.5 17.859-18.766 11.919"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 839"
                d="M261.681 304.432c-6.611-3.848-15.613-7.3-23.73-7.06a48.392 48.392 0 00-9.509.735c-9.054 2.063-14.387 4.072-17.705 6.611-7.176 5.495-4.957 15.368-4.662 22.379 1.726 40.8-2.676 47.183 2.418 87.735 28.678 8.267 54.265-4.431 75.215-8.821-.387-33.157-28.139-50-22.027-101.579z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 840"
                d="M288.663 317.42c-20.929 2.547-21.071-13.737-28.394-14.613"
                fill="none"
                stroke="#4247ca"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 841"
                d="M221.373 305.72c1.735 9.638 16.49 4.808 19.666-4.516l-1.384-22.593-17.95 4.08q-.165 11.517-.332 23.029z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 842"
                d="M240.559 293.361l-.9-14.75-2.6.592c-5.235 2.6-10.17 10.047-15.473 11.611l-.162 11.314c1.641 1.573 4.049 2 7.54.252 3.474-1.729 8.347-5.789 11.595-9.019z"
                fill="#e0bd89"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 843"
                d="M232.581 248.797c4.076.675 16.338 2.844 14.306 18.841s-5.208 20.886-7.485 23c-2.913 2.705-7.392 5.516-17.5 5.429-5.749-1.136-8.586-5.327-9.927-8.983-1.07-2.917-3.181-8.622-.719-24.559s17.222-14.204 21.325-13.728z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 844"
                d="M222.019 269.953c-.083 3.2-2.871 4.853-2.927 6.031-.077 1.608 2.391 1.793 2.391 1.793"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 845"
                d="M224.119 283.008c-3.791.96-6.713-.324-7.758-1.284"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 846"
                d="M234.019 266.928a13.446 13.446 0 00-5.146-1.766"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 847"
                d="M243.659 283.173c-1.463 4.276-3 6.295-4.257 7.465-2.913 2.7-7.392 5.516-17.5 5.429"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 848"
                d="M213.59 264.371a13.481 13.481 0 015.425.372"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 849"
                d="M230.082 271.403c.989.147 1.463 1.24 1.06 2.439a2.586 2.586 0 01-2.522 1.9c-.987-.147-1.461-1.24-1.059-2.439a2.588 2.588 0 012.521-1.9z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 850"
                d="M221.387 285.986a20.2 20.2 0 00-2.921-.605"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 851"
                d="M215.541 269.086c.989.147 1.463 1.238 1.06 2.439a2.587 2.587 0 01-2.522 1.9c-.987-.147-1.461-1.24-1.059-2.439a2.589 2.589 0 012.521-1.9z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 852"
                d="M218.498 252.704c-3.681-.418-8.983 8.672-8.375 17.142-2.68-14.215 3.84-24.73 19.676-24.555s19.55 14.227 16.712 26.337c-3.449-6.76-8.686-5.759-12.9-7.346-11.615-4.378-9.436-11.645-15.113-11.578z"
                fill="#4247ca"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 853"
                d="M250.779 259.478a2.046 2.046 0 00-2.823.588l-4.953 7.544a2.047 2.047 0 00.586 2.825 2.045 2.045 0 002.825-.586l4.951-7.546a2.043 2.043 0 00-.586-2.825z"
                fill="#f66f82"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 854"
                d="M246.685 256.745a2.045 2.045 0 00-2.823.586l-4.953 7.544a2.046 2.046 0 00.586 2.825 2.045 2.045 0 002.824-.586l4.951-7.544a2.044 2.044 0 00-.585-2.825z"
                fill="#f66f82"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 855"
                d="M244.06 272.729a4.1 4.1 0 013.174-1.97c1.523-.1 4.109 1.03 3.511 5.229s-4.606 7.315-6.75 6.5.065-9.759.065-9.759z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 856"
                d="M245.41 275.008c.075-.524 2.458-2.67 3.972-.767"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.425}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 857"
                d="M232.997 277.447c-2.063.908-3.3 2.632-2.765 3.848s2.641 1.465 4.706.557 3.3-2.632 2.763-3.848-2.643-1.465-4.704-.557z"
                fill="#f66f82"
                fillRule="evenodd"
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 95">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 858"
                    d="M384.962 108.612c5.3 13.5-11.755 51.282-21.382 78.124s-32.942 76.335-32.942 76.335l-8.746 1.166c2.921-17.08 18.625-68.139 22.575-79.35s18.137-62.247 31-77.247c-.004 0 6.605-1.851 9.495.972z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 90">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 859"
                        d="M331.958 262.141c-1.363 2.163-3.519 7.163-4.745 8.792-.4.53-1.334 1.419-3.538 3.69a54.162 54.162 0 00-3.848 5.273 2.335 2.335 0 01-.744-2.1 12.561 12.561 0 011.967-4.568c.914-1.06 2.47-2.6 2.721-3.041-1.809.665-3.519 1.516-3.519 1.516-1.247 1.544-2.649 3.682-3.532 4.847a31.179 31.179 0 01-.891 3.33 5.522 5.522 0 01-.357 2.865 4.778 4.778 0 01-1.548-.808.975.975 0 01-.449.671l-1.577-2.156-.544-2.584-.706-.873-.26-5.514.15-2.78 4.477-4.729s7.9-5.478 8.967-6.407c.27-.235 1.107-.964 2.237-1.945z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 860"
                        d="M313.918 281.94l-.563-1.19.478-5.713 3.6-4.669"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 861"
                        d="M311.02 277.677l.5-4.159 3.2-4.276"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 862"
                    d="M408.746 193.718c-9.085 0-18.322-.006-27.405-.006a234.855 234.855 0 002.614-44.2c-3.123-4.849-6.214-7.24-7.375-11.514-1.267-4.65.241-12.586 1.128-15.486-.769-3.818-1.77-10.951-2.246-14.875 7.151-7.022 19.945-12.968 33.284-12.968s26.133 5.946 33.284 12.968c-1.9 15.623-6.889 30.1-8.792 45.721-.459 14.335-.827 26.433 1.481 40.352-9.082.002-16.888.008-25.973.008z"
                    fill="#f66f82"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 863"
                    d="M419.446 152.936a155.56 155.56 0 00-28.841-4.972"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 864"
                    d="M449.67 353.02l-27.241-1.849c-5.757-55.905-22.961-95.222-31.651-150.824l37.061-3.254c24.468 33.938 23.968 114.042 21.831 155.927z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 865"
                    d="M450.006 345.785c12.5 6.771 29.636 83.49 40.865 114.014.565 1.539 3.551 2.15 4.072 3.444l-2.075 8.664-15.226 10.595c-1.278-2.94.328-3.6 1.909-7.273.143-.332.567-1.567 1.024-3.066.74-2.431 1.575-5.555 1.454-6.629-15.073-34.94-37.09-74.947-59.193-114.485z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 866"
                    d="M477.642 482.499c-1.278-2.94.328-3.6 1.909-7.273.143-.332.567-1.567 1.024-3.066.74-2.431.133 4.136.014 3.062l7.81-4.213 6.544-7.77 3.5 8.044-20.801 11.216zm0 0l.314.721 20.8-11.215-.314-.721z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 867"
                    d="M378.655 292.328c-.035 17.518.241 35.47.586 53.439l24.681 1.886c2.863-31.107 1.078-38.075 3.442-69.621l4.519-73.562-27.723-.239c-4.663 46.71-5.463 67.056-5.505 88.097z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 91"
                    fillRule="evenodd"
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 868"
                        d="M379.24 345.77c8.507 44.115 17.474 88.26 22.153 126.662l-12.607 8.946.129 3.918 22.444-4.2 2.555-5.722c-3.3-43.049 6.168-111.13-10.03-131.953z"
                        fill="#f2d291"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 869"
                        d="M415.171 483.034l-35.3 5.788a1.976 1.976 0 01.952-2.724l7.967-4.72c1.421 2.061 19.629-.922 22.117-3.222l3.012-2.778z"
                        fill="#2f2e6b"
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 870"
                    d="M449.82 252.784c-1.253 38.171 33.881 41.218 32.628 79.387-29.532-2.535-82.809 16.053-109.362.844l-8.375-80.231c.947-21.881 6.012-45.222 15.492-59.071h55.446c9.424 13.852 13.324 37.19 14.171 59.071z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 871"
                    d="M424.506 328.836c-3.873-17.156-34.317-63.1-40.4-58.115s13.284 25.46 8.855 29.886-9.962-7.747-12.177-10.515"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 872"
                    d="M380.202 193.712h55.448l-1.583-4.426h-52.05z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 873"
                    d="M384.584 34.673c2.53-8.792 20.179-14.1 28.151-13.465 16.089 1.28 20.092 8.427 35.127 17.981 14.877 9.451 34.986.457 47.9 17.888 8.709 11.759 3.26 32.966-11.647 36.1-17.645 3.708-24.771-8.817-31.136-8.684s-10.9 6.345-20.48 4.529c-17.909-3.4-23.252-15.594-23.252-15.594-3.719-.833-6.255 10.2-16.678 11.562-11.219 1.465-20.516-8.817-22.558-17.134-4.264-17.371 4.651-32.242 14.573-33.183z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 874"
                    d="M388.095 32.663c6.416-7.369 23.994-14.566 44.684-7.057s19.951 18.326 44.108 16.286"
                    fill="none"
                    stroke="#4247ca"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 875"
                    d="M418.555 96.677c0 6.9-15.432 34.857-21.039 33.594-3.87-.871 1.421-28.83 1.421-33.594v-18.86c6.54 0 13.08-10.836 19.618-10.836z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 876"
                    d="M403.255 34.237c-4.749.507-19.041 2.181-17.875 18.912s4.136 22.074 5.772 25.03c1.878 3.388 5.131 7.776 10.2 7.417 10.918-.771 14.786-5.8 17.4-9.243 2.046-2.69 5.1-9.121 3.436-25.811s-14.167-16.606-18.933-16.305z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 877"
                    d="M411.054 63.422c-2.094.831-3.4 2.506-2.907 3.742s2.586 1.564 4.679.733 3.4-2.507 2.907-3.74-2.585-1.566-4.679-.735z"
                    fill="#f66f82"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 878"
                    d="M390.186 59.14a1.945 1.945 0 011.866 1.866 1.759 1.759 0 01-1.39 2.119 1.945 1.945 0 01-1.864-1.868 1.755 1.755 0 011.388-2.117z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 879"
                    d="M405.665 57.827a1.944 1.944 0 00-1.525 2.154 1.757 1.757 0 001.724 1.855 1.948 1.948 0 001.527-2.156 1.758 1.758 0 00-1.726-1.853z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 880"
                    d="M393.782 55.027c4.147 3.548 2.784 11.63 2.4 13.342s2.048 1.242 2.048 1.242"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 881"
                    d="M394.178 73.934s3.361 2.1 8.508-.812"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 882"
                    d="M397.527 76.947a3.162 3.162 0 003.154-.3"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 883"
                    d="M402.819 53.864s2.028-1.808 5.84-.436"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 884"
                    d="M392.787 54.718s-2.3-1.44-5.83.555"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 885"
                    d="M404.469 85.528c4.967-.773 11.2-3.76 15.571-11.7"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 886"
                    d="M388.699 43.844c-3.42.669-3.241 8.472-3.021 14.541-3.478-15 2.582-26.541 19.336-27.075s28.1 6.571 22.381 25.439c-17.057 8.682-16.878-17.168-38.696-12.905z"
                    fill="#4247ca"
                    fillRule="evenodd"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 92">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 887"
                        d="M420.55 58.738a2.963 2.963 0 012.152-2.375c1.357-.409 3.924.083 4.249 3.995s-2.651 7.554-4.755 7.263-1.754-7.825-1.646-8.883z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 888"
                        d="M423.544 66.779a1.217 1.217 0 101.315 1.111 1.219 1.219 0 00-1.315-1.111z"
                        fill="#f66f82"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 889"
                        d="M422 60.569c-.04-.488 1.666-2.917 3.426-1.512"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.425}
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 94">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 890"
                        d="M442.031 107.639c11.111 9.316 13.3 52.67 17.69 80.848s7.585 82.794 7.585 82.794l-7.12 5.206c-5.6-16.4-16.24-68.746-18.131-80.48s-8.973-51.251-8.973-51.251 1.543-20.029 8.949-37.117z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 93">
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 891"
                            d="M458.568 271.549c-.5 2.508-2.385 7.616-2.616 9.642-.077.661-.012 1.949.021 5.113a54.153 54.153 0 01-.843 6.472 2.335 2.335 0 001.984-1.008 12.532 12.532 0 001.722-4.662c.066-1.4 0-3.586.121-4.08a40.042 40.042 0 011.508 3.522c-.162 1.978-.617 4.494-.779 5.944a31.34 31.34 0 00-1.648 3.029 5.553 5.553 0 00-1.716 2.323 4.757 4.757 0 001.681.48.972.972 0 00-.139.8l2.63-.476 2.175-1.5 1.111-.148 3.989-3.816 1.807-2.121.012-6.513s-1.953-9.411-2.088-10.82c-.033-.357-.135-1.461-.278-2.95z"
                            fill="#f2d291"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 892"
                            d="M458.003 298.326l1.227-.474 3.59-4.47.608-5.863"
                            fill="none"
                            stroke="#2f2e6b"
                            strokeMiterlimit={22.926}
                            strokeWidth={0.567}
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 893"
                            d="M463.044 297.235l2.505-3.359.625-5.3"
                            fill="none"
                            stroke="#2f2e6b"
                            strokeMiterlimit={22.926}
                            strokeWidth={0.567}
                        />
                    </g>
                </g>
            </g>
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 13"
                fill="#f2d291"
                d="M560.264 478.258h14.871v17.379h-14.871z"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 894"
                d="M547.685 503.051c0-1.488-.214-4.67 1.7-5.732 1.394-.775 2.306-.407 3.129-.656 1.008-.3 6.642-7.259 7.753-8.543h10.307l4.564 4.774v10.157z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 895"
                d="M577.073 214.636c10.606 93.945 8.478 189.723 2.861 270h-23.7c-8.514-77.339-17.03-167.529-19.7-269.987z"
                fill="#4247ca"
                fillRule="evenodd"
            />
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 14"
                fill="#2f2e6b"
                d="M555.59 478.61h25.915v6.387H555.59z"
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 96">
                <path
                    data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 15"
                    fill="#f2d291"
                    d="M529.917 480.409l-6.78-16 16.904-7.163 6.78 16z"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 896"
                    d="M549.242 478.962l-47.85 20.273s-1.515-4.2.58-6.008 18.3-13.086 18.3-13.086 3.3-5.289 4.294-6.63 2.37-1.556 5.429-1.149l7.274.972 8.14-3.415z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 897"
                    d="M546.248 458.728l-25.233 5.9 2.631 5.72 24.059-5.398z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
            </g>
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 97"
                fillRule="evenodd"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 898"
                    d="M501.78 214.636c-5.99 48.706-11.084 98.685-13.33 145.285l34.722 1.674c10.9-36.16 18.625-65.328 22.512-98.995 1.637-14.179-4.124-33.116-2.346-47.964z"
                    fill="#4247ca"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 899"
                    d="M534.807 270.377s-23.609 82.981-23.73 90.244l-.006.391 12.1.582c7-23.225 12.692-43.564 16.9-64.065h-.064z"
                    fill="#2f2e6b"
                />
            </g>
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 98"
                fillRule="evenodd"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 900"
                    d="M488.513 358.602c.283.983-.511 2.131-.227 3.11 11.094 38.052 23.917 72.506 34.782 103.144l22.084-4.049c-2.4-39.912-14.721-80.089-24.016-111.011z"
                    fill="#4247ca"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 901"
                    d="M512.723 352.068c2.811 8.441 28.57 98.177 27.957 104.444s.694 3.677 2.264 4.7l2.208-.4c-2.4-39.912-14.721-80.089-24.016-111.011z"
                    fill="#2f2e6b"
                />
            </g>
            <path
                data-name="\u041B\u0438\u043D\u0438\u044F 3"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
                d="M540.018 297.77l-6.404-33.656"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 902"
                d="M573.024 480.119c1.77-15.353-.592-112.782-2.362-148.8"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 903"
                d="M492.767 100.583c-12.384 3.893-20.888 79.046-20.888 106.225s-3.87 58.329-5.73 68.09l9.494 5.094c12-25.142 16.271-54.047 18.168-66.056s6.372-56.1 6.372-56.1.171-40.186-7.416-57.253z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 904"
                d="M492.767 100.583c-9.21 2.9-20.553 41.721-24.1 74.266l29.364 3.233c1.7-10.891 2.156-20.248 2.156-20.248s.167-40.184-7.42-57.251z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 905"
                d="M582.924 250.439c-34.581-6.717-67.938 15.877-86.64 0l-3.52-149.856c12.667-7.053 28.616-10.776 46.658-10.776s33.991 3.723 46.658 10.776l-7.716 59.607c1.724 33.135 2.838 57.112 4.56 90.249z"
                fill="#fff"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 906"
                d="M586.083 100.583c12.384 3.893 22.78 78.573 22.78 105.751s-3.792 74.584-3.792 74.584H594.46s-6.1-61.119-8-73.128-7.791-49.955-7.791-49.955-.169-40.185 7.414-57.252z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 907"
                d="M524.584 90.671c0 21.095 29.688 21.095 29.688 0l.04-35.728-26.946 3.8z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 99">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 908"
                    d="M594.442 280.519c-.881 3-3.725 9.008-4.226 11.447-.164.794-.224 2.371-.524 6.224a66.666 66.666 0 01-1.728 7.785 2.85 2.85 0 002.522-1.012 15.3 15.3 0 002.6-5.489c.233-1.7.386-4.361.59-4.949a48.846 48.846 0 011.454 4.45c-.411 2.387-1.236 5.4-1.593 7.149a38.647 38.647 0 00-2.331 3.505 6.767 6.767 0 00-2.337 2.641 5.777 5.777 0 001.992.767 1.169 1.169 0 00-.255.952l3.249-.293 2.811-1.591 1.365-.06 5.267-4.211 2.427-2.383.717-7.922s-1.357-11.667-1.369-13.394c0-.438-.008-1.791-.019-3.619z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 909"
                    d="M590.804 313.066l1.543-.446 4.851-5.05 1.374-7.068"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 910"
                    d="M597.052 312.281l3.411-3.815 1.336-6.386"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 100">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 911"
                    d="M475.645 279.993c-1.143 2.908-2.589 9.4-3.69 11.63-.359.729-1.278 2.009-3.4 5.237a66.413 66.413 0 00-3.417 7.205 2.846 2.846 0 01-1.371-2.346 15.339 15.339 0 011.319-5.931c.858-1.481 2.372-3.677 2.572-4.267a48.676 48.676 0 00-3.877 2.62c-1.143 2.136-2.341 5.021-3.133 6.621a38.606 38.606 0 01-.31 4.2 6.793 6.793 0 01.224 3.521 5.771 5.771 0 01-2.044-.617 1.175 1.175 0 01-.384.908l-2.389-2.23-1.242-2.979-1.043-.885-1.573-6.561-.453-3.37 4.3-6.694s8.233-8.373 9.3-9.733c.27-.343 1.1-1.409 2.238-2.846z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 912"
                    d="M458.51 307.863l-.946-1.3-.729-6.965 3.254-6.422"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 913"
                    d="M454.058 303.408l-.352-5.106 2.868-5.859"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 914"
                d="M550.192 187.821s-39.7-20.713-52.647-37.113"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 915"
                d="M520.308 189.955s-13.517-14.229-17.786-24.189"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 916"
                d="M586.085 100.583c9.212 2.9 20.553 41.721 24.106 74.266l-29.366 3.233c-1.695-10.891-2.154-20.244-2.154-20.244s-.171-40.188 7.414-57.255z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 101">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 917"
                    d="M542.576 19.654c5.111 1.07 20.474 4.471 17.05 24.561s-7.7 26.09-10.693 28.639c-3.821 3.256-9.629 6.565-22.388 5.909-7.2-1.741-7.6-6.309-9.1-11-1.2-3.74-3.519-11.258.447-31.25s19.529-17.682 24.684-16.859z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 918"
                    d="M554.567 33.88c1.623 1.431-4.839 16.473 3.677 16.878 6.762-7.388 9.434-17.628 3.058-22.976-.168-7.575-6.887-11.811-16.128-12.985-5.331-.675-11.969 3.2-18 2.287-9.426-1.421-13.226 3.785-10.714 9.521 1.093 2.493 5.958 3.723 8.225 3.291 3.162-.6 10.247 5.992 15.968 6.682 6.515.782 9.3-2.301 13.914-2.698z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 919"
                    d="M556.472 52.601a4.379 4.379 0 013.478-1.959c1.631-.041 4.338 1.288 3.509 5.742s-5.248 7.6-7.5 6.632.513-10.415.513-10.415z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 920"
                    d="M537.031 47.949c-.987-.174-1.791.69-1.8 1.93a2.533 2.533 0 001.78 2.56c.987.174 1.791-.69 1.795-1.93a2.535 2.535 0 00-1.775-2.56z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 921"
                    d="M557.812 55.1c.1-.557 2.744-2.738 4.271-.64"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.425}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 922"
                    d="M520.773 45.375a2.831 2.831 0 011.934 2.616c.081 1.255-.656 2.119-1.643 1.93a2.838 2.838 0 01-1.934-2.618c-.078-1.252.655-2.119 1.643-1.928z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 923"
                    d="M525.037 42.03a3.143 3.143 0 011.456 2.767c-.28 4.041-2.977 11.379-3.112 12.87-.181 2.026 2.923 2.389 2.923 2.389"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 924"
                    d="M542.033 45.043c-1.138-.659-4.652-1.08-6.81-.949"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 925"
                    d="M556.718 25.032c.889 6.717-4.945 14.4-12.436 14.586"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 926"
                    d="M518.409 41.432c1.263-.359 4.772.1 6.831.762"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 927"
                    d="M524.569 60.728c5.117.812 9.233 1.94 11.74 5.676s17.7-4.83 19.616-16.926 4.215-5.535 2.643 1.371-2.861 15.05-8.46 21.8-15.586 10.789-24.195 9.426c-10.087-1.6-12.212-12.895-10.242-26.51.739 1.918.914 8.607 2.119 7.109 1.585-1.969 4.541-2.451 6.779-1.942zm6.677 5.74c-.442-2.466-11.233-4.076-12.3-1.861s2.19 7.263 5.52 7.315 7.447-1.746 6.78-5.454z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 928"
                    d="M529.83 66.248s-3.933 1.955-9.291-1.812"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 929"
                    d="M527.807 68.309a12.11 12.11 0 01-4.3.283"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 103">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 102">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 930"
                        d="M324.342 271.637a1.967 1.967 0 00.26-1.427c-.071-.266-5.078 1.136-5.078 1.136s3.796.837 4.818.291z"
                        fill="#edba68"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 931"
                        d="M308.643 282.227c.609-1.654 3.432-10.745 3.638-11.067s4.839-3.814 4.839-3.814l7.076-.652a12.207 12.207 0 01-2.138 1.872 12.177 12.177 0 01-2.877.378l-2.156 2.042 2.755-.43s7.9-.391 8.266-.522-.723 1.512-1.714 1.918a33.818 33.818 0 01-4.188.964s4.371.133 4.878.187c.048.386-.451 1.087-1.082 1.365l.324.1a4.844 4.844 0 01-1.77 2.042 4.9 4.9 0 01-2.235.789s-1.986 2.063-2.362 2.366a23.858 23.858 0 00-2.283 3.233c-.528.889-2.842 3.6-3.513 3.831a8.977 8.977 0 01-2.169.195z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 932"
                        d="M317.386 273.293a17.147 17.147 0 012.73-.355 15.558 15.558 0 012.618.137"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 933"
                        d="M325.898 274.511c-1.2.357-3.685.767-4.282.945a27.286 27.286 0 00-3.183 2.019"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 934"
                    d="M300.214 322.988c14.443-13.46 16.334-40.439 16.334-40.439l-6.987-1.38s-15.3 22.2-19.639 29.015z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 935"
                    d="M246.501 312.052c4.188 9.039 39.8 13.244 53.713 10.934l-4.421-13.629c-7.913-1.6-32.588-9-35.819-9.627-5.534-1.074-16.093 6.67-13.473 12.322z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 936"
                    d="M244.459 307.099c-1.573 8.115 8.113 14.094 30.762 16.731l2.773-21.933c-12.417-2.495-30.938-8.183-33.535 5.202z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
            </g>
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 104">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 937"
                    d="M591.216 296.946a2.255 2.255 0 01-.3-1.637c.081-.3 5.827 1.3 5.827 1.3s-4.351.959-5.527.337z"
                    fill="#edba68"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 938"
                    d="M609.236 309.098c-.7-1.9-3.939-12.334-4.176-12.7s-5.557-4.38-5.557-4.38l-8.121-.744a14.154 14.154 0 002.452 2.15 14.1 14.1 0 003.3.432l2.474 2.345-3.16-.5s-9.075-.445-9.488-.6.831 1.733 1.967 2.2a38.859 38.859 0 004.8 1.107s-5.015.148-5.6.212a2.029 2.029 0 001.244 1.566l-.37.116a5.542 5.542 0 002.03 2.345 5.621 5.621 0 002.564.906s2.279 2.366 2.711 2.715a27.352 27.352 0 012.622 3.711c.6 1.018 3.26 4.134 4.03 4.4a10.234 10.234 0 002.491.222z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 939"
                    d="M599.199 298.839a19.66 19.66 0 00-3.135-.407 17.522 17.522 0 00-3 .158"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 940"
                    d="M589.431 300.241c1.373.409 4.226.879 4.915 1.085a31.1 31.1 0 013.652 2.318"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 941"
                d="M620.591 355.785c-16.577-15.447-20.429-46.315-20.429-46.315l8.021-1.585s17.566 25.487 22.541 33.3z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 942"
                d="M653.265 301.671c4.8 10.377-17.958 44.673-30.311 55.138l-8.649-13.99c4.475-8.115 16.321-35.208 18.17-38.5 3.168-5.642 17.788-9.134 20.79-2.648z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 943"
                d="M650.444 296.209c8.271 4.645 6.314 17.566-8.194 39.349l-21.256-13.863c7.035-12.716 15.805-33.15 29.45-25.486z"
                fill="#fff"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 944"
                d="M668.909 430.95c-2.36 20.036-5.638 39.455-9.407 59.142-5.717-.449-12.276-.966-17.989-1.417-.843-20.809-1.751-40.95-2.127-62.345l-.04-.006.035-.328v-.083h.01c2.676-25.78 6.363-53.324 12.523-85.879l30.257 6.1a547.456 547.456 0 01-13.176 84.828z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 945"
                d="M668.909 430.95c-2.36 20.036-5.638 39.455-9.407 59.142l-3.152-.247c1.353-15.44 4.7-53.538 5.41-61.325.893-9.821 10.132-36.012 10.132-36.012l4.88-.709a556.317 556.317 0 01-7.78 39.162z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 946"
                d="M663.779 353.015c5.319 29.354 11.587 59.252 19.356 86.432l25.107-8.56c.854-22.674.764-40.732-3.012-60.479-1.591-8.32-9.511-17.7-11.129-26.424z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 947"
                d="M683.134 439.447c19.706 17.335 28.055 23.073 45.13 36.214l12.422-11.674c-14.908-22.086-25.4-31.776-41.594-45.743z"
                fill="#8fb2ee"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 948"
                d="M753.926 477.046l-9.113 10.6-16.441-12.848 9.113-10.6z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 949"
                d="M756.837 479.321l-19.361 22.515s-3.887-1.828-3.862-4.024c.062-5.514 2.844-9.336 4.211-13.473.384-1.155 1.082-1.847 3.343-2.645l5.375-1.9 4.4-5.082z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 950"
                d="M738.009 459.65l-14.473 13.336 8.1 6.146 14.031-12.449z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 951"
                d="M737.826 484.339l-3.341-3.912s3.53-2.836 5.4-2.053 3.123 5.03 3.123 5.03z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 952"
                d="M643.503 380.595c25.666 9.278 41.061-7.564 64.273-6.993l-3.875-81.044c-7.527-4.523-17.8-8.622-27.122-8.483-3.528.056-7.238-.1-10.926.692-10.423 2.223-16.579 4.444-20.424 7.305-8.325 6.193-5.936 17.559-5.709 25.61.742 26.286 3.444 50.736 3.783 62.913z"
                fill="#f66f82"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 953"
                d="M657.618 293.392c1.839 11.09 18.852 5.784 22.643-4.866l-1.228-25.951-20.667 4.4q-.373 13.209-.748 26.417z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 954"
                d="M679.835 279.516l-.8-16.942-2.987.636c-6.05 2.907-11.834 11.368-17.942 13.08l-.368 12.981c1.857 1.834 4.614 2.356 8.651.411 4.01-1.93 9.669-6.509 13.446-10.166z"
                fill="#e0bd89"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 955"
                d="M670.038 226.588c4.942.887 19.8 3.729 17.059 23.127s-6.679 25.28-9.48 27.812c-3.582 3.235-9.07 6.575-21.345 6.3-6.964-1.473-10.338-6.611-11.908-11.077-1.251-3.559-3.717-10.527-.461-29.844s21.159-16.966 26.135-16.318z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 956"
                d="M686.74 261.55c12.37-1.5 15.388-24.544 3.619-27.135.164-8.994-6.993-12.91-15-12.293-6.544.505-14.694-3.027-19.608-4.33-8.935-2.364-17.449 5.049-11.335 10.477-1.332-.4-7.633.361-7.683 4.849-.075 6.943 8.749 7.533 15.833 8.212 12.023 1.151 19.109-.958 24 1.693 5.51 2.99 6.617 5.859 7.98 9.841s1.906 8.648 2.194 8.686z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 957"
                d="M683.579 255.846a4.97 4.97 0 013.885-2.337c1.855-.1 4.974 1.319 4.178 6.409s-5.717 8.805-8.308 7.783.245-11.855.245-11.855z"
                fill="#f2d291"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 958"
                d="M685.184 258.643c.1-.638 3.027-3.2 4.834-.868"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.425}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 959"
                d="M656.853 252.107c-.156 3.889-3.569 5.844-3.656 7.278-.12 1.949 2.875 2.213 2.875 2.213"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 960"
                d="M659.181 267.998c-4.685.65-7.546-.218-9.416-2.452"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 961"
                d="M671.479 248.633c-.939-.844-4.163-1.932-6.218-2.231"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 962"
                d="M682.917 268.53c-1.849 5.169-3.748 7.6-5.3 9-3.582 3.235-9.069 6.575-21.345 6.3"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 963"
                d="M646.708 245.183c1.205-.378 4.589-.035 6.586.544"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 964"
                d="M666.624 254.006c1.2.195 1.755 1.529 1.246 2.979a3.142 3.142 0 01-3.095 2.271c-1.2-.195-1.754-1.529-1.246-2.979a3.138 3.138 0 013.095-2.271z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 965"
                d="M655.347 272.036a24.349 24.349 0 00-3.538-.783"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 966"
                d="M649 250.945c1.2.195 1.756 1.529 1.246 2.979a3.138 3.138 0 01-3.093 2.271c-1.2-.195-1.756-1.529-1.247-2.979a3.142 3.142 0 013.094-2.271z"
                fill="#2f2e6b"
                fillRule="evenodd"
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 967"
                d="M689.473 235.433c2.877-2.067 3.336-9.667-3.251-12.605"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 968"
                d="M694.41 225.452s-.01 8.181-4.488 9.17"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 969"
                d="M699.923 440.318c2.071-4.639 9.108-10.122 6.214-13.915s-7.319 7.916-10.986 7.658 1.8-5.491 2.705-8.239"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 970"
                d="M670.093 261.947c-2.383 1.008-3.831 2.967-3.235 4.371s3.01 1.725 5.391.713 3.829-2.967 3.233-4.371-3.008-1.725-5.389-.713z"
                fill="#f66f82"
                fillRule="evenodd"
            />
            <g
                data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 105"
                fillRule="evenodd"
            >
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 971"
                    d="M654.52 509.766l-13.837-1.957 1.948-20.775 13.839 1.956z"
                    fill="#f2d291"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 972"
                    d="M654.17 513.441l-29.4-4.153s-.561-4.257 1.3-5.424c4.677-2.921 9.4-2.643 13.616-3.719 1.18-.3 2.14-.089 4.032 1.384l4.5 3.5 6.652.962z"
                    fill="#2f2e6b"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 973"
                    d="M660.579 486.975l-19.037-4.991-.8 10.135 18.052 5.1z"
                    fill="#2f2e6b"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 974"
                    d="M639.691 500.145l1.492-4.926s4.29 1.442 4.645 3.44-2.553 5.343-2.553 5.343z"
                    fill="#2f2e6b"
                />
            </g>
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 975"
                d="M707.907 390.185s-13.529-1.681-20.412-14.377"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <path
                data-name="\u041A\u043E\u043D\u0442\u0443\u0440 976"
                d="M697.471 451.138c-7.737-5.358-17.857-6.251-25.892-60.712"
                fill="none"
                stroke="#2f2e6b"
                strokeMiterlimit={22.926}
                strokeWidth={0.567}
            />
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 107">
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 106">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 977"
                        d="M728.592 402.644a2.251 2.251 0 01-.854 1.427c-.264.175-3.524-4.82-3.524-4.82s3.909 2.147 4.378 3.393z"
                        fill="#edba68"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 978"
                        d="M723.064 381.62c-.725 1.888-5.171 11.869-5.237 12.3s1.29 6.954 1.29 6.954l5.614 5.917a14.122 14.122 0 00-.428-3.235 13.986 13.986 0 00-2.2-2.5l-.314-3.4 2.051 2.458s6.528 6.318 6.737 6.7.521-1.851-.025-2.952a38.726 38.726 0 00-2.882-4s3.87 3.195 4.352 3.532a2.031 2.031 0 00.1-2l.355.158a5.683 5.683 0 00-1.313-5.474s-.152-3.282-.249-3.829a27.691 27.691 0 01.476-4.517c.218-1.166.276-5.26-.129-5.963a10.341 10.341 0 00-1.726-1.81z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 979"
                        d="M723.839 395.958a19.808 19.808 0 002.09 2.37 17.945 17.945 0 002.362 1.862"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 980"
                        d="M732.11 401.341c-.764-1.213-2.6-3.449-2.981-4.057a31.435 31.435 0 01-1.219-4.149"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 981"
                    d="M734.308 332.704c7.053 21.534-3.131 53.933-3.131 53.933l-7.789-2.485s-2.722-32.753-3.3-42z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 982"
                    d="M697.67 292.975c11.435.044 32.509 31.712 36.637 39.729l-14.075 13.587c-5.458-7.49-26.763-30.289-28.967-33.361-3.769-5.257-.741-19.982 6.405-19.955z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 983"
                    d="M691.527 293.218c7.712-5.526 18.588 1.718 32.181 24.085l-21.558 13.388c-8.545-11.759-23.343-28.359-10.623-37.473z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
            </g>
        </svg>
    )
}

export default FamilySvg
