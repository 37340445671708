import React, { useEffect } from 'react'
import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import EmptyIconSvg from '../../svgs/EmptyIconSvg';
import { useDispatch } from 'react-redux';
import { RESET_EVENTS_POST_REDUCER } from '../../reducers/EventsPostReducer';
import { RESET_NEWS_POST_REDUCER } from '../../reducers/NewsPostReducer';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '100%',
        height: 'auto'
    }
}))

const EmptyPage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(RESET_EVENTS_POST_REDUCER())
        dispatch(RESET_NEWS_POST_REDUCER())
    }, [dispatch])
    return (
        <MainContainerComponent
            hiddenBreadcrumbs
            space
        >
            <Container>
                <EmptyIconSvg className={classes.icon} />
            </Container>
        </MainContainerComponent>
    )
}


EmptyPage.propTypes = {
    name: PropTypes.string,
}
export default React.memo(EmptyPage);