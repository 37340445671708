import * as React from "react"

function EmptyIconSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1069.311}
            height={750.977}
            viewBox="0 0 1069.311 750.977"
            {...props}
        >
            <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 523">
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1245"
                    d="M641.97 247.35c90.229 135.944 52.624 263.731-44.077 326.878-70.278 45.893-157.485 2.977-226.307 54.438s-198.55 94.476-294.611 23.166c-118.385-87.887-4.676-192.285-15.189-280.611S-38.447 127.151 50.661 42.654c85.477-81.052 195.67 74.172 315.952 91.533S557.257 119.712 641.97 247.35z"
                    fill="#e6e8f6"
                    fillRule="evenodd"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 508">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1258"
                        d="M881.714 319.849l-107.271-.708c-8.639-.058-14.569 7.439-17.2 15.706l-28.314 88.927c-2.635 8.267 5.573 15.526 14.206 15.7l136.326 2.833c8.636.18 14.475-7.465 17.2-15.7l21.935-66.247c7.161-21.634-2.027-40.286-36.882-40.511z"
                        fill="#e6e8f6"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1259"
                        d="M723.898 400.71c-24.044-1.134-90.271 1-109.328 2.007s-40.12 19.057-57.17 58.173-42.13 143.43-21.065 160.48 135.464 7.509 163.489 0 40.119-50.149 49.15-85.255 44.386-132.122-25.076-135.405z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1260"
                        d="M887.809 304.01l-151.815-1c-12.227-.081-20.62 10.529-24.345 22.228l-40.072 125.85c-3.727 11.7 7.891 21.972 20.111 22.225l192.939 4.012c12.22.253 20.487-10.568 24.342-22.225l31.044-93.763c10.136-30.615-2.868-57.007-52.204-57.327z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1261"
                        d="M874.482 482.762l-172.567-1.085a18.46 18.46 0 00-18.448 18.45h0a18.541 18.541 0 0018.448 18.446l172.566 1.085a18.458 18.458 0 0018.446-18.443h0a18.542 18.542 0 00-18.445-18.453z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <text
                    data-name={404}
                    transform="translate(0 666.977)"
                    fill="#171c8f"
                    fontSize={367}
                    fontFamily="Helvetica"
                >
                    <tspan x={0} y={0}>
                        {"404"}
                    </tspan>
                </text>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1244"
                    d="M1005.661 226.393c111.356 120 64.946 232.8-54.4 288.541-86.734 40.514-194.366 2.627-279.307 48.055s-245.053 83.4-363.61 20.448c-146.112-77.578-5.771-169.733-18.746-247.7S165.882 120.293 275.861 45.702c105.494-71.545 241.5 65.476 389.949 80.8s235.296-12.778 339.851 99.891z"
                    fill="none"
                    stroke="#8fb2ee"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 507"
                    fill="none"
                    stroke="#8fb2ee"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1253"
                        d="M738.917 82.685c.617 56.973-17.322 66.616-42.069 58.147s-95.533-24.123-70.244-76.964 64.911-80.913 80.926-51.959 10.189 120.525-17.745 106.547-78.109-42.278-30.349-53.737 108.256 58.766 94.978 38.4c-17.228-26.42-159.944 219.315-114.859-37.192 4.7-26.765 21.08-28.36 53.74-30.349s76.9 22.4 65.33 43.057-37.759 82.11-75.458 71.577-77.232 18.956-61.192-21.927 62.12-56.516 60.476-83.5c-6.239-102.54-105.773 1.581-45.3 28.412 39.55 17.547 63.565 37.461 66.961 23s58.6-20.67 40.823-55.219-55.088-15.169-55.088-15.169-27.56 85.764-20.411 109.558 23.77 62.69 37.1 47.626c76.674-86.609 47.678 45.825-4.666-76.679-33.24-77.79-50.76-108.079-15.989-98.794s63.218 31.784 32.586 43.624-64.159-.371-83.177 15.04-15.814 67.949-6.344 83.049 13.913 64.715 39.252 36.091 77.79-33.237 64.7-60.946 30.15-43.167 16.164-62.269-21.781-30.03-45.538-45.687-60.886 6.558-81.841 13.534-42.723 23.977-20.3 41.141 37.312 27.659 36.756 41.947-34.016 73.33-29.941 23.2 42.318-100.917 66.592-76.742 41.884 55.889 17.533 77.322a118.119 118.119 0 00-32.652 49.019"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1254"
                        d="M664.88 159.188c46.983.685 53.141-16.958 43.7-41.322s-29.369-94.053-70.377-69.237-60.183 63.775-34.74 79.572 100.279 10.189 85.987-17.317-42.595-76.93-47.279-29.944 21.81 83.749 41.079 93.525 100.086-87.278 81.922-98.363-101.735-15.865-123.981-14.723-21.261 20.7-19.648 52.843 26.1 75.712 41.962 64.355 63.871-37.048 51.445-74.164 7.925-75.983-24.149-60.254-40.368 61.059-62.75 59.407c-85.074-6.281-9.222-104.1 18.89-44.547 18.388 38.946 37.182 62.609 25.61 65.931s-11.192 57.645-41.416 40.1-17.979-54.23-17.979-54.23 67.9-27.006 88.2-19.941 54 23.477 42.916 36.58c-63.7 75.338 42.49 46.986-63.618-4.7-67.379-32.816-94.066-50.1-82.96-15.871s32.466 62.261 39.173 32.129-6.691-63.142 4.109-81.833 54.391-15.476 67.771-6.135 54.684 13.783 33.629 38.679-19.635 76.511-43.758 63.594-32.552 29.615-49.678 15.819-26.9-21.472-42.158-44.874-.659-59.911 3-80.526 15.5-42.012 31.865-19.92 26.494 36.758 38.2 36.233 57.013-33.381 16.13-29.437-78.906 41.507-56.579 65.429 50.2 41.3 65.426 17.361 37.127-32.064 37.127-32.064"
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1255"
                    d="M692.427 327.343c1.492 19.936-59.867 72.868-78.3 75.78-11.2 1.772-19.264-6.422-24.925-11.8 12.3-10.541 66.856-61.166 71.906-65.426 8.642-7.293 30.383-11.015 31.319 1.446z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1256"
                    d="M663.019 369.496a239.7 239.7 0 01-30.443 24.549l-9.049 5.445-.055.029-3.495-21.653.6-14.922c7.588-6.95 15.364-14.1 22.076-20.257z"
                    fill="#e0bd89"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1257"
                    d="M700.078 319.369c10.688 12.68-31.321 50.112-65.748 76.9l-22.583-28.964c19.316-16.503 70.696-68.855 88.331-47.936z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 510">
                    <g
                        data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 509"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    >
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1262"
                            d="M1018.188 608.1c3.079-34.672 8.91-73.213 18.3-102.077 14.776-45.423 30.733-62.575 30.733-62.575s6.778 34.215-6.124 84.842c-8.973 35.219-18.7 62.36-23.961 79.81z"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1263"
                            d="M1067.22 443.448s-35.469 87.858-34.727 134.841"
                            fill="none"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1264"
                            d="M1005.647 608.099a330.114 330.114 0 00-9.82-39.5c-14.773-45.425-30.731-62.577-30.731-62.577s-6.778 34.212 6.124 84.842q2.286 8.97 4.613 17.235z"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1265"
                            d="M1028.434 608.099c7.141-45.812 13.644-118.565 8.26-169.263-5.693-53.583-7.588-77.16-7.588-77.16s-18.971 37.508-20.869 96.45-2.844 98.6-4.741 112.53c-1.163 8.544-.9 25.566-.52 37.443z"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1266"
                            d="M988.743 608.1c-6.759-40.689-12.5-101.52-7.342-144.463 5.693-47.425 7.588-68.291 7.588-68.291s18.974 33.198 20.869 85.371 2.844 87.262 4.741 99.594c.978 6.354.949 18 .69 27.793z"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1267"
                            d="M988.989 395.346c7.844 70.283 10.934 98.724 8.887 135.989-1.61 29.29 3.879 56.022 7.807 76.765"
                            fill="none"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1268"
                            d="M1029.106 361.677s-2.136 106.753-4.114 156.1c-1.3 32.466.01 65.552-4.284 90.325"
                            fill="none"
                        />
                        <path
                            data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1269"
                            d="M965.096 506.022s8.9 52.519 22.4 102.077"
                            fill="none"
                        />
                    </g>
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1270"
                        d="M1046.179 614.017c-.954 14.543-1.683 43.878-10.455 57.449h-58.926c-8.769-13.571-9.5-42.906-10.453-57.449h-2.643a.833.833 0 01-.829-.834v-5.612a.831.831 0 01.829-.831h85.116a.83.83 0 01.829.831v5.612a.832.832 0 01-.829.834z"
                        fill="#e6e8f6"
                        fillRule="evenodd"
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1271"
                    d="M773.366 675.084l-23.542 1.7-6.083-27.271 23.476.5z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1272"
                    d="M774.417 686.708h-69.245s.254-6.679 3.753-7.813 29.183-6.54 29.183-6.54 6.746-4.773 8.652-5.9 3.712-.677 7.274 1.414l8.472 4.969 11.765.037z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1273"
                    d="M686.873 659.021l-23.542 1.7-6.083-27.271 23.476.5z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1274"
                    d="M687.926 670.646h-69.245s.256-6.681 3.753-7.815 29.183-6.537 29.183-6.537 6.749-4.775 8.652-5.9 3.712-.677 7.274 1.414l8.471 4.974 11.765.039z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1275"
                    d="M667.489 474.229c3.947 61.009 15.6 117.608 16.321 170.666l-32.114-9.766c-10.068-52-29.147-113.878-37.32-138.644z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1276"
                    d="M723.607 495.439c-38.415 1.283-71.5 1.534-109.231 1.045-2.494-8.91-5.664-16.838-5.5-25.255.376-19.753 9.742-26.363 19.753-27.827 57.08-8.343 100.038-17.1 160.161-19.016-1.895 49.777-8.819 73.333-65.183 71.053z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1277"
                    d="M723.605 495.439c-19.431.651-37.5 1.035-55.5 1.194-16.769-20.177-44.194-34.877-56.319-40.733 3.6-8.163 10.074-11.506 16.841-12.5 57.078-8.343 100.038-17.1 160.161-19.016-1.891 49.779-8.816 73.335-65.183 71.055z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1278"
                    d="M589.197 391.323c6.132-43.347 62.509-77.925 62.509-77.925l10.406 3.725s-38.363 51.435-45.023 66.2z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1279"
                    d="M726.247 482.044c17.288 61.009 32.508 128.27 44.824 181.331l-32.4-7.572c-19.9-48.282-36.962-103.185-57.93-159.317-1.613-4.321-2.8-8.3-4.467-12.638z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1280"
                    d="M789.943 495.439c-38.413 1.283-71.449 1.6-109.174 1.111-4.82-12.693-6.116-16.608-6.349-25.221-.541-19.75 10.531-26.462 20.539-27.926 57.083-8.341 100.044-17.1 160.164-19.016-1.894 49.776-8.815 73.332-65.18 71.052z"
                    fill="#8fb2ee"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1281"
                    d="M667.486 474.229c3.032 46.811 10.6 91.023 14.279 133.035-9.959-36.972-27.612-106.277-43.977-138.723z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1282"
                    d="M738.512 486.867c-5.818-5.892-9.982-19.82-17.455-17.669s7.692 15.8 5.131 21.216-7.274-5.991-10.913-8.986"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1283"
                    d="M705.92 293.954c43.395-11.89 80.4-9.885 114.49 38.339s34.322 99.725 34.322 99.725l-123.181-2.481s4.133-12.4-6.615-25.628-21.58-23.145-41.419-47.945-4.051-38.036-4.051-38.036z"
                    fill="#f66f82"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1284"
                    d="M687.161 333.502l18.465 16.313 11.944-41.9-20.464-32.9-27.789 23.295z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1285"
                    d="M706.288 289.777l-9.182-14.763-27.79 23.3 12.24 24.136a14.867 14.867 0 0010.56.933c8.501-2.319 13.438-21.698 14.172-33.606z"
                    fill="#e0bd89"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1286"
                    d="M650.385 242.507c6.038-3.364 24.353-13.3 38.363 9.768s14.938 32.775 14.162 37.916c-1 6.574-3.934 14.922-17.259 25.338-8.712 4.5-13.082.29-18.65-3.1-4.441-2.7-13.289-8.2-26.671-31.638s4.183-34.635 10.055-38.284z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1287"
                    d="M698.687 265.205c-4.773-12.146-5.011-39.589-19.815-32.082-7.674-9.731-18.707-4.14-20.471-2.261-6.043 6.44-11.339 13.375-15.62 16.357-4.423 3.074-9.658 5.62-11.647 8.022-8.511 10.27-2.6 16.135-1.153 17.316 7.133 5.821 13.458-.421 21.6-5.881 13.819-9.269 15.916-13.89 24.037-22.458 2.23-.923 3.28 18.425 10.808 17s11.924 4.196 12.261 3.987z"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1288"
                    d="M692.871 263.399a5.789 5.789 0 011.777-4.974c1.6-1.456 5.455-2.475 8.492 2.695s1.343 12.152-1.746 13.142-8.523-10.863-8.523-10.863z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1289"
                    d="M696.377 264.734c-.382-.648.366-5.12 3.717-4.347"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.425}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1290"
                    d="M652.261 280.106a4.156 4.156 0 013.855 1.5c3.228 4.284 6.9 13.963 8.056 15.568 1.579 2.185 4.995-.146 4.995-.146"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1291"
                    d="M678.072 300.165c-1.064-1.307-4.561 2.509-10.868 6.25"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1292"
                    d="M671.443 267.976c-1.738-.081-6.119 1.574-8.573 3.048"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1293"
                    d="M700.7 275.979c2.538 7.125 2.632 11.37 2.2 14.211-1 6.574-3.934 14.922-17.259 25.338"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1294"
                    d="M645.112 285.26c.951-1.458 4.862-4.043 7.491-5.167"
                    fill="none"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 511">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1295"
                        d="M662.112 317.123c.951-9.59-5.094-16.159-9-17.157l-2.925-.75s-5.377-5.68-6.142-6.129-2.912-3.7-4.99-5.967c.421-2.394 2.6-7.988-.436-10.374a3.657 3.657 0 00-1.487.572c.118-1.1-.745-5.092-2.426-2.486-1.919-.4-1.673 1.333-2.517 6.192a49.4 49.4 0 01-1.2 5.157l-.7 5.395s2.439 7.431 2.98 8.265 3.471 6.391 4.47 7.58c1.971 2.358 12.379 14.355 13.4 15.772 3.001-.686 9.502-5.628 10.973-6.07z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041B\u0438\u043D\u0438\u044F 20"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                        d="M633.884 274.81l-3.188 14.805"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1296"
                        d="M636.896 277.712l-2.606 10.374 9.13 12.559"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <g
                    data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 512"
                    fill="#2f2e6b"
                    fillRule="evenodd"
                >
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1297"
                        d="M651.71 287.586c1.1-.708 2.52-.039 3.155 1.5a3.4 3.4 0 01-.844 4.062c-1.1.708-2.514.042-3.155-1.493a3.4 3.4 0 01.844-4.069z"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1298"
                        d="M669.651 275.199c1.106-.708 2.517-.039 3.155 1.5a3.4 3.4 0 01-.844 4.062c-1.1.708-2.517.039-3.155-1.5a3.4 3.4 0 01.844-4.062z"
                    />
                </g>
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 513">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1299"
                        d="M710.922 446.007a3.933 3.933 0 01-2.7-1.082c-.376-.405 7.35-7.41 7.35-7.41s-2.632 7.337-4.65 8.492z"
                        fill="#edba68"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1300"
                        d="M745.679 430.664c-3.455-.729-21.914-5.654-22.68-5.646s-11.652 4.143-11.652 4.143l-8.662 11.32s4.206-.808 5.463-1.626 3.712-4.485 3.712-4.485l5.774-1.477-3.675 4.216s-9.107 13.014-9.716 13.477 3.34.389 5.092-.855a68.021 68.021 0 006.109-6.082s-4.446 7.562-4.9 8.484c.609.491 2.334.395 3.476-.382l-.175.656a9.707 9.707 0 005.358-.821 9.811 9.811 0 003.727-2.954s5.625-1.168 6.542-1.482a47.836 47.836 0 017.93-.426c2.073.055 9.154-.975 10.257-1.869a17.876 17.876 0 002.648-3.476z"
                        fill="#f2d291"
                        fillRule="evenodd"
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1301"
                        d="M721.156 435.962a34.784 34.784 0 00-3.518 4.26 31.062 31.062 0 00-2.561 4.587"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1302"
                        d="M714.14 451.726c1.884-1.657 5.238-5.439 6.182-6.268s6.83-3.246 6.83-3.246"
                        fill="none"
                        stroke="#2f2e6b"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                </g>
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1303"
                    d="M845.202 390.61c-25.158 30.6-98.841 54.621-98.841 54.621l-3.636-13.822s62.1-43.395 75.207-52.919z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1304"
                    d="M765.737 304.237c19.9-1.939 71.156 73.493 79.747 86.711l-33.344 14.091c-10.811-12.065-53.141-58.706-57.509-63.662-7.483-8.485-1.333-35.927 11.106-37.14z"
                    fill="#f2d291"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1305"
                    d="M793.852 323.451c12.646 13.343 26.237 31.2 36.476 45.472l-36.031 16.339c-16.221-17.928-36.7-40.522-39.662-43.886a12.86 12.86 0 01-2.656-5.685z"
                    fill="#e0bd89"
                    fillRule="evenodd"
                />
                <path
                    data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1306"
                    d="M743.582 294.479c22.293-16.606 55.329 26.867 82.884 60.687l-37.971 30.292c-16.932-18.945-72.214-70.643-44.913-90.979z"
                    fill="#fff"
                    stroke="#2f2e6b"
                    strokeMiterlimit={22.926}
                    strokeWidth={0.567}
                    fillRule="evenodd"
                />
                <g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 514">
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1307"
                        d="M884.391 692.671c-10.521-30.252-16.66-48.664-16.66-48.664h-19.288l25.427 47.8z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1308"
                        d="M716.487 692.671c10.521-30.252 16.658-48.664 16.658-48.664h19.29l-25.427 47.8z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1309"
                        d="M902.928 422.296c-24.042-1.134-90.268 1-109.325 2.007s-40.122 19.062-57.168 58.173-42.626 146.92-21.561 163.97 135.463 7.51 163.489 0 40.119-50.151 49.148-85.255 44.879-135.612-24.583-138.895z"
                        fill="none"
                        stroke="#8fb2ee"
                        strokeMiterlimit={22.926}
                        strokeWidth={0.567}
                    />
                    <path
                        data-name="\u041A\u043E\u043D\u0442\u0443\u0440 1310"
                        d="M884.43 468.774c-16.859-.795-63.293.706-76.653 1.406s-28.132 13.365-40.085 40.791-29.535 100.564-14.771 112.519 94.981 5.264 114.631 0 28.127-35.161 34.458-59.775 31.126-92.639-17.58-94.941z"
                        fill="#e6e8f6"
                        fillRule="evenodd"
                    />
                </g>
            </g>
            <text
                data-name="Ups! Co\u015B posz\u0142o nie tak. Wr\xF3\u0107 do strony g\u0142\xF3wnej."
                transform="translate(201.06 238.171)"
                fill="#444544"
                fontSize={35}
                fontFamily="Lato-Bold, Lato"
                fontWeight={700}
            >
                <tspan x={49.211} y={35}>
                    {"Ups! Co\u015B posz\u0142o nie tak. "}
                </tspan>
                <tspan fontSize={25}>
                    <tspan x={147.641} y={75}>
                        {"Wr\xF3\u0107 do strony g\u0142\xF3wnej."}
                    </tspan>
                </tspan>
            </text>
        </svg>
    )
}

export default EmptyIconSvg
