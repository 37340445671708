import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EVENTS_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_EVENTS_DATA_REDUCER = createAsyncThunk(
    'EventsReducer/fetchEventsData',
    async (page) => {
        const response = await GET(EVENTS_API, { page });
        return response.body
    }
)


export const EventsReducer = createSlice({
    name: 'EventsReducer',
    initialState: {
        data: [
        ],
        meta: {
        },
        loading: false,
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [FETCH_EVENTS_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_EVENTS_DATA_REDUCER.fulfilled]: (state, { payload }) => {
            state.data = [
                ...payload?.data
            ];
            state.meta = payload.meta;
            state.loading = false;
        },
        [FETCH_EVENTS_DATA_REDUCER.rejected]: (state, { payload }) => {
            state.data = {};
            state.loading = false;
            state.error = payload;
            state.error = {};
        }
    }
})

// export const { } = EventsReducer.actions

export default EventsReducer.reducer