import * as React from "react"

function ForFamilyBgrSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={375.746}
            height={360.465}
            viewBox="0 0 375.746 360.465"
            {...props}
        >
            <path
                d="M8.845 122.426C20.566 78.949 13.563 11.011 64.764 1.779 72.028.469 77.217-.779 83.628.6 100.667 4.279 117.714 7.654 135 9.677c21.357 2.5 42.678 5.713 64.171 5.713 21.439 0 42.9-.3 64.341 0 29.475.408 55.922 15.731 80.564 30.008l.7.408c11.9 6.888 15.939 10.366 17.178 23.355 1.573 16.472 3.727 31.659 8.254 47.638 8.4 29.664 6.113 61.826.588 91.912A414.9 414.9 0 01323.3 339.439c-5.451 9.618-12.554 19.169-24.424 20.415-15.923 1.672-32.938-.342-48.589-3.024-36.987-6.342-73.855-13-110.912-18.988-32.166-5.2-65.31-10.346-96.679-19.327-14.161-4.052-28.424-10.978-33.181-26.046-6.6-20.9-8-44.4-8.924-66.121-1.467-34.279-.728-70.597 8.254-103.922z"
                fill="#c4e0e4"
            />
        </svg>
    )
}

export default ForFamilyBgrSvg
