import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
    squareBox: {
        position: 'relative',
        width: '100%',
        '&::after': {
            content: "''",
            display: 'block',
            paddingBottom: '100%',
        },
    }
}))

const SquareBoxComponent = ({ children, className =''}) => {
 const classes = useStyles();

    return (
        <Box className={classNames([classes.squareBox, className])}>
           {children} 
        </Box>
    )
}


export default React.memo(SquareBoxComponent);