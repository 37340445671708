import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// MAP_ROUTE
import { MAIN_ROUTE, NEWS_ROUTE, EVENTS_ROUTE, PARTNERS_ROUTE, FOR_FAMILY_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE, MEET_ROUTE, INFORM_ROUTE } from "./constants/routes";
import AboutPage from "./pages/aboutPage/AboutPage";
import ContactPage from "./pages/contactPage/ContactPage";
import EmptyPage from "./pages/emptyPage/EmptyPage";
import EventsPage from "./pages/eventsPage/EventsPage";
import EventsPostPage from "./pages/eventsPostPage/EventsPostPage";
import ForFamilyPage from "./pages/forFamilyPage/ForFamilyPage";
import InformDisclPage from "./pages/informDisclPage/InformDisclPage";
import MainPage from "./pages/mainPage/MainPage";
import MeetPage from "./pages/meetPage/MeetPage";
import NewsPage from "./pages/newsPage/NewsPage";
import NewsPostPage from "./pages/newsPostPage/NewsPostPage";
import PartnersPage from "./pages/partnersPage/PartnersPage";

const Root = (props) => {

  
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={NEWS_ROUTE} component={NewsPage} />
        <Route exact path={`${NEWS_ROUTE}/:slug`} component={NewsPostPage} />
        <Route exact path={EVENTS_ROUTE} component={EventsPage} />
        <Route exact path={`${EVENTS_ROUTE}/:slug`} component={EventsPostPage} />
        <Route exact path={PARTNERS_ROUTE} component={PartnersPage} />
        <Route exact path={FOR_FAMILY_ROUTE} component={ForFamilyPage} />
        <Route exact path={ABOUT_ROUTE} component={AboutPage} />
        <Route exact path={CONTACT_ROUTE} component={ContactPage} />
        <Route exact path={INFORM_ROUTE} component={InformDisclPage} />
        <Route exact path={MEET_ROUTE} component={MeetPage} />
        <Route exact path={MAIN_ROUTE} component={MainPage} />
        <Route exact component={EmptyPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
