import React from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SectionBgrSvg from '../svgs/SectionBgrSvg';
import SectionComponent from './SectionComponent';
import ForFamilyBgrSvg from '../svgs/ForFamilyBgrSvg';
import ForFamilyIconSvg from '../svgs/ForFamilyIconSvg';
import { useTranslation } from 'react-i18next';
import MeetBgrSvg from '../svgs/MeetBgrSvg';
import MeetIconSvg from '../svgs/MeetIconSvg';
import EventsBgrSvg from '../svgs/EventsBgrSvg';
import EventsIconSvg from '../svgs/EventsIconSvg';
import FriendlyIconSvg from '../svgs/FriendlyIconSvg';
import FriendlyBgrSvg from '../svgs/FriendlyBgrSvg';
import { EVENTS_ROUTE, FOR_FAMILY_ROUTE, MEET_ROUTE } from '../constants/routes';
import { FRIENDLY_LINK } from '../constants';

const useStyles = makeStyles((theme) => ({
    bgr: {
        minWidth: '100%',
        // width: '100%',
        // height: '100%',
        position: 'absolute',
        top:0,
        left: 0,
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    },
    mainBox: {
        marginTop: 100,
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.down("md")]: {
            background: theme.palette.background.gray,
        },
    },
    sectionsCont: {
        margin: 'auto',
        // position: 'absolute',
        paddingTop: 100,
        paddingBottom: 100,
    }
}))

const SectionsComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={classes.mainBox}>
            <SectionBgrSvg className={classes.bgr} />
            <Container className={classes.sectionsCont}>
                <Grid container>
                    <SectionComponent
                        Background={ForFamilyBgrSvg}
                        Icon={ForFamilyIconSvg}
                        to={FOR_FAMILY_ROUTE}
                        transform={`matrix3d(1, 0.03, 0, 0, 0, 1, 0, 0, 0, 0.03, 1, 0.03, 0, 0, 0, 1)`}
                        title={t('sections.for_family')}
                    />
                    <SectionComponent
                        Background={MeetBgrSvg}
                        Icon={MeetIconSvg}
                        to={MEET_ROUTE}
                        index={2}
                        transform={'matrix3d(1, 0, 0, 0.0001, 0, 1, 0, 0, 0.0001, 0, 1, 0, 0,  0.0001, 0, 1)'}
                        title={t('sections.meet')}
                    />
                    <SectionComponent
                        Background={EventsBgrSvg}
                        Icon={EventsIconSvg}
                        to={EVENTS_ROUTE}
                        index={3}
                        transform={'matrix3d(1, 0, 0, 0.0001, 0, 1, 0, 0, 0.0001, 0, 1, 0, 0,  0.0001, 0, 1)'}
                        title={t('sections.event')}
                    />
                    <SectionComponent
                        Background={FriendlyBgrSvg}
                        Icon={FriendlyIconSvg}
                        external_link
                        transform={'matrix3d(1, -0.01, 0, 0, -0.04, 1,  -0.01, 0, 0,  -0.04, 1,  -0.01, 0, 0,  -0.04, 1)'}
                        to={FRIENDLY_LINK}
                        title={t('sections.friendly')}
                    />
                </Grid>
            </Container>
        </Box>
    )
}


SectionsComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(SectionsComponent);