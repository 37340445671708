import React from 'react'
import NewsPostComponent from '../../components/NewsPostComponent';
import { useTranslation } from 'react-i18next';
import MeetImg from '../../imgs/meet.jpg'

const MeetPage = (props) => {
    const { t } = useTranslation();
    return (
        <NewsPostComponent
            desc={t('meet_page.desc')}
            src={MeetImg}
            breadcrumbsTitle={t('meet_page.title')}
        />
    )
}

export default React.memo(MeetPage);