import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EVENTS_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_EVENTS_POST_DATA_REDUCER = createAsyncThunk(
    'EventsPostReducer/fetchEventsPostData',
    async (slug) => {
        const response = await GET(`${EVENTS_API}/${slug}`);
        return response.body.data
    }
)

const initialState = {
    data: {},
    loading: false,
    error: {},
};

export const EventsPostReducer = createSlice({
    name: 'EventsPostReducer',
    initialState,
    reducers: {
        RESET_EVENTS_POST_REDUCER: (state) => {
            state.error = {}
        }
    },
    extraReducers: {
        [FETCH_EVENTS_POST_DATA_REDUCER.pending]: (state) => {
            state.loading = true;
        },
        [FETCH_EVENTS_POST_DATA_REDUCER.fulfilled]: (state, { payload }) => {
            state.data = {
                ...state.data,
                ...payload,
            }
            state.loading = false;
        },
        [FETCH_EVENTS_POST_DATA_REDUCER.rejected]: (state, {error}) => {
            state.data = {};
            state.loading = false;
            state.error = error;
        }
    }
})

export const { RESET_EVENTS_POST_REDUCER } = EventsPostReducer.actions

export default EventsPostReducer.reducer